export class PrintManager {

    // #region Print Code
    //Code print Sp LAI Diag
    public static code_Print_Diag_Sp_LAI = "Diag_Sp_LAI";
    //Code print Annuals Diag
    public static code_Print_Diag_Annuals = "annuals";
    //Code print Preconisation Modulation
    public static code_Print_Modulation = "modulation";
    //Code print organique
    public static code_Print_Orga_Ferti = 'fumure organique'
    //Code print Advice LAI
    public static code_Print_Advice_Sp_LAI = "Adv_Sp_LAI";
    //Code print Preocnisation LAI
    public static code_Print_Preco_Sp_LAI = "Preco_Sp_LAI";
    //Code print Intervention mode preconisation
    public static code_Print_Inter_Mode_Preco = "InterModePreco";
    //Code print Synthesis of the ferti (preco+Pilot Sp)
    public static code_Print_Synt_Fertis = "SynthFertis";
    //Code print Synthesis of the preco other inputs (modulation)
    public static code_Print_Synt_Other_Inputs = "SynthOInputs";
    //#endregion
    
    /**
     * Format en Pdf type (to the list user)
     * for the print Synthesis of the ferti (preco+Pilot Sp)
     * @returns PrintPdfExtraModel
     */
    public static FormatPdfTypeSynthesisFert() {
        return new PrintPdfExtraModel(PrintManager.code_Print_Synt_Fertis, "Synthèse des besoins en engrais", DownloadPdfEnum.SynthFertilizerNeeds)
    }

    /**
    * Format en Pdf type (to the list user)
    * for the print Synthesis of the preco other inputs (modulation)
    * @returns PrintPdfExtraModel
    */
    public static FormatPdfTypeSynthesisOtherInputs() {
        return new PrintPdfExtraModel(PrintManager.code_Print_Synt_Other_Inputs, "Synthèse des besoins en autres intrants", DownloadPdfEnum.SynthOtherInputNeeds)
    }
}

export enum DownloadPdfEnum {
    DiagnosticFertilité = 1,
    DiagnocticPotentiel = 2,
    ConseilFumure = 3,
    ConseilAzote = 4,
    Préconisations = 5,
    Modulations = 6,
    Annuals = 7,
    ModePreco = 8,
    DiagSpLai = 9,
    PrecoPilotNSpLai = 10,//impression preconisation LAI
    AdvicePilotNSpLai = 11,
    SynthFertilizerNeeds = 12,//synthèse des besoins en engrais(GRID Preco fumure+ Pilotage AzoteSP)
    SynthOtherInputNeeds = 13,//synthèse des besoins en autres intrant(GRID Modulation)
}

/**Model Impression PDF */

export class PrintPdfExtraModel {
    /**Code unique identifiant le type d'impression */
    public code: string
    /**Titre affiché au client dans la liste d'impression*/
    public title: string
    /**type enum lié à l'impression */
    public type: DownloadPdfEnum

    /**titre à afficher */
    public get value() {
        return this.title;
    }
    /**
     * Initialisation
     * @param _iCode Code unique identifiant le type d'impression
     * @param _iUserText Titre affiché au client dans la liste d'impression
     * @param _iPdfType type enum lié à l'impression
     */
    public constructor(_iCode: string, _iUserText: string, _iPdfType: DownloadPdfEnum) {
        this.code = _iCode;
        this.title = _iUserText;
        this.type = _iPdfType;
    }

}