import { Component } from '@angular/core';

@Component({
  selector: 'nb-auth-block',
  templateUrl: './auth-block.component.html',
  styleUrls: ['./auth-block.component.scss']
})
export class AuthBlockComponent {

}
