import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { error } from './_models/error';

@Injectable()
export class JarvisPopupService {
    private subject = new Subject<any>();
    private keepAfterNavigationChange = false;


    public Error : error = new error();

    constructor() {

    }

    ThrowError(code:string, text:string) {
        this.Error.Code = code;
        this.Error.Title = "Une erreur est survenue."
        this.Error.Message = text;
        this.Error.Show = true;
        throw new TypeError("Error message");
    }
}
