import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, EMPTY } from 'rxjs';
import { Farm, Cultivation, User } from '../_models/index';
import {HttpRequestService} from '../_services/index';
import { environment } from '../../environments/environment'
import { JarvisUiService } from '../modules/jarvis-ui/jarvis-ui.service';
import { map } from 'rxjs/operators';

const GETFARM_ACTION = environment.BeApiOperational.getfarms;
const GETCAMPAGNE_ACTION = environment.BeApiOperational.getcampagne;
const GETCULTURE_ACTION = environment.BeApiOperational.getcultures;
const GETFILTER_ACTION = environment.BeApiOperational.getfilter;
const GETPARCELLE_ACTION = environment.BeApiOperational.getparcelles;
const GETUSERINFO_ACTION = environment.BeApiOperational.getuserinfo;

@Injectable()
export class FarmServices extends BehaviorSubject<Farm[]> {
    private baseUrl = environment.BeApiOperational.url;


    private _filteredOrders : Farm[];

    public get FilteredOrders(): Farm[]
    {
        return this._filteredOrders;
    }
    public set FilteredOrders(value:Farm[])
    {
        this._filteredOrders = value;
    }

    constructor(private _jarvisMenuService: JarvisUiService, private httpRequestService: HttpRequestService) {
        super([]);
    }

    getuserinfo(): any {
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      const body = { "smagToken": currentUser['smag_token'] };
        return this.httpRequestService.PostRequest(this.baseUrl + GETUSERINFO_ACTION, body , 'getuserinfo').pipe(
          map(
            (response) => {
                const userInfo = response;
                return this.convertJsonToUserInfo(userInfo);
            })
        );
    }

    public getFarms(allFarms: boolean) {
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      const body = { "smagToken": currentUser['smag_token'] };
        return this.httpRequestService.PostRequest(this.baseUrl + GETFARM_ACTION, body, 'getFarms')
          .pipe(
            map(
            (response) => {
                return this.convertJsonToFarm(response, allFarms);
            })
        );
    }


    public getCampagnes(farmsId: string[]) {
      const body = { "FarmsId": farmsId };
      return this.httpRequestService.PostRequest(this.baseUrl + GETCAMPAGNE_ACTION, body, 'getCampagnes').pipe(
        map(
            (response) => {
                return this.convertJsonToCampagneList(response);
            })
      );
    }

    public GetFarmByCultivationsFiltered(farmsId: string[], idCampaign: number, cultivations: Cultivation[]) {
        const body = { "FarmsId": farmsId, "IdCampaign": idCampaign, "Cultivations": cultivations };

        return this.httpRequestService.post(this.baseUrl + GETFILTER_ACTION, body, 'GetFarmByCultivationsFiltered').pipe(
          map(
            (response) => {
                let apps = response;
                return this.convertJsonToFarm(apps, false);
            })
        );
    }

    public getCultivations(farmsId: string[], idCampaign: number) {

        const body = { idCampaign, farmsId };
        return this.httpRequestService.PostRequest(this.baseUrl + GETCULTURE_ACTION, body, 'getCultivations').pipe(
          map(
            (response) => {
                let apps = response;
                return this.convertJsonToCultivation(apps);
            })
        );

    }

    private convertJsonToFarm(any, allFarm: boolean): Farm[] {
        let convertedApps = new Array<Farm>();
        if (any.length === 0)
            return;

        if (any)
            any.forEach((element, i) => {
                convertedApps[i] = new Farm(element);
            });

        if (allFarm)
        {
            let currentUser = JSON.parse(localStorage.getItem('currentUser'));
            let farms = JSON.parse(localStorage.getItem('farms')) as { [userId: string] : Farm[]; };

            if(farms == null)
                farms =  {};

            farms[currentUser.userid] = convertedApps;
            localStorage.setItem('farms', JSON.stringify(farms));
        }
        else
        {
            this.FilteredOrders = convertedApps;
        }
        return convertedApps;
    }

    convertJsonToUserInfo(personalInfo: any): User {
        let convertedInfo: User = new User();
        if(personalInfo.UserInfo != null)
        {
            if(personalInfo.UserInfo.FirstName!=undefined)
            convertedInfo.Firstname = personalInfo.UserInfo.FirstName;
            else
            convertedInfo.Firstname='';

            if(personalInfo.UserInfo.LastName!=undefined)
            convertedInfo.Lastname = personalInfo.UserInfo.LastName;
            else
            convertedInfo.Lastname='';
        }

        if (personalInfo && personalInfo.Role != undefined) {
          convertedInfo.Role = personalInfo.Role;
        } else {
          convertedInfo.Role = "";
        }
        return convertedInfo;
    }



    private convertJsonToCultivation(any): Cultivation[] {
        let convertedApps = new Array<Cultivation>();
        let i = 0;

        if (any)
            any.forEach(element => {

                if (element != null) {

                    convertedApps[i] = new Cultivation(element.Id, element.Name, element.Color);


                    if (element.Name == null)
                        element.Name = "Non défini(e)"

                    convertedApps[i].Name = element.Name;
                }
                i++;
            });
        return convertedApps;
    }

    private convertJsonToCampagneList(any): number[] {
        let convertedApps = new Array<number>();
        let i = 0;
        convertedApps = any.CampagnesValues.sort(function (a, b) { return b - a });;
        return convertedApps;

    }

    //Retourne un observable VIDE
    public GetEmptyFarmArray():Observable<Farm[]> {
        return EMPTY;
    }


    public static IncludePlotModulation(_iFarm:Farm):boolean
    {
        return (_iFarm.Sectors && _iFarm.Sectors.find(r=>r.CultivationPlots!=null && r.CultivationPlots.length && r.CultivationPlots.find(pl=> pl.Preconisations && pl.Preconisations.length>0)!=null )!=null)
    }


    public static IncludeModePreco(_iFarm:Farm): boolean
    {
      return _iFarm.HasModePreco();
    }
}
