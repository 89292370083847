/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  let ga_id = "G-NVKBV42YHJ" // google analytics id
  let script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;
  script.src = 'https://www.googletagmanager.com/gtag/js?id='+ ga_id +''
  document.head.appendChild(script)
  const script1 = document.createElement('script');
   script1.innerHTML = `
   window.dataLayer = window.dataLayer || [];
   function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', "${ga_id}");
   `;
   document.head.appendChild(script1);
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
