import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, AuthCanLoadGuard } from './_guards/index';
import { PagesModule } from './pages/pages.module';
import { PingComponent } from './ping/ping.component';
import { NbAuthComponent } from './framework/auth/components/auth.component';
import { LoginComponent, ConnectorComponent, NbLogoutComponent, NbRegisterComponent, NbRequestPasswordComponent, NbResetPasswordComponent, AuthSmagComponent } from './framework/auth/components/index';

const appRoutes: Routes = [
  {
      path: 'home',
      canActivate: [AuthGuard],
      loadChildren: () =>  PagesModule
  },
  {
      path: 'ping', component: PingComponent
  },
  {
    path: 'auth',
    component: NbAuthComponent,
    children: [
        {
            path: 'login',
            component: LoginComponent,
        },
        {
            path: 'register',
            component: NbRegisterComponent,
        },
        {
            path: 'logout',
            component: NbLogoutComponent,
        },
        {
            path: 'connector',
            component: ConnectorComponent,
        },
        {
            path: 'callback',
            component: AuthSmagComponent,
        },

    ]
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'auth/login' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
