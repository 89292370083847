import { Component, AfterContentInit, AfterViewInit, OnChanges, Input, ElementRef, Renderer2, OnInit, HostListener, OnDestroy } from '@angular/core';
import { SharedMapService, SelectableService, LegendService } from './../../../_services/index';

import { Farm, Sector, Geometry, LegendItem, LegendGroup, LegendColor } from '../../../_models/index';

@Component({
    selector: 'map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss', './map.component.responsive.scss']
})

export class AppSharedMapComponent implements AfterContentInit, OnChanges, OnInit, AfterViewInit, OnDestroy {

    // Instance Openlayers
    public map: any;

    // tableau des couches carto
    public layers: any;

    // Type de carto (parcelle ou micro)
    @Input() type: string;

    private _farmData: Farm[];
    // Ajout des features
    @Input()
    set ilot(farmData: Farm[]) {
        this._farmData = farmData;
    }
    get ilot(): Farm[] { return this._farmData }

    selectedLegenditem: LegendGroup;

    legendAttrs: LegendGroup[];

    constructor(private mapService: SharedMapService, private elRef: ElementRef, private rd: Renderer2, private selectableService: SelectableService
        , private legendService: LegendService) { }

    ngOnDestroy() {
        delete this.map;
        delete this.layers;
    }

    ngOnInit() {

        // Création de la carto
        this.map = this.mapService.render(this.type);

        // Récupération des couches
        this.layers = this.map.getLayers();

        this.mapService.selectedLayer = 'micro';

        this.mapService.loadFeaturesFromModel(this._farmData, 'micro');

        // Ajout des features

        this.selectedLegenditem = this.legendService.legend[0];

        this.legendAttrs = this.legendService.legend;

    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        // Rafraichissement
        if (this.map) this.map.updateSize();
        // Zoome sur la sélection courante
        this.selectableService.trigger();
    }

    // Céation de la carte après que le squelette HTML soit créé
    ngAfterContentInit(): void {
        this.map.setTarget('map');
        this.mapService.fitActiveLayer();
    }

    // Mise à jour de la taille de la carto
    ngAfterViewInit(): void {
        this.map.updateSize();
    }

    onSelectedLegendItemChanged(legenditem: LegendGroup): void {
        this.selectedLegenditem = legenditem;



    }
    // mise à jour des features
    ngOnChanges(changes: any) {

        if (changes.ilot) {
            if (changes.ilot.currentValue) {
                this.mapService.loadFeaturesFromModel(changes.ilot.currentValue, 'ilot');
                this.legendAttrs = this.legendService.legend;
                if (this.legendService.legend || this.legendService.legend.length == 0) {
                    this.selectedLegenditem = null;
                }
            }
        }
    }
}