/**
 * Remplacement des attibuts par du texte
 */

import { Injectable } from '@angular/core';

@Injectable()
export class AuthSmagTextService {

    public texts: any = {
       "ManyUsers": "Nous avons trouvé plusieurs comptes utilisateurs affiliés à votre compte SMAG. Veuillez choisir le compte avec lequel vous voulez vous connecter :",
       "ManyUsersSwitchAccount": "Veuillez choisir le compte sur lequel vous voulez vous connecter :",
       "NoUsers": "Aucun compte associé à l'application n'a été trouvé. Merci de contacter le support<br/>",
       "OneUser": "Un utilisateur a été trouvé, merci de patienter...",
       "OneUserAlreadyLogged": "Votre compte est bien lié à l'application. Vous pouvez fermer cette fenêtre en cliquant sur le bouton ci-dessous",
       "ButtonSmagLoginText": "Connexion avec votre compte SMAG",
       'UndefinedError': "Une erreur est survenue lors de la récupération des données. Merci de réessayer dans quelques minutes ou de contacter le support be Api",
       "NoUserProfilsActives":"Vous n'êtes pas autorisé à accéder à cette application.",//aucun profil utilisateur actif retourné
       "return": "Retour"
    };

    constructor() { }

   public get(attribut: string) {
      return (this.texts[attribut] !== undefined) ? this.texts[attribut] : attribut;
   }

  /**
   * Retourne le profil dans le format souhaité
   * @param profile Profil
   * @returns profil traduit
   */
  getProfileText(profile) {
    let profil = '';
    switch(profile) {
      case 'initiator':
          profil = 'Initiateur';
          break;
      case 'technician':
          profil = 'Technicien';
      case 'laboratory':
          profil = 'Laboratoire';
      case 'visitor':
          profil = 'Visiteur';
      default:
          profil = 'Exploitant';
          break;
    }
    return profil;
  }

}
