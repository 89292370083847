/**
 * Externalisation de la gestion de la sélection tableau ou carto
 * Tout changement de l'état de ce service sera propagé dans les composants qui l'écoutent
 */

import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class SelectableService implements OnDestroy {

    public fromMap: boolean;
    private selection = new Subject<Array<number>>();
    private _list: Array<number> = [];

    ngOnDestroy() {
        this.selection.unsubscribe();
    }

    add(item: number) {
        if (this._list.indexOf(item) === -1) {
            this._list.push(item);
            this.selection.next(this._list);
        }
    }

    remove(item: number) {
        let indexAt = this._list.indexOf(item);
        this._list.splice(indexAt, 1);
        this.selection.next(this._list);
    }

    clear(silent:boolean = false) {
        this._list = [];
        if(!silent) this.selection.next(this._list);
    }

    setSelection(selection:Array<number>) {
        this._list = selection;
        this.selection.next(this._list);
    }

    getSelection(): Observable<any> {
        return this.selection.asObservable();
    }

    getList(): Array<any> {
        return this._list;
    }

    trigger() {
        this.selection.next(this._list);
    }
}
