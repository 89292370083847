import { Component, OnInit } from '@angular/core';
import { SmagAccountService } from '../../services/index';
import { ActivatedRoute, Router } from '@angular/router';
import { SmagUser, Login } from '../../../../_models';
import { AuthSmagTextService } from '../../../../_services';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { LoadingService } from '../loading/loading.service';
import { isNullOrUndefined} from '../../../../_helpers';
import { slugify } from '../../helpers';

@Component({
  selector: 'auth-smag',
  templateUrl: './auth-smag.component.html',
  styleUrls: ['./auth-smag.component.scss']
})

export class AuthSmagComponent implements OnInit {
  private smag_token: string;
  private smag_refresh_token: string;
  public identities: any;
  public smag_users: SmagUser[] = [];
  public smag_users_grouped = []
  public no_smag_user: Boolean = false;
  //1 seul compte  présent (vérifier si actif)
  public one_smag_user: Boolean = false;
  //plusieurs comptes actifs
  public many_smag_users: Boolean = false;
  //Si l'utilisateur est deja connecté à l'appli avant de lancer la connexion compte SMAG
  public user_already_logged: Boolean = false;
  //texte utilisateur affiché sous le bouton retour
  public undefined_error: string="";
  //texte utilisateur affiché au dessus du bouton retour
  public text_no_users: SafeHtml;

  constructor(private smagAccountService: SmagAccountService,
              private router: Router,
              private route: ActivatedRoute,
              private authSmagTextService: AuthSmagTextService,
              private sanitized: DomSanitizer,
              private loading: LoadingService
              ){
                this.text_no_users = this.sanitized.bypassSecurityTrustHtml(this.authSmagTextService.get("NoUsers"));
              }


  ngOnInit() {
    this.smag_token = this.getRouteParam('token');
    this.smag_refresh_token = this.getRouteParam('refresh_token');
    this.user_already_logged = JSON.parse(localStorage.getItem('currentUser')) != null;
    if (this.smag_token != null) {
      this.getUsersFromToken();
    }
  }

  /**
   * Récupère la liste des utilisateurs du token
   */
  getUsersFromToken() {
    this.loading.show();
    this.smagAccountService.setSmagToken(this.smag_token);
    this.smagAccountService.setSmagRefreshToken(this.smag_refresh_token);
    //Appel du endpoint Smag pour récupérer les profils utilisateur actifs
    this.smagAccountService.getActiveUserProfils(this.smag_token, this.smag_refresh_token).subscribe((result) => {
        // les profils actifs
        if (result) {
          let smag_api_users = result;
          if (smag_api_users.length > 0) {
            let has_smag_account = true;
            if (smag_api_users.length == 1) {
              let user = smag_api_users[0];
              if(user.jarvis_token == "") {
                //compte suspendu
                this.LockNoUserActiveProfils();
              } else {
                this.one_smag_user = true;

                let smagUser = new SmagUser(user.login, user.first_name, user.last_name, user.Profile, user.jarvis_token, has_smag_account, this.smag_token, this.smag_refresh_token);
                this.loginUser(smagUser);
              }
            
            } else {
              this.many_smag_users = true;
              smag_api_users = this.sortByFirstName(smag_api_users);
              let users_list: SmagUser[] = [];
              for(let user of smag_api_users) {
                let smagUser = new SmagUser(user.login, user.first_name, user.last_name, user.Profile, user.jarvis_token, has_smag_account, this.smag_token, this.smag_refresh_token);
                users_list.push(smagUser);
                if (isNullOrUndefined(this.smag_users_grouped.find(x => x.label === user.Profile))) {
                  this.smag_users_grouped.push({label: user.Profile, users: []});
                }
                this.smag_users_grouped.find(x => x.label === user.Profile).users.push(smagUser);
              }

              this.smag_users = users_list;
              this.smagAccountService.setLogins(this.smag_users_grouped);
              this.loading.hide();
            }
          } else {
            //aucun compte actif pour l'utilisateur
            this.LockNoUserActiveProfils();
          }
        } else {
         
          this.loading.hide();
          this.undefined_error = this.getText('UndefinedError');
        }
    },
    (error => {
      console.log("Erreur lors de la récupération du compte SMAG", error);
      this.loading.hide();
      this.undefined_error = this.getText('UndefinedError');
    }))
  }


/**
 * Authentifie l'utilisateur du compte Smag
 * @param smag_user Utilisateur provenant de Smag
 */
  loginUser(smag_user: SmagUser) {
    //Authentification de l'utilisateur
    let already_logged:boolean = JSON.parse(localStorage.getItem('currentUser'));
    // En cas de callback provenant du smag_button par exemple
    let smag_account_already_set = JSON.parse(localStorage.getItem('smag_user'));
    let loginModel = new Login();
    loginModel.username = smag_user.login;
    loginModel.userid = smag_user.id;
    loginModel.token = smag_user.jarvis_token;
    loginModel.smag_token = this.smag_token;
    this.saveLoggedUserInfos(smag_user, loginModel);
    //Si on est dans le cas de l'authentification après connexion classique, on ferme juste la fenetre de connexion
    if(!already_logged || smag_account_already_set) {
      this.router.navigateByUrl("/");
    } else {

        // On masque tous les autres champs
        this.many_smag_users = false;
        this.one_smag_user = true;
        this.user_already_logged = true;
        this.loading.hide();
        this.smagAccountService.setShowCloseButton(true);
    }
  }

  saveLoggedUserInfos(smag_user: SmagUser, currentUser: Login) {
    localStorage.setItem('smag_user', JSON.stringify(smag_user));
    localStorage.setItem('show_smag_iframe', JSON.stringify('false'));
    localStorage.setItem('currentUser', JSON.stringify(currentUser));
  }

  /**
   * verouille accès sur aucun profil utilisateur actif retourné
   */
  private LockNoUserActiveProfils(){
    this.no_smag_user = true;
    this.text_no_users = this.getText('NoUserProfilsActives');
    this.loading.hide();
  }


  /**
   * Récupère un paramètre de la liste des paramètres
   * @param param
   * @returns string
   */
  private getRouteParam(param:string) {
    return this.route.snapshot.queryParamMap.get(param);
  }

  getText(key:string, touppercase = false) {
    const text = this.authSmagTextService.get(key);
    return touppercase ? text.toUpperCase() : text;
  }



  /*
  * Trie les utilisateurs par leur prénom
  */
  sortByFirstName(users) {
    return users.sort((a,b) =>{
      let a_user = slugify(a.first_name.toUpperCase() +' '+a.last_name.toUpperCase())
      let b_user = slugify(b.first_name.toUpperCase() +' '+b.last_name.toUpperCase())
      if (a_user > b_user) {return 1;}
      else if (a_user < b_user) {return -1}
      else return 0
    });
  }

}
