import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { itemValue } from './_models/itemValue';
import { menuItem, menuType } from './_models/menuItem';
import { JarvisPopupService } from '../jarvis-popup/jarvis-popup.service';
import { SmagAccountService } from '../../framework/auth/services/index';
import { User } from '../../_models/index';

@Component({
  selector: 'app-jarvis-ui',
  templateUrl: './jarvis-ui.component.html',
  styleUrls: ['./assets/font/style.css','./jarvis-ui.component.scss', './jarvis-ui.component.responsive.scss']
})
export class JarvisUiComponent implements OnInit {

  public showPopupFilter = false;
  public showMenuProfil = false;
  public showSmagButton = true;



  @Input()
  public CoopName: string;

  @Input()
  public ImageUrl: string;

  @Input()
  public User: User = new User();

  @Input()
  public Menus: menuItem[];

  @Output()
  public logout: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public SelectionChanged: EventEmitter<menuItem> = new EventEmitter<menuItem>();
  @Output()
  public ActionClick: EventEmitter<itemValue> = new EventEmitter<itemValue>();


  @Input()
  menuWith?(): string {
    return (100 / (this.Menus.length - 1)) + "%";
  }

  @Input()
  ActionMenus?(): menuItem[] {
    return this.Menus.filter(i => i.Checkable == menuType.Action);
  }
  @Input()
  FilterMenus?(): menuItem[] {
    return this.Menus.filter(i => i.Checkable != menuType.Action && i.Visible);
  }

  @Input()
  allItems(menu: menuItem) {
    var items: Array<itemValue> = new Array<itemValue>();

    menu.GroupItems.forEach(g => {
      items = items.concat(g.Items);
    });
    return items;
  }

  @Input()
  Selected?(code: string): number {

    return this.allItems(this.Menus.find(m => m.Code == code)).filter(i => i.Checked).length;
  }

  @Input()
  SelectedText?(code: string): string {
    return this.allItems(this.Menus.find(m => m.Code == code)).filter(i => i.Checked)[0].Value;
  }


  @Input()
  TitleSelectAll?(code: string): string {
    var itemCount = this.allItems(this.Menus.find(m => m.Code == code)).filter(i => i.Checked).length;
    if (itemCount == this.allItems(this.Menus.find(m => m.Code == code)).length)
      return "Désélectionner tous les élèments";
    else
      return "Sélectionner tous les élèments";
  }

  constructor(private popupService: JarvisPopupService, private smagAccountService: SmagAccountService) {
  }

  ngOnInit() {
    this.User = new User();
    this.smagAccountService.loadSmagButtonLibraries();
  }

  public mouseOutItems(event: any) {
    event.currentTarget.scrollTop = 0;
  }

  public selectAllClick(event: any) {
    var code = event.target.id.split("_")[1];
    var activMenu = this.Menus.find(x => x.Code === code);

    var check = (this.allItems(activMenu).filter(x => x.Checked == false).length == 0);

    this.allItems(activMenu).forEach(itemmenu => {
      itemmenu.Checked = !check;
    });
    this.SelectionChanged.emit(activMenu);
  }
  public itemActionClick(code: string, itemId:string) {

    var activMenu = this.Menus.find(x => x.Code === code);
    var selectedItemMenu = this.allItems(activMenu).find(x => x.Id == itemId);

    this.ActionClick.emit(selectedItemMenu);

  }
public menuGroupClick(event:any)
{
  /*l'ID passé doit contenir '_MenuGroup' pour que la fonction le prenne en compte Car appelé si selection par items */
  if(event && event.target!= null && event.target.id !=null && event.target.id.indexOf('_MenuGroup')!==-1 )
  { var code = event.target.id.split("_")[0];
  var groupId = event.target.id.split("_")[1];
   if(code!= null && groupId!=null)
   {
     /*Selection de l enseble des Exploitations de la coop */
     var activMenu = this.Menus.find(x => x.Code === code);
/*Si c est bien un menu checkable */
     if(activMenu!= null  && activMenu.GroupItems &&activMenu.Checkable == menuType.MultiSelectable)
    {
      if(!activMenu.MultipleGroupSelected)
      {/* permet pas la selection de group different donc deselection des autres */
        this.UnselectByGroupExcepte(code,groupId);
      }
      activMenu.GroupItems.forEach(grp=>{if(grp.Id ==groupId){grp.Items.forEach(t=> {if(!t.Checked){t.Checked=true}})}});
      this.SelectionChanged.emit(activMenu);
    }

   }
  }


}
  public itemClick(event: any) {

    var code = event.target.id.split("_")[0];
    var itemId = event.target.id.split("_")[1];
    var activMenu = this.Menus.find(x => x.Code === code);
    // Verification que la selection appartient au même groupe que les élèments précédement selectionnés
    if (!activMenu.MultipleGroupSelected && activMenu.Checkable == menuType.MultiSelectable) {

      var groupItemSelected = activMenu.GroupItems.filter(g => g.Items.filter(i => i.Checked).length > 0);
      if (groupItemSelected != null && groupItemSelected.length > 0) {
        var newGroupSelected = activMenu.GroupItems.filter(g => g.Items.filter(i => i.Id == itemId).length > 0);
        if (newGroupSelected != null && newGroupSelected.length > 0 && newGroupSelected[0].Id != groupItemSelected[0].Id) {
            /*Permet la selection d exploitation d une autre coop en deselectionnant les autres */
         this.UnselectByGroupExcepte(code,newGroupSelected[0].Id);
            // this.popupService.ThrowError('001', "Vous pouvez sélectionner uniquement des exploitations reliées à une même coopérative.");
        }
      }
    }

    if (activMenu.Checkable == menuType.Mono) {
      this.allItems(activMenu).forEach(itemmenu => {
        itemmenu.Checked = false;
      });
    }


    var selectedItemMenu = this.allItems(activMenu).find(x => x.Id == itemId);

    selectedItemMenu.Checked = !selectedItemMenu.Checked;

    this.SelectionChanged.emit(activMenu);

  }

  public onLogout() {
    this.logout.emit();
  }
//Permet la déselection des éléments sélectionnés dans les Autres Groupes que celui indiqué sélectionné (groupIDSelected)
  private  UnselectByGroupExcepte(menuCode:string,groupIDSelected:string)
  {
    var activMenu = this.Menus.find(x => x.Code === menuCode);
    if(activMenu!= null && activMenu.GroupItems!=null && activMenu.GroupItems.length>0)
    {/* on désélectionne les selection selon le group */
      activMenu.GroupItems.forEach(grp=>{if(grp.Id !=groupIDSelected){grp.Items.forEach(t=> {if(t.Checked){t.Checked=false}})}});
    }
  }

  public EmptyFarmSelection()
  {
    var farmMenu = this.Menus.find(x => x.Code === 'farm');
    this.SelectionChanged.emit(farmMenu);
  }
}
