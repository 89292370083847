import { Component, Inject } from '@angular/core';
import { NbAuthResult, NbAuthService } from '../../services/auth.service';
import { NB_AUTH_OPTIONS_TOKEN } from '../../auth.options';
import { Router } from '@angular/router';
import { getDeepFromObject } from '../../helpers';
import { Login } from './../../../../_models/index';
import { LoadingService } from '../loading/loading.service';
import { SmagAccountService } from '../../services/index';
import { AuthSmagTextService } from '../../../../_services';
import { DomSanitizer } from '@angular/platform-browser'

@Component({
  selector: 'nb-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  redirectDelay: number = 0;
  showMessages: any = {};
  provider: string = '';

  errors: string[] = [];
  messages: string[] = [];
  user: any = {};
  submitted: boolean = false;
  showLegacyLogin: boolean = false;
  public authSmagTextService: AuthSmagTextService;

  constructor(protected service: NbAuthService,
              @Inject(NB_AUTH_OPTIONS_TOKEN) protected config = {},
              protected router: Router,
              private loading: LoadingService,
              private smagAccountService: SmagAccountService,
              private _authSmagTextService: AuthSmagTextService,
              private sanitized: DomSanitizer
              ) {

    this.redirectDelay = this.getConfigValue('forms.login.redirectDelay');
    this.showMessages = this.getConfigValue('forms.login.showMessages');
    this.provider = this.getConfigValue('forms.login.provider');
    this.authSmagTextService = _authSmagTextService;

  }

  ngOnInit(): void {
    this.smagAccountService.clearSmagAccount();
    this.preserve();
  }

  private preserve() : void{
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if(currentUser && currentUser.stay_connected){
      this.loading.show();
      this.service.refresh(this.provider,currentUser.token, currentUser.refresh_token).subscribe((result: NbAuthResult) => {
        this.submitted = false;
        if (result.isSuccess()) {
          currentUser.refresh_token = result.getRefreshTokenValue();
          currentUser.token = result.getTokenValue();
          localStorage.setItem('currentUser',JSON.stringify(currentUser));
        } else {
          this.loading.hide();
          this.errors = result.getErrors();
        }

        const redirect = result.getRedirect();
        if (redirect) {
          setTimeout(() => {
            return this.router.navigateByUrl(redirect);
          }, this.redirectDelay);
        }
      });

    }
  }

  login(loginForm: any): void {
    this.errors = this.messages = [];
    this.submitted = true;

    if (!loginForm.form.valid) {
      return;
    }

    this.loading.show('Merci de patienter');
    this.service.authenticate(this.provider, this.user).subscribe((result: NbAuthResult) => {
      this.submitted = false;
      if (result.isSuccess()) {
        this.messages = result.getMessages();
        const user = result.getResponse();

        if (user && user.Token) {
          var loginModel = new Login();
          loginModel.username = this.user.username;
          loginModel.userid = user.UserId;
          loginModel.token = user.Token;
          loginModel.refresh_token = user.Refresh_Token;
          loginModel.stay_connected = this.user.stayconnected;
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(loginModel));
        }
      } else {
        this.loading.hide()
        this.errors = result.getErrors();
      }

      const redirect = result.getRedirect();
      if (redirect) {
        setTimeout(() => {
          return this.router.navigateByUrl(redirect);
        }, this.redirectDelay);
      }
    });
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.config, key, null);
  }

  launchSmagAccountConnection() {
    this.loading.show();
    this.smagAccountService.getSmagAccountUrl().subscribe(
      (result) => {
        this.loading.hide();
        window.location.href = result;
      },
      (error: any) => {
        console.log("Erreur lors de la récupération de l'url de connexion:", error)
        this.loading.hide();
      });
    return false;
  }

  getText(key:string, touppercase = false) {
    const text = this.authSmagTextService.get(key);
    return touppercase ? text.toUpperCase() : text;
  }

}
