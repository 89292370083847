import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { Console, PrecizionRequest, ViewShapeConvertUnit } from '../_models/index';
import { environment } from '../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { HttpRequestService } from '../_services/index';
import { saveAs } from 'file-saver';
import { isNullOrUndefined } from '../_helpers';

declare let doPostRequest: any;

@Injectable()
export class PrecizionServices extends BehaviorSubject<Console[]> {
    private baseUrl = environment.Precizion.url;
    constructor(private httpRequestService: HttpRequestService) {
        super([]);
    }

    public getPrecizions() {
      const body = {};
      return this.httpRequestService.PostRequest(this.baseUrl + environment.Precizion.get_all_console, body, 'getPrecizions').pipe(map(
            (response) => {
                let apps = response;
                return this.convertJsonToConsole(apps);
            }));
    }

    public getConsoleDownload(param: PrecizionRequest) {
        
        return this.httpRequestService.PostRequest(this.baseUrl + environment.Precizion.get_console_download, param, 'getConsoleDownload').pipe(
            map(
                (response) => {
                    return response;
                })
        );
    }

    public Downlaod(res: any): void {
        // doPostRequest existe dans module carto permet de simuler l'envoi d'un formulaire
        doPostRequest('POST', this.baseUrl + 'test.zip', { filePath: res, saveAs: true }, '_blank');
    }

    public getShapeDownload(param: PrecizionRequest) {
        return this.httpRequestService.PostRequest(this.baseUrl + environment.Precizion.get_shape_download, param, 'getShapeDownload').pipe(
          map(
            (response) => {
                return response;
            })
        );
    }

    /**
     * Download the generated Export file
     * @param _iEFileDownloader structure precision File Downloader
     * @returns 
     */
    public downloadExportedFile(_iEFileDownloader:any):Observable<any> {
     return this.httpRequestService.GetExportedFile(this.baseUrl + environment.Precizion.file_download+'?ExportUId='+_iEFileDownloader.Uid,_iEFileDownloader).pipe(
        map((response: any)=>{
              if(response) {
                this.downLoadBlobFile(response,_iEFileDownloader.BlobFileType, _iEFileDownloader.Name)
                return true;
              } else {
                return throwError(new Error("no yet available file"));
              }
               
            }),
            catchError(err => {
                console.log('Error downloadExportedFile', err);
                return throwError(err);
            })
        )
    }

       /**
     * Method is use to download file.
     * @param data - Array Buffer data
     * @param type - type of the document. 'application/zip, application/octet-stream'
     *  @param _iFileName - file name to set on file
     */
      private  downLoadBlobFile(data: any, _iBlobType: string, _iFileName:string) {
            let blob = new Blob([data], { type: _iBlobType, });
            let url = window.URL.createObjectURL(blob);
      
            if(_iFileName && _iFileName != "") {
              const anchor = document.createElement("a");
              anchor.download = _iFileName;
              anchor.href = url;
              anchor.click();
            } else{
              let pwa = window.open(url);
              if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
                  alert( 'Veuillez désactiver votre bloqueur de popup et essayer à nouveau.');
              }
            }
           
        }
    

    private convertJsonToConsole(any): Console[] {
        let convertedApps = new Array<Console>();
        
        let preselectedNdx = -1;
        if (any) {

            for(let ndx=0;ndx<any.length;ndx++) {
                var element = any[ndx];
                if(element.preselected == true){
                    preselectedNdx = ndx;
                }
                convertedApps[ndx] = new Console();
                convertedApps[ndx].id = element.id;
                convertedApps[ndx].name = element.name;
                convertedApps[ndx].manufacturer = element.manufacturer;
                convertedApps[ndx].shownName = element.manufacturer + " - " + element.name;
            }
        }
           
        if(convertedApps.length>1)
        {/* ORDONNE les consoles */
            convertedApps=  convertedApps.sort(function(a,b){
                return ( isNullOrUndefined(a.shownName))?-1:( isNullOrUndefined(b.shownName))?1: (a.shownName<b.shownName)?-1:(a.shownName>b.shownName)?1:0;
            });
            //positionne la preselection en 1e position
            if (preselectedNdx > 0) {
                let f = convertedApps.splice(preselectedNdx, 1)[0];
                convertedApps.splice(0, 0, f);
            }
        }
        localStorage.setItem('consoles', JSON.stringify(convertedApps));
        return convertedApps;
    }

     /**
      * Retourne les unités de convertion pour les shape
      * @param _iBasicUnit
      * @param _iShapeConvertUnit ensemble des unités convertibles
      * @returns
      */
      public GetShapeConvertUnit(_iBasicUnit:string, _iShapeConvertUnit:any[]) {
        let oConvertUnit :ViewShapeConvertUnit;
       let preselected = null;
        let units:any[] = [];

        if(_iBasicUnit && _iShapeConvertUnit && _iShapeConvertUnit.length) {
            let upp_basic_unit = _iBasicUnit.toUpperCase();
            let _ShapeConvertUnit = _iShapeConvertUnit[0];
            if(_ShapeConvertUnit[upp_basic_unit]) {
                let currentkey= _ShapeConvertUnit[upp_basic_unit].key;
                let formatkey = currentkey+"_"+currentkey;
                Object.keys(_ShapeConvertUnit[upp_basic_unit].targets).forEach((key) => {
                    let element = _ShapeConvertUnit[upp_basic_unit].targets[key];

                    // id selectionné
                    if( element.key == formatkey) {
                        preselected = element.key;
                    }
                    units.push({key:element.key, text: this.FormatUnitOnArea(element.translate)})
                });
            }
            oConvertUnit = new ViewShapeConvertUnit(preselected,units)
        }
        return oConvertUnit;

    }

     /**
      * Formatage d'une unité en ajoutant à l hectare (+/ha)
      * @param _iUnit l'unité
      * @returns
      */
      public FormatUnitOnArea(_iUnit:string):string {
         return _iUnit ? _iUnit + "/ha" : _iUnit;
    }


    /**Retourne l'ensemble des unités de convertion pour les shapefiles */
    public GetDatasShapeConvertUnits():any[] {
        let oUnitsConvert :any[]= [  {
             "T":{
                 key:"T",
                 targets:{
                     "T_KG": {key: "T_KG", translate:"Kg"},
                     "T_T": {key: "T_T", translate:"T"},
                 }
             },
             "KG":{
                 key:"KG",
                 targets:{
                     "KG_T": {key: "KG_T", translate:"T"},
                     "KG_KG":  {key: "KG_KG", translate:"Kg"},
                 }
             },
             "L":{
                 key:"L",
                 targets:{
                     "L_M3":  {key: "L_M3", translate:"m3"},
                     "L_L":  {key: "L_L", translate:"L"},
                 }
             },
             "M3":{
                 key:"M3",
                 targets:{
                     "M3_L":  {key: "M3_L",translate:"L"},
                     "M3_M3": {key: "M3_M3", translate:"m3"},
                 }
             },
         }];
         return oUnitsConvert;
     }
}

