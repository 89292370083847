import {
    Farm, Sector, CultivationPlot, MicroPlot, LegendItem, LegendProperties, LegendProperty, Observation, LegendGroup,
    Legend, LegendColor, JsonStyle, Intervention, farmConfig, LegendExtProperty
} from '../_models/index';
import { Injectable, OnDestroy } from '@angular/core';
import { HandleService } from './handle.service';
import { FarmServices } from './farm.service';
import { mcPreconisation } from '../_models/beapi/mcPreconisation';
import { isNullOrEmptyString, isNullOrUndefined } from '../_helpers';
import { PrintManager, PrintPdfExtraModel } from '../_models/print/print_manager';
// Déclaration Openlayers
declare let ol: any;

@Injectable()
export class LegendService implements OnDestroy {

    ngOnDestroy(): void {

    }
    private _legdModulations = LegendsConfig.legdModulations;
    ///legende preconisation fumure step 4
    private _legdPrecoFumure = LegendsConfig.legdPrecoFumur;
    //legende preconisation pilotage azote SP
    private _legdPilotPreco = LegendsConfig.legPilotSpPreco;
    private _legdInterModePreco = LegendsConfig.legInterModePreco;
    public ilotFeatures: any[] = [];
    public plotFeatures: any[] = [];
    public microFeatures: any[] = [];
    public legend: LegendGroup[] = [];
    public legendColorAttrs: Legend;
    ///indique si il a chargé par parcelle la legende conseil fumur
    private PlotAdviceFumurLegLoaded: boolean = false;

    /**Nom de la propriete Observation Conseil Azote:N */
    private ObsNameN: string = "FE_NAMC";
    /**Nom de la propriete Observation Conseil Azote:SO3 Soufre */
    private ObsNameSo3: string = "FE_SAMC";
    /**Nom de la propriete Observation Date ( N ou PK) */
    private ObsNameDate: string = "BA_DTCONS";
    private ObsTypePK = "0";
      /**Reprend les Modulation Preco Type Pour PDF */
    public ModPrecosTypes:String[]=[];
    /**Reprend les  Prestations diagnostic (annuels - Pilot LAI) Type Pour PDF */
    public DiagnosticPrestTypes:String[]=[];
    /**Reprend les Interventions en mode preco Pour PDF */
    public IntersModePreco:any;

    /**reprend les impressions supplémentaires formatées */
    public ExtrasPrintsModels:PrintPdfExtraModel[] =[];

    clean() {
        this.ilotFeatures = [];
        this.plotFeatures = [];
        this.microFeatures = [];
        this.legend = [];
        this.legendColorAttrs = undefined;
        this.PlotAdviceFumurLegLoaded = false;
        this.ModPrecosTypes=[];
        this.DiagnosticPrestTypes=[];
        this.IntersModePreco=[];
        this.ExtrasPrintsModels =[];
    }


    loadFeatures(farms: Farm[]): any {

        if (farms === null || farms === undefined || farms.length === 0)
            return;
        this.ilotFeatures = [];
        this.plotFeatures = [];
        this.microFeatures = [];
        this.legend = [];
        let p205 = false;
        /*  if (farms === null || farms === undefined || farms.length === 0)
          {this.hasLegendClassLoaded = false;
              this.legendColorAttrs = undefined;
              return;
          }*/

        this.PlotAdviceFumurLegLoaded = false;

        farms.forEach(sector => {

            if (sector.Sectors === null)
                return;
            /// indique si sur l exploitation on est sur prestation Pack ou Potentiel
            let _blFarmPrestPot: boolean = sector.PresTypePack() || sector.PresTypePotentiel();
            let _blModulation = sector.PresTypeModulation();
            let _blAnnual=sector.IsAnnualOrder();
            //si c est un diagnostic pilot sp LAI
            let _blIsDiagLai = sector.IsDiagPilotSPLAI();

            // si c est un diagnostic (LAI ou Annuel)
            let _blIsDiagnostic = sector.IsDiagnostic();
            let _blPilotSpLAI = sector.IsOrderPilotSPLAI();
            if(_blModulation )
            {
                if(!FarmServices.IncludePlotModulation(sector) )
                    {// ORDER modulation AVEC au - 1 preconisation
                        return;
                    }
            }

            sector.Sectors.forEach(element => {
                let geom = element.Geometry;
                if (geom === null && !_blIsDiagnostic)
                    return;
                if(!_blIsDiagnostic && !_blPilotSpLAI && !_blModulation)
                {
                   
                    let polylines = new ol.geom.Polygon(geom.coordinates);
                    if (polylines.flatCoordinates) {
                        let featureIlot = new ol.Feature({
                            geometry: polylines,
                            properties: this.loadIlotProperties(sector, element)
                        })
                        this.ilotFeatures.push(featureIlot);
                    }
                }

                //add plot
                if (element.CultivationPlots == null)
                    return;

                this.PlotAdviceFumurLegLoaded = false;/*recharge par parcelle */

                element.CultivationPlots.forEach(plots => {
                    let geometryPlot = plots.Geometry;
                    if (geometryPlot == null && !_blIsDiagnostic)
                        return;

                    let polylinesPlot = !_blIsDiagnostic? new ol.geom.Polygon(geometryPlot.coordinates):null;
                    //on evite la superposition , le step Order (dont decoule modulation et pilot sp) reste le principale
                    if ((polylinesPlot && polylinesPlot.flatCoordinates && !_blPilotSpLAI && !_blModulation) || _blIsDiagnostic) {
                        let featurePlot = new ol.Feature({
                            geometry: polylinesPlot,
                            properties: this.loadParcelleProperties(sector, element, plots, farms[0].IdCampagne)

                        })
                        this.plotFeatures.push(featurePlot);
                       
                    }
                    if (plots.MicroPlots == null)
                        return;

                    /** conserve le dernier Observation N type de la plot*/
                    let _plotMajorObsNType: string = _blFarmPrestPot ? this.getMajorObsTypeWithValue(plots, this.ObsNameN) : null;
                    /** conserve le dernier Observation SO3 type de la plot*/
                    let _plotMajorObsSO3Type: string = _blFarmPrestPot ? this.getMajorObsTypeWithValue(plots, this.ObsNameSo3) : null;
                    plots.MicroPlots.forEach(micro => {

                        let microPlotGeometry = micro.Geometry;
                        if (microPlotGeometry == null)
                            return;

                        if (!p205){
                            this.loadLegendStatic(farms, micro.MicroPlotDetails.P205EpiclesName);

                        }


                        p205 = true
                        let polylinesMicro = new ol.geom.Polygon(microPlotGeometry.coordinates);
                        if (polylinesMicro == null)
                            return;

                        let featureMicro = new ol.Feature({
                            geometry: polylinesMicro,
                            nameIlot: element.Name,
                            namePlot: plots.NameCultivationPlot,
                            nameMicro: micro.Name,
                            farm: sector,
                            properties: this.loadMicroParcelleProperties(sector, element, plots, micro, _blFarmPrestPot, _plotMajorObsNType, _plotMajorObsSO3Type, _blModulation, _blAnnual, _blIsDiagLai)

                        });
                        
                        this.microFeatures.push(featureMicro);

                    })
                })
            });
                
        });
        
        this.ClearEmptyStyleLegend(LegendsConfig.legdAnnaRend);
        this.ClearEmptyStyleLegend(LegendsConfig.legdAnnaAzote);
        this.ClearEmptyStyleLegend(LegendsConfig.legdAnnaCarboN);
        this.ClearOnEmptyLegend(LegendsConfig.legdPilotSpLAI);

        this.ClearOnEmptyLegend(this._legdModulations);
        this.ClearOnEmptyLegend(this._legdInterModePreco);
        this.ModPrecosTypes= this.getPDFModulationTypes();
        this.DiagnosticPrestTypes=this.GetPDFDiagnosticTypes();
        this.ExtrasPrintsModels = this.getPDFExtraPrintTypes();
    }

    loadIlotProperties(farm: Farm, ilot: Sector): LegendProperties[] {
        if (!ilot) return null;
        let ilotLegendProperties: LegendProperty[] = [];
        ilotLegendProperties.push(
            this.InitLegendProperty("Exploitation", farm.NameExploitation)
            )
        ilotLegendProperties.push(
            this.InitLegendProperty("Ilot n°", ilot.PacNumber.toString())
            )
        return [{
            legendGroupKey: "Assolement",
            legendItemKey: "Ilots",
            legendProperies: ilotLegendProperties
        }];
    }

    loadParcelleProperties(farm: Farm, ilot: Sector, parcelle: CultivationPlot, IdCampagne: number): LegendProperties[] {
        if (!parcelle) return null;
        let parcelLegendProperties: LegendProperty[] = [];
        let parcelPreviousLegendProperties: LegendProperty[] = [];
        parcelLegendProperties.push(
            this.InitLegendProperty("Exploitation", farm.NameExploitation),
            this.InitLegendProperty("Parcelle", this.FormatPlotWithAreaText(parcelle)),
            this.InitLegendProperty("Ilot n°", ilot.PacNumber.toString()),
            this.InitLegendProperty("Campagne", IdCampagne.toString()),
            this.InitLegendProperty("Culture_color", parcelle.CultivationSelected ? parcelle.CultivationSelected.Color : "",null,false),
            this.InitLegendProperty("Culture", parcelle.CultivationSelected ? parcelle.CultivationSelected.Name : ""),
            this.InitLegendProperty("Variété", parcelle.SpecieSelected ? parcelle.SpecieSelected.Name : "")
           );
        parcelPreviousLegendProperties.push(
            this.InitLegendProperty("Exploitation", farm.NameExploitation),
            this.InitLegendProperty("Parcelle", this.FormatPlotWithAreaText(parcelle)),
            this.InitLegendProperty("Ilot n°", ilot.PacNumber.toString()),
            this.InitLegendProperty("Campagne", (IdCampagne - 1).toString()),
            this.InitLegendProperty("Culture_color", parcelle.PreviousCultivationSelected ? parcelle.PreviousCultivationSelected.Color : "",null,false),
            this.InitLegendProperty("Culture", parcelle.PreviousCultivationSelected ? parcelle.PreviousCultivationSelected.Name : ""),
)
        return [{
            legendGroupKey: "Assolement",
            legendItemKey: IdCampagne.toString(),
            legendProperies: parcelLegendProperties
        }, {
            legendGroupKey: "Assolement",
            legendItemKey: (IdCampagne - 1).toString(),
            legendProperies: parcelPreviousLegendProperties
        }];

    }


    /**
     *   Retourne les propriétes voulues en fonction du code groupe passer en parametre
     * @param farm
     * @param sector
     * @param cultivationPlot
     * @param microPlot
     * @param blPrestPotent Indique que c est une prestation Potentiel ou Pack
     * @param plotMajorObsNType Observation Type Majour au Plot pour conseil Azote N
     * @param plotMajorObsSo3Type Observation Type Majour au Plot pour conseil Azote SO3
     * @param _iblIsModulation indique si on est sur un order modulation
     * @param _iblIsAnnual indique si on est sur un diagnostic annuel ( RDT ou Azote)
     * @param _iblIsDiagLai indique si on est sur un diagnostic Pilot Azote SP LAI
     */
    loadMicroParcelleProperties(farm: Farm, sector: Sector, cultivationPlot: CultivationPlot, microPlot: MicroPlot, blPrestPotent: boolean, plotMajorObsNType: string, plotMajorObsSo3Type: string, _iblIsModulation: boolean, _iblIsAnnual:boolean=false, _iblIsDiagLai:boolean=false): LegendProperties[] {

        let returnList: LegendProperties[] = [];
        let _blMicroIncludeObs: boolean = microPlot.Observations != null && microPlot.Observations.length > 0;
      
        let _blMicroPk = false;
        let _blMicroN = false;

        let _isDiagnostic = _iblIsAnnual == true || _iblIsDiagLai;
        let _blPilotOrder = !_iblIsDiagLai && farm.IsOrderPilotSPLAI();
        let _cloneMicro = new MicroPlot(microPlot);
        _blMicroPk = !_iblIsModulation && !_isDiagnostic && !_blPilotOrder ? _cloneMicro.IsFerti() : false;
        _blMicroN = !_iblIsModulation && !_isDiagnostic  && !_blPilotOrder ? _cloneMicro.IsPotentiel() : false;


        let P2O5Type: string = null;
        if (microPlot.MicroPlotDetails.P205EpiclesName != null) {
            let P2O5UppEpicName: string = microPlot.MicroPlotDetails.P205EpiclesName.toUpperCase();
            if (P2O5UppEpicName === 'P2O5_OL')
                P2O5Type = 'Olsen';
            else if (P2O5UppEpicName === 'P2O5_JH')
                P2O5Type = 'Joret Hébert';
            else if (P2O5UppEpicName === 'P2O5_DY')
                P2O5Type = 'Dyer';
        }


        if (_blMicroPk) {
            /// >>Diagnostic fertilité
            let dictvaluesFertilite = new Array<LegendProperty>();
            dictvaluesFertilite.push(
                this.InitLegendProperty("Exploitation", farm.NameExploitation),
                this.InitLegendProperty("Parcelle", this.FormatPlotWithAreaText(cultivationPlot)),
                this.InitLegendProperty("Ilot", sector.PacNumber != null ? sector.PacNumber.toString() : ""),
                this.InitLegendProperty("Date", HandleService.getStringLocalDate(microPlot.MicroPlotDetails.AnalysisDate)),
                this.InitLegendProperty("Identifiant microparcelle", (microPlot.MicroPlotDetails.IdBeApi != null ? microPlot.MicroPlotDetails.IdBeApi.toString() : "")),
                this.InitLegendProperty("Identifiant conseil", microPlot.Id.toString()),
                this.InitLegendProperty("pH", (Math.round(microPlot.MicroPlotDetails.PhWater * 100) / 100).toString()),
                this.InitLegendProperty("Teneur prise en compte pour le conseil", P2O5Type),
                this.InitLegendProperty("K2O", (microPlot.MicroPlotDetails.K2O != null ? microPlot.MicroPlotDetails.K2O.toString() : ""),'ppm'),
                this.InitLegendProperty("MgO", (microPlot.MicroPlotDetails.MgO != null ? microPlot.MicroPlotDetails.MgO.toString() : ""),'ppm'),
                this.InitLegendProperty("CaO", (microPlot.MicroPlotDetails.Limestone != null ? microPlot.MicroPlotDetails.Limestone.toString() : ""),'ppm'),
                this.InitLegendProperty("K2O/MgO", (microPlot.MicroPlotDetails.K2OMGO != null ? (Math.round(microPlot.MicroPlotDetails.K2OMGO * 100) / 100).toString() : "")),
                this.InitLegendProperty("CEC", (microPlot.MicroPlotDetails.CEC != null ? microPlot.MicroPlotDetails.CEC.toString() : ""),'meq/100g'),
                this.InitLegendProperty(LegendsConfig.legLibFertiMATORG, (microPlot.MicroPlotDetails.MATORG != null ? (Math.round(microPlot.MicroPlotDetails.MATORG * 100) / 100).toString() : ""),'%'),
                this.InitLegendProperty("<br>Profil pédologique",microPlot.MicroPlotDetails.SoilType),

                this.InitLegendProperty("Taux de cailloux dans le sol", (microPlot.MicroPlotDetails.SoilRateStone != null ? microPlot.MicroPlotDetails.SoilRateStone.toString() + " %" : "0 %"),'%'),
                this.InitLegendProperty("Profondeur d’enracinement", (microPlot.MicroPlotDetails.RootingDepth != null ? microPlot.MicroPlotDetails.RootingDepth.toString() : ""),'cm'),
                this.InitLegendProperty("Profondeur du sol", (microPlot.MicroPlotDetails.SoilDepth != null ? microPlot.MicroPlotDetails.SoilDepth.toString() : ""),'cm'),
                this.InitLegendProperty("<br>N° analyse", microPlot.MicroPlotDetails.AnalysisNumber),
                this.InitLegendProperty("Laboratoire", microPlot.MicroPlotDetails.LaboratoryName))
                                         let indexLastP2O5: number = 7;

            if (microPlot.MicroPlotDetails.P2O5OL != null && microPlot.MicroPlotDetails.P2O5OL != 0.0) {
                dictvaluesFertilite.splice(indexLastP2O5, 0,this.InitLegendProperty("P2O5 Olsen", (microPlot.MicroPlotDetails.P2O5OL != null ? microPlot.MicroPlotDetails.P2O5OL.toString() : ""),'ppm'))
                indexLastP2O5 = indexLastP2O5 + 1;
            }
            if (microPlot.MicroPlotDetails.P2O5JH != null && microPlot.MicroPlotDetails.P2O5JH != 0.0) {
                dictvaluesFertilite.splice(indexLastP2O5, 0,this.InitLegendProperty("P2O5 Joret Hébert", (microPlot.MicroPlotDetails.P2O5JH != null ? microPlot.MicroPlotDetails.P2O5JH.toString() : ""),'ppm'))
                    indexLastP2O5 = indexLastP2O5 + 1;
            }
            if (microPlot.MicroPlotDetails.P2O5DY != null && microPlot.MicroPlotDetails.P2O5DY != 0.0) {
                dictvaluesFertilite.splice(indexLastP2O5, 0,this.InitLegendProperty("P2O5 Dyer", (microPlot.MicroPlotDetails.P2O5DY != null ? microPlot.MicroPlotDetails.P2O5DY.toString() : ""),'ppm'))
                indexLastP2O5 = indexLastP2O5 + 1;
            }
            if (indexLastP2O5 == 7 && isNullOrUndefined(P2O5Type)) {/*Pas d info P2O5 */
                dictvaluesFertilite.splice(7, 1);
            }


            returnList.push({
                legendGroupKey: "Diagnostic fertilité",
                legendItemKey: "",
                legendProperies: dictvaluesFertilite
            });

        }
        // >>Conseil AZOTE
        if (_blMicroN) {
            if (blPrestPotent && _blMicroIncludeObs) {
                returnList.push(this.LoadLegendConsAzote(farm, sector, cultivationPlot, microPlot, plotMajorObsNType, plotMajorObsSo3Type));
            }
            // >>Diagnostic Potentiel
            let dictvaluesPotentiel = new Array<LegendProperty>();
            dictvaluesPotentiel.push(
                this.InitLegendProperty("Exploitation", farm.NameExploitation),
                this.InitLegendProperty("Parcelle", this.FormatPlotWithAreaText(cultivationPlot)),
                this.InitLegendProperty("Ilot", sector.PacNumber != null ? sector.PacNumber.toString() : ""),
                this.InitLegendProperty("Date", HandleService.getStringLocalDate(microPlot.MicroPlotDetails.MeasuredDate)),
                this.InitLegendProperty("Identifiant microparcelle", microPlot.MicroPlotDetails.IdBeApi),
                this.InitLegendProperty("Identifiant conseil", (microPlot.Id != null ? microPlot.Id.toString() : "")),
                this.InitLegendProperty("Potentiel", (microPlot.MicroPlotDetails.SoilPotential != null ? microPlot.MicroPlotDetails.SoilPotential.toString() : "")),
                this.InitLegendProperty("Réserve utile totale", (microPlot.MicroPlotDetails.RU_TOT != null ? microPlot.MicroPlotDetails.RU_TOT.toString() : ""),'mm'),
                this.InitLegendProperty("Humidité capacité au champ", (microPlot.MicroPlotDetails.HCC_TOT != null ? microPlot.MicroPlotDetails.HCC_TOT.toString() : ""),'%'),
                this.InitLegendProperty("<br>Profil pédologique", microPlot.MicroPlotDetails.SoilType),
                this.InitLegendProperty("Type de sol (Epiclès)", (cultivationPlot.GroundTypeSelected) ? cultivationPlot.GroundTypeSelected.Name : ""),
                this.InitLegendProperty("Taux de cailloux dans le sol", (microPlot.MicroPlotDetails.SoilRateStone != null ? microPlot.MicroPlotDetails.SoilRateStone.toString() + " %" : ""),'%'),
                this.InitLegendProperty("Profondeur d’enracinement", (microPlot.MicroPlotDetails.RootingDepth != null ? microPlot.MicroPlotDetails.RootingDepth.toString() : ""),'cm'),
                this.InitLegendProperty("Profondeur du sol", microPlot.MicroPlotDetails.SubSoilDepth != null ? microPlot.MicroPlotDetails.SubSoilDepth.toString() : "",'cm'))

                if (microPlot.MicroPlotDetails.CE1 != null) {
                dictvaluesPotentiel.splice(6, 0,
                    this.InitLegendProperty("Conductivité électrique", (microPlot.MicroPlotDetails.CE1 ? microPlot.MicroPlotDetails.CE1.toString() : ""),'millisiemens/m'))
                }
                if (microPlot.MicroPlotDetails.APM ) {
                    dictvaluesPotentiel.splice(10, 0,
                        this.InitLegendProperty("APM", (microPlot.MicroPlotDetails.APM ? microPlot.MicroPlotDetails.APM.toString() : ""),''))
                }

            returnList.push({
                legendGroupKey: "Diagnostic potentiel",
                legendItemKey: "",
                legendProperies: dictvaluesPotentiel
            });
            let legendPotItem = this.legend.find(a => a.key != null && a.key.toUpperCase() === "DIAGNOSTIC POTENTIEL");
            if (legendPotItem != null && legendPotItem.items != null && farm.Legends != null && farm.Legends.length > 0) {
                legendPotItem.items.forEach(item => {
                    if (item.name != null) {
                        if (item.name.toUpperCase() === "POTENTIEL") {
                            this.BindingLegendsStyles(item, farm.Legends, "POTENTIEL", "Potentiel");
                        }
                        /*RU et HCC Nommage Selon legend.Service */
                        else if (item.name.toUpperCase() === "RU") {
                            this.BindingLegendsStyles(item, farm.Legends, "RU_TOT", "Réserve utile totale");
                        }
                        else if (item.name.toUpperCase() === "HCC") {
                            this.BindingLegendsStyles(item, farm.Legends, "HCC_TOT", "Humidité capacité au champ");
                        } else if (item.name.toUpperCase() === LegendsConfig.legPotentielAPM) {
                            this.BindingLegendsStyles(item, farm.Legends, "APM", "APM");
                        }
                    }
                });

            }
        }

        if (_blMicroPk) {
            /// >>Conseil fumure
            let dictAdviceFumure = new Array<LegendProperty>();
            dictAdviceFumure = this.InitBasicProperties(farm, sector, cultivationPlot, microPlot, false);
            dictAdviceFumure.push(
                this.InitLegendProperty("Date conseil", (_blMicroIncludeObs && this.getObservation(microPlot, this.ObsNameDate, this.ObsTypePK, true) != null
                ? HandleService.getLOCALDate(this.getObservation(microPlot, this.ObsNameDate, this.ObsTypePK, true).Value)
                : "")),
                this.InitLegendProperty("Objectif de rendement",  microPlot.MicroPlotDetails.ObjectifRendement, "T/ha"),
                this.InitObsLegendProperty("P2O5", _blMicroIncludeObs, microPlot.Observations, "FE_PAMC"),
                this.InitObsLegendProperty("K2O", _blMicroIncludeObs, microPlot.Observations, "FE_KAMC"),
                this.InitObsLegendProperty("MgO", _blMicroIncludeObs, microPlot.Observations, "FE_MGAMC"),
                this.InitObsLegendProperty("CaO", _blMicroIncludeObs, microPlot.Observations, "FE_CAAMC"),
            );
            returnList.push({
                legendGroupKey: "Conseil fumure",
                legendItemKey: "",
                legendProperies: dictAdviceFumure
            });

            // chargement des legendes ( component left)
            let legendItem = this.legend.find(a => a.key === "Conseil fumure");

            if (legendItem && (!this.PlotAdviceFumurLegLoaded) && _blMicroIncludeObs) {
                let CntLoaded: number = 0;
                legendItem.items.forEach(item => {
                    if (item.legendName === "P2O5") {
                        if (this.BindingReqObsStyles(item, "FE_PAMC", microPlot))
                            CntLoaded++;
                    }
                    else if (item.legendName === "K2O") {
                        if (this.BindingReqObsStyles(item, "FE_KAMC", microPlot))
                            CntLoaded++;
                    }
                    else if (item.legendName === "MgO") {
                        if (this.BindingReqObsStyles(item, "FE_MGAMC", microPlot))
                            CntLoaded++;

                    }
                    else if (item.legendName === "CaO") {
                        if (this.BindingReqObsStyles(item, "FE_CAAMC", microPlot))
                            CntLoaded++;
                    }
                    if (CntLoaded > 3)/*il en a chargé sur la micro pour la current parcelle */
                        this.PlotAdviceFumurLegLoaded = true;
                })
            }
        }

        /// >>Modulation fumure || pilot SP LAI
        if (cultivationPlot && cultivationPlot.Preconisations)
            if (!_iblIsModulation) {
                let legendPreco = !_blPilotOrder ? this._legdPrecoFumure : this._legdPilotPreco;
                
                if(cultivationPlot.MicroPlots!=null && cultivationPlot.MicroPlots.length>0)
                for (let i = 0; i < cultivationPlot.Preconisations.length; i++) {
                    let _plPreco:Intervention= new Intervention(cultivationPlot.Preconisations[i]) ;

                    if (!microPlot.Preconisations || this.IsEmptyFertiDose(_plPreco) || !_plPreco.IncludeIdent()) {
                        continue;
                    }
                    //si il a pas de preco ou pas de dose à la plot ou la micro on remplit pas
                    let _currMcPreco:mcPreconisation=null;
                     if ( !this.IncludeMcPrecoDoseByIdent(cultivationPlot, _plPreco.Ident)) {//aucune micro de la plot ne dispose de dose >0
                            continue;
                     }
                     _currMcPreco = microPlot.Preconisations.find(t=> this.EqualByIdent(t,_plPreco.Ident));
                      if(!_currMcPreco || this.IncludeMcFertiDose(_currMcPreco, false) == false)
                        {
                            continue;
                        }
                    let _plPrcFerti=_plPreco.Fertilizer;
                    let precoProductLegendName = _plPrcFerti.Name + " - " + HandleService.getLOCALDate(_plPreco.BeginDate);


                    let _mcPrecDose = _currMcPreco.Fertilizer.Dose;
                    let _teneur_unit = 'kg/ha';
                    let _product_unit =_plPrcFerti.Unit;
                    let dictvaluesModulationFumure = [];
                    dictvaluesModulationFumure = this.FormatCommonPrecoLegend(farm, sector, cultivationPlot);
                    dictvaluesModulationFumure.push(
                        this.InitLegendProperty("Identifiant microparcelle", microPlot.MicroPlotDetails.IdBeApi),
                        this.InitLegendProperty("Identifiant conseil", microPlot.Id.toString()),
                        this.InitLegendProperty("Produit",_plPrcFerti.Name),
                        this.InitLegendProperty("Dose", _mcPrecDose,_plPrcFerti.Unit.length ? _plPrcFerti.Unit + '/ha' : ''),
                        this.InitLegendProperty("Dose moyenne à la parcelle", _plPrcFerti.Dose != null && _plPrcFerti.Dose != undefined ? _plPrcFerti.Dose : 0),
                        this.InitLegendProperty("Date de l'apport", HandleService.getLOCALDate(_plPreco.BeginDate)),
                        this.InitLegendProperty("<br>N", this.GetPreconisationValue(_mcPrecDose, _plPrcFerti.N, _product_unit),_teneur_unit),
                    );
                    if(!_blPilotOrder) {
                        dictvaluesModulationFumure.push(  
                            
                            this.InitLegendProperty("P2O5", this.GetPreconisationValue(_mcPrecDose, _plPrcFerti.P2O5, _product_unit),_teneur_unit),
                            this.InitLegendProperty("K2O", this.GetPreconisationValue(_mcPrecDose, _plPrcFerti.K2O, _product_unit),_teneur_unit),
                            this.InitLegendProperty("MgO", this.GetPreconisationValue(_mcPrecDose, _plPrcFerti.MgO, _product_unit),_teneur_unit),
                            this.InitLegendProperty("SO3", this.GetPreconisationValue(_mcPrecDose, _plPrcFerti.SO3, _product_unit),_teneur_unit),
                            this.InitLegendProperty("CaO", this.GetPreconisationValue(_mcPrecDose, _plPrcFerti.CaO, _product_unit),_teneur_unit),
                            this.InitLegendProperty("Na2O", this.GetPreconisationValue(_mcPrecDose, _plPrcFerti.Na2O, _product_unit),_teneur_unit),
                        );
                    }
                    returnList.push({
                        legendGroupKey: legendPreco,
                        legendItemKey: precoProductLegendName,
                        legendProperies: dictvaluesModulationFumure
                    });

                    let legendItem = this.legend.find(a => a.key == legendPreco);
                    if (legendItem && legendItem.items && !legendItem.items.find(a => a.legendName == precoProductLegendName))
                        legendItem.items.push(this.formatLegendItem(legendPreco, precoProductLegendName, _plPrcFerti.Unit.length ? _plPrcFerti.Unit + '/ha' : '', _plPreco.Legend))

                    else if (legendItem) {// contrôle que le style est pas superieur
                        this.BindingLegendStyles(legendItem.items.find(a => a.legendName == precoProductLegendName), _plPreco.Legend, null, true, true);
                    }
                }
                this.OrderLegend(legendPreco, true);
                this.OrderLegend("Assolement", false);
            }
            else {//donnees de modulation
                let UnitNoArea = ['%', 'mm'];
                let _propMcValMashVol= LegendsConfig.legLibMashVolumeValue;
                let _propMashUnit='l/ha';
                let _propDose= LegendsConfig.legLibModulationPdtDose;
                for (let dxprcPlot = 0; dxprcPlot < cultivationPlot.Preconisations.length; dxprcPlot++) {
                    let _plotPrec = cultivationPlot.Preconisations[dxprcPlot];
                    //recherche selon IdGroupe
                    let _mcPrec = microPlot.Preconisations.find(t => t.IdGroupe == _plotPrec.IdGroupe)
                    //si il a pas de preco ou pas de dose à la plot ou la micro on remplit pas
                    if (!_mcPrec || this.IncludeFertiDose(_plotPrec,false) == false || this.IncludeMcFertiDose(_mcPrec,false)==false) {
                        continue;
                    }

                    let precoType = _plotPrec.InterventionType ? _plotPrec.InterventionType.Name : null;
                    let precoTypeCode = _plotPrec.InterventionType ? _plotPrec.InterventionType.Code : null;
                    let precoProductLegendName = _plotPrec.Fertilizer.Name + " - " + HandleService.getLOCALDate(_plotPrec.BeginDate);
                    
                    let PlotNameArea =this.FormatPlotWithAreaText(cultivationPlot)
                    let areaUnit = UnitNoArea.indexOf(_plotPrec.Fertilizer.Unit) == -1 ? '/ha' : '';
                    let blIncludBouille= Intervention.IsModulationBouillie(_plotPrec);
                    let _mcBouil=null;
                    let _mcdose = _mcPrec.Fertilizer.Dose;

                    let _Refdose=_plotPrec.RefDose != null && _plotPrec.RefDose != undefined ? _plotPrec.RefDose : 0;
                    let _plotdose=_plotPrec.Fertilizer.Dose != null && _plotPrec.Fertilizer.Dose != undefined ? _plotPrec.Fertilizer.Dose : 0;

                    let prdUnit=_plotPrec.Fertilizer.Unit.length ? _plotPrec.Fertilizer.Unit + areaUnit : '';
                    if(blIncludBouille)
                    {_mcBouil=0;

                        let _plvolBouil=_plotPrec.ModDatas.Value;

                        if(_mcdose>0 && _Refdose>0 && _plvolBouil)
                        {
                            _mcBouil =Math.round((_mcdose*_plvolBouil)/_Refdose)
                        }

                    }

                    let dictvaluesModulations = [];
                    dictvaluesModulations.push(
                        this.InitLegendProperty("Exploitation", farm.NameExploitation),
                        this.InitLegendProperty("Ilot", sector.PacNumber != null ? sector.PacNumber.toString() : ""),
                        this.InitLegendProperty("Parcelle", PlotNameArea),
                        this.InitLegendProperty("Culture", cultivationPlot.CultivationSelected ? cultivationPlot.CultivationSelected.Name : ""),
                        this.InitLegendProperty("Produit", _plotPrec.Fertilizer.Name),
                        this.InitLegendExistProperty(_propMcValMashVol,_mcBouil,_propMashUnit,blIncludBouille),
                        this.InitLegendExistProperty("Volume de bouillie de référence",blIncludBouille ? _plotPrec.ModDatas.Value : null,_propMashUnit,blIncludBouille),
                        this.InitLegendProperty(_propDose, _mcdose,prdUnit,true),
                        this.InitLegendProperty("Dose moyenne à la parcelle", _plotdose,prdUnit),
                        this.InitLegendProperty("Date de l'apport", HandleService.getLOCALDate(_plotPrec.BeginDate))
                    );
                    if( !isNullOrEmptyString(_mcPrec.Comm))
                    {
                        dictvaluesModulations.push(
                                this.InitLegendProperty("Commentaire", _mcPrec.Comm),
                        )
                    }
                    if( !blIncludBouille && _plotPrec.ModDatas && _plotPrec.ModDatas.Type=="PMG")
                    {
                        dictvaluesModulations.push(
                                this.InitLegendExistProperty("PMG de référence", _plotPrec.ModDatas.Value,"g")
                        )
                    }
                    dictvaluesModulations= this.ClearNullPropertie(dictvaluesModulations);
                    returnList.push({
                        legendGroupKey: this._legdModulations,
                        legendItemKey: precoProductLegendName,
                        legendProperies: dictvaluesModulations
                    });

                    let legendItem = this.legend.find(a => a.key == this._legdModulations);
                    if (legendItem && !legendItem.items.find(a => a.legendName == precoType)) {//groupe intervention
                        legendItem.items.push(this.formatLegendItem(this._legdModulations, precoType, null, null, true, precoTypeCode, _plotPrec.InterventionTypeGroup ? _plotPrec.InterventionTypeGroup.Name : null))
                    }
                    if (legendItem && legendItem.items.find(a => a.legendName == precoType)) {//les modulation
                        let legModType = legendItem.items.find(a => a.legendName == precoType);
                        let legModTypeProduit = isNullOrUndefined(legModType) == false ? legModType.items.find(a => a.legendName == precoProductLegendName) : null;
                        let _blExistLegdproduct= (isNullOrUndefined(legModTypeProduit) == false);// on dispose d'une legende produit existante
                        if (legModType && _blExistLegdproduct == false) {
                            //non trouvé selon le nom de produit
                            let formatUit = _plotPrec.Fertilizer.Unit.length ? _plotPrec.Fertilizer.Unit + areaUnit : _plotPrec.Fertilizer.Unit;
                            let _modLegend=this.formatLegendItem(this._legdModulations, precoProductLegendName, formatUit, _plotPrec.Legend, false, null);
                            if(blIncludBouille && _mcBouil>0)
                            {

                                _modLegend.externeProp= new LegendExtProperty(_propMcValMashVol,_mcBouil, _propMashUnit,_mcBouil);
                            }
                            legModType.items.push(_modLegend)
                        }
                        else if(legModType && blIncludBouille && _mcBouil>0)
                        {
                            let onr=legModType.items.find(a => a.legendName == precoProductLegendName);

                            if(onr && onr.externeProp && onr.externeProp.propertyValue)
                            {
                                //gestion max
                                if(Number(onr.externeProp.propertyValue)<_mcBouil) {
                                    onr.externeProp.propertyValue= _mcBouil;
                                }

                                 if(isNullOrUndefined(onr.externeProp.propertyMinValue) || Number(onr.externeProp.propertyMinValue)> _mcBouil){
                                    onr.externeProp.propertyMinValue= _mcBouil;
                                }

                            }
                        }
                        if (_blExistLegdproduct == true) {

                            //MAJ de legende max si superieur
                            this.BindingLegendStyles(legModType.items.find(a => a.legendName == precoProductLegendName), _plotPrec.Legend, null, true, true);
                        }
                    }
                    else if (legendItem) {// contrôle que le style est pas superieur

                        this.BindingLegendStyles(legendItem.items.find(a => a.legendName == precoType), _plotPrec.Legend, null, true, true);
                    }
                }

                this.OrderGroupLegend(this._legdModulations, true);
                this.OrderLegend("Assolement", false);
            }

        //gestion des legendes de diagnostic annuel
        if(_iblIsAnnual)
        {
            if(farm.PresAnnualRdt())
            {
                let dictvaluesRend = new Array<LegendProperty>();
                dictvaluesRend.push(
                    this.InitLegendProperty("Exploitation", farm.NameExploitation),
                    this.InitLegendProperty("Parcelle", this.FormatPlotWithAreaText(cultivationPlot)),
                    this.InitLegendProperty("Ilot", sector.PacNumber != null ? sector.PacNumber.toString() : ""),
                    this.InitLegendProperty("Identifiant microparcelle",  microPlot.MicroPlotDetails.IdBeApi),
                    this.InitLegendProperty("Rendement", (microPlot.MicroPlotDetails.Rendement != null ? microPlot.MicroPlotDetails.Rendement.toString() :""), "T/ha"),
                    this.InitLegendProperty(LegendsConfig.legLibAnnaRend_Indic, (microPlot.MicroPlotDetails.INDICE_APY != null ? microPlot.MicroPlotDetails.INDICE_APY.toString() : ""),"%"),
                    this.InitLegendExistProperty("Protéine",microPlot.MicroPlotDetails.PROTEINE,"%"),
                    this.InitLegendExistProperty("Amidon",microPlot.MicroPlotDetails.AMIDON,"%"),
                    this.InitLegendExistProperty("Humidité",microPlot.MicroPlotDetails.HUMIDITE,"%"),
                    this.InitLegendExistProperty("Rendement moyen",cultivationPlot.Rendement && cultivationPlot.Rendement>0? cultivationPlot.Rendement:null ,"T/ha"),
                    this.InitLegendExistProperty("Date récolte",microPlot.MicroPlotDetails.DT_RECOLT?HandleService.getStringLocalDate(microPlot.MicroPlotDetails.DT_RECOLT):null),
                    this.InitLegendExistProperty("Remarques",microPlot.MicroPlotDetails.Commentary),
                    )
                    dictvaluesRend = this.ClearNullPropertie(dictvaluesRend);


                    returnList.push({
                        legendGroupKey: LegendsConfig.legdAnnaRend,
                        legendItemKey: "",
                        legendProperies: dictvaluesRend
                    });

                    let legendRendItem = this.legend.find(a => a.key != null && a.key === LegendsConfig.legdAnnaRend);
                    if (legendRendItem != null && legendRendItem.items != null && farm.Legends != null && farm.Legends.length > 0) {
                        legendRendItem.items.forEach(item => {
                            if (item.name != null) {
                                if (item.name.toUpperCase() === LegendsConfig.legAnnaRend_Indic ) {
                                    this.BindingLegendsStyles(item, farm.Legends, LegendsConfig.legAnnaRend_Indic, LegendsConfig.legAnnaRend_Indic);
                                }

                                else if (item.name.toUpperCase() === LegendsConfig.legAnnaRend_Rendement) {
                                    this.BindingLegendsStyles(item, farm.Legends,LegendsConfig.legAnnaRend_Rendement, LegendsConfig.legLibAnnaRend_Rendement);
                                }

                            }
                        });

                    }
                    
            }
            else if(farm.PresAnnualAzote())
            {
                let dictvaluesAzote = new Array<LegendProperty>();
                dictvaluesAzote.push(
                    this.InitLegendProperty("Exploitation", farm.NameExploitation),
                    this.InitLegendProperty("Parcelle", this.FormatPlotWithAreaText(cultivationPlot)),
                    this.InitLegendProperty("Ilot", sector.PacNumber != null ? sector.PacNumber.toString() : ""),
                    this.InitLegendProperty("Identifiant microparcelle",  microPlot.MicroPlotDetails.IdBeApi),
                    this.InitLegendExistProperty(LegendsConfig.legLibAnnaAzoteNAbs, (microPlot.MicroPlotDetails.Nabs_value != null ? microPlot.MicroPlotDetails.Nabs_value.toString() : null),"kg/ha"),
                    this.InitLegendExistProperty("Date Azote absorbée",microPlot.MicroPlotDetails.DT_N_ABS?HandleService.getStringLocalDate(microPlot.MicroPlotDetails.DT_N_ABS):null),
                    this.InitLegendExistProperty(LegendsConfig.legLibAnnaAzoteEH, (microPlot.MicroPlotDetails.BiomassEH != null ? microPlot.MicroPlotDetails.BiomassEH.toString() : ""),"kg/m²"),
                    this.InitLegendExistProperty("Date Biomasse EH", microPlot.MicroPlotDetails.DT_BIOM_EH?HandleService.getStringLocalDate(microPlot.MicroPlotDetails.DT_BIOM_EH):null),
                    this.InitLegendExistProperty(LegendsConfig.legLibAnnaAzoteSH, (microPlot.MicroPlotDetails.BiomassSH != null ? microPlot.MicroPlotDetails.BiomassSH.toString() : ""),"kg/m²"),
                    this.InitLegendExistProperty("Date Biomasse SH",microPlot.MicroPlotDetails.DT_BIOM_SH?HandleService.getStringLocalDate(microPlot.MicroPlotDetails.DT_BIOM_SH):null),
                    this.InitLegendExistProperty("Indice de nutrition Azote", microPlot.MicroPlotDetails.INN),
                    this.InitLegendExistProperty("Remarques",microPlot.MicroPlotDetails.Commentary)
                    )
                    dictvaluesAzote = this.ClearNullPropertie(dictvaluesAzote);
                    returnList.push({
                        legendGroupKey: LegendsConfig.legdAnnaAzote,
                        legendItemKey: "",
                        legendProperies: dictvaluesAzote
                    });

                    let legendAztItem = this.legend.find(a => a.key != null && a.key === LegendsConfig.legdAnnaAzote);
                    if (legendAztItem != null && legendAztItem.items != null && farm.Legends != null && farm.Legends.length > 0) {
                        let legPropManaged:string[]=[LegendsConfig.legAnnaAzoteEH,LegendsConfig.legAnnaAzoteSH,LegendsConfig.legAnnaAzoteNAbs];
                        legendAztItem.items.forEach(item => {

                            if(legPropManaged.find(e=>e ==item.name )!=null)
                            {
                                this.BindingLegendsStyles(item, farm.Legends, item.name,item.name);
                            }
                        });
                    }
            } else if(farm.PresAnnualCarboN() && farm.Legends != null && farm.Legends.length > 0) {
                let dictvaluesCarboN = new Array<LegendProperty>();
                dictvaluesCarboN.push(
                    this.InitLegendProperty("Exploitation", farm.NameExploitation),
                    this.InitLegendProperty("Parcelle", this.FormatPlotWithAreaText(cultivationPlot)),
                    this.InitLegendProperty("Ilot", sector.PacNumber != null ? sector.PacNumber.toString() : ""),
                    this.InitLegendProperty("Identifiant microparcelle",  microPlot.MicroPlotDetails.IdBeApi),
                    this.InitLegendExistProperty(LegendsConfig.legLibAnnaCarboN_CarboN, (microPlot.MicroPlotDetails.DVegCip != null ? microPlot.MicroPlotDetails.DVegCip.toString() : null),"t/ha"),
                    this.InitLegendExistProperty("Date Mesure",microPlot.MicroPlotDetails.MeasuredDate?HandleService.getStringLocalDate(microPlot.MicroPlotDetails.MeasuredDate):null),
                    )
                    
                    dictvaluesCarboN = this.ClearNullPropertie(dictvaluesCarboN);
                    returnList.push({
                        legendGroupKey: LegendsConfig.legdAnnaCarboN,
                        legendItemKey: "",
                        legendProperies: dictvaluesCarboN
                    });

                    let legendCarboNItem = this.legend.find(a => a.key != null && a.key === LegendsConfig.legdAnnaCarboN);
                    
                    if (legendCarboNItem != null && legendCarboNItem.items != null) {

                        legendCarboNItem.items.forEach(item => {
                            if(item.name == LegendsConfig.legAnnaCarboN_Biom) {
                                this.BindingLegendsStyles(item, farm.Legends, LegendsConfig.legAnnaCarboN_Biom,LegendsConfig.legLibAnnaCarboN_CarboN);
                            }
                        });
                    }

            }
          
        }


        //gestion des legendes de diagnostic Pilot azote SP LAI
        if(_iblIsDiagLai == true) {

            let dictvaluesLAI = this.InitBasicProperties(farm,sector,cultivationPlot,microPlot,false,false,false)
            dictvaluesLAI.push(

                this.InitLegendProperty(LegendsConfig.legLibDiag_LAI, (microPlot.MicroPlotDetails.LAI != null ? microPlot.MicroPlotDetails.LAI : "")),
                this.InitLegendExistProperty("Date de mesure du LAI",microPlot.MicroPlotDetails.DT_LAI?HandleService.getStringLocalDate(microPlot.MicroPlotDetails.DT_LAI):null),
                this.InitLegendExistProperty("Date actualisée du LAI",microPlot.MicroPlotDetails.DT_ACT_LAI?HandleService.getStringLocalDate(microPlot.MicroPlotDetails.DT_ACT_LAI):null),
                this.InitLegendExistProperty("Fournisseur du LAI",microPlot.MicroPlotDetails.FOUR_LAI),
                
                )
                dictvaluesLAI = this.ClearNullPropertie(dictvaluesLAI);

                returnList.push({
                    legendGroupKey: LegendsConfig.legdPilotSpLAI,
                    legendItemKey: LegendsConfig.legDiag_SP_Lai,
                    legendProperies: dictvaluesLAI
                });

                let legendLAIItem = this.legend.find(a => a.key != null && a.key === LegendsConfig.legdPilotSpLAI);
                if (legendLAIItem != null && legendLAIItem.items != null && farm.Legends != null && farm.Legends.length > 0) {
                    legendLAIItem.items.forEach(item => {
                        if (item.name != null) {
                            if (item.name.toUpperCase() === LegendsConfig.legDiag_SP_Lai ) {
                                this.BindingLegendsStyles(item, farm.Legends, LegendsConfig.legDiag_SP_Lai, LegendsConfig.legDiag_SP_Lai);
                            }
                        }
                    });

                }
        }

        if(_blPilotOrder == true) {

            let dictvaluesAdviceLAI = this.InitBasicProperties(farm,sector,cultivationPlot,microPlot,false,false,false)
            dictvaluesAdviceLAI.push(
                this.InitLegendProperty(LegendsConfig.legLibOrd_SP_Lai_Advice_N, (microPlot.AdviceN != null ? microPlot.AdviceN : "")),
                this.InitLegendExistProperty(LegendsConfig.legLibDiag_LAI, (microPlot.MicroPlotDetails.LAI != null ? microPlot.MicroPlotDetails.LAI : "")),
                this.InitLegendExistProperty("Date de mesure du LAI",microPlot.MicroPlotDetails.DT_LAI?HandleService.getStringLocalDate(microPlot.MicroPlotDetails.DT_LAI):null),
                this.InitLegendExistProperty("Date actualisée du LAI",microPlot.MicroPlotDetails.DT_ACT_LAI?HandleService.getStringLocalDate(microPlot.MicroPlotDetails.DT_ACT_LAI):null),
                this.InitLegendExistProperty("Fournisseur du LAI",microPlot.MicroPlotDetails.FOUR_LAI),
       
                )
                dictvaluesAdviceLAI = this.ClearNullPropertie(dictvaluesAdviceLAI);

                returnList.push({
                    legendGroupKey: LegendsConfig.legdPilotSpLAI,
                    legendItemKey: LegendsConfig.legOrd_SP_Lai_Advice_N,
                    legendProperies: dictvaluesAdviceLAI
                });

                let legendLAIItem = this.legend.find(a => a.key != null && a.key === LegendsConfig.legdPilotSpLAI);
                if (legendLAIItem != null && legendLAIItem.items != null && farm.Legends != null && farm.Legends.length > 0) {
                    legendLAIItem.items.forEach(item => {
                        if (item.name != null) {
                            if (item.name.toUpperCase() === LegendsConfig.legOrd_SP_Lai_Advice_N ) {
                                this.BindingLegendsStyles(item, farm.Legends, LegendsConfig.legOrd_SP_Lai_Advice_N, LegendsConfig.legOrd_SP_Lai_Advice_N);
                            }
                        }
                    });

                }     
        }
        // gestion des interventions en mode préco exclusivement
        if (cultivationPlot && cultivationPlot.Interventions) {
          for (let i = 0; i < cultivationPlot.Interventions.length; i++) {
            let _plInter:Intervention= new Intervention(cultivationPlot.Interventions[i], true) ;
            if (_plInter.IsModePreco()) {
              if (!microPlot.Interventions || this.IsEmptyFertiDose(_plInter) || !_plInter.IncludeIdent() || _plInter.IsInterDeleted()) {
                continue;
              }
             
              //si il a pas de preco ou pas de dose à la plot ou la micro on remplit pas
              let _currMcInter:mcPreconisation=null;
              if ( !this.IncludeMcInterDoseByIdent(cultivationPlot, _plInter.Ident)) {//aucune micro de la plot ne dispose de dose >0
                  continue;
              }
              _currMcInter = microPlot.Interventions.find(t=> this.EqualByIdent(t,_plInter.Ident));
                if(!_currMcInter || this.IncludeMcFertiDose(_currMcInter, false) == false)
                {
                  continue;
                }
              let _plPrcFerti=_plInter.Fertilizer;
              let precoProductLegendName = _plPrcFerti.Name + " - " + HandleService.getLOCALDate(_plInter.BeginDate);


              let _mcPrecDose = _currMcInter.Fertilizer.Dose;
              let _teneur_unit = 'kg/ha';
              let _blInclude_Unit = isNullOrEmptyString(_plPrcFerti.Unit) == false;
              let _product_unit = _blInclude_Unit ? _plPrcFerti.Unit : '';
                
             
              let dictvaluesInters = this.FormatCommonPrecoLegend(farm, sector, cultivationPlot);
              dictvaluesInters.push(
                this.InitLegendProperty("Identifiant microparcelle", microPlot.MicroPlotDetails.IdBeApi),
                this.InitLegendProperty("Identifiant conseil", microPlot.Id.toString()),
                this.InitLegendProperty("Produit",_plPrcFerti.Name),
                this.InitLegendProperty("Dose", _mcPrecDose,_blInclude_Unit ? _product_unit + '/ha' : ''),
                this.InitLegendProperty("Dose moyenne à la parcelle", _plPrcFerti.Dose != null && _plPrcFerti.Dose != undefined ? _plPrcFerti.Dose : 0),
                this.InitLegendProperty("Date de l'apport", HandleService.getLOCALDate(_plInter.BeginDate)),
                this.InitLegendProperty("<br>N", this.GetPreconisationValue(_mcPrecDose, _plPrcFerti.N, _product_unit),_teneur_unit),
                this.InitLegendProperty("P2O5", this.GetPreconisationValue(_mcPrecDose, _plPrcFerti.P2O5, _product_unit),_teneur_unit),
                this.InitLegendProperty("K2O", this.GetPreconisationValue(_mcPrecDose, _plPrcFerti.K2O, _product_unit),_teneur_unit),
                this.InitLegendProperty("MgO", this.GetPreconisationValue(_mcPrecDose, _plPrcFerti.MgO, _product_unit),_teneur_unit),
                this.InitLegendProperty("SO3", this.GetPreconisationValue(_mcPrecDose, _plPrcFerti.SO3, _product_unit),_teneur_unit),
                this.InitLegendProperty("CaO", this.GetPreconisationValue(_mcPrecDose, _plPrcFerti.CaO, _product_unit),_teneur_unit),
                this.InitLegendProperty("Na2O", this.GetPreconisationValue(_mcPrecDose, _plPrcFerti.Na2O, _product_unit),_teneur_unit),
              );
              returnList.push({
                legendGroupKey: this._legdInterModePreco,
                legendItemKey: precoProductLegendName,
                legendProperies: dictvaluesInters
              });

              let legendItem = this.legend.find(a => a.key == this._legdInterModePreco);
              if (legendItem && legendItem.items && !legendItem.items.find(a => a.legendName == precoProductLegendName))
                legendItem.items.push(this.formatLegendItem(this._legdInterModePreco, precoProductLegendName, _blInclude_Unit ? _product_unit + '/ha' : '', _plInter.Legend))

              else if (legendItem) {// contrôle que le style est pas superieur
                this.BindingLegendStyles(legendItem.items.find(a => a.legendName == precoProductLegendName), _plInter.Legend, null, true);
              }
            }

          }
          this.OrderLegend(this._legdInterModePreco, false);
        }

        return returnList;
    }

    /**Initialise les légendes Conseil Azote */
    private BindLegendConsAzote() {
        let ObsAdviceUnit = "kg/ha";
        this.legend.push(
            {
                key: "Conseil azote",
                selected: false,
                items:
                    [
                        this.formatLegendItem("N", "N", ObsAdviceUnit),
                        this.formatLegendItem("SO3", "SO3", ObsAdviceUnit),
                    ]
            },

        )
    }

    private getMajorObsTypeWithValue(cultivationPlot: CultivationPlot, ReqObsName: string): string {
        let MajorType = null;
        let reqMicroWithObs = cultivationPlot.MicroPlots.filter(t => t.Observations != null && t.Observations.findIndex(i => i.Name == ReqObsName && i.Value != null) !== -1);
        if (reqMicroWithObs.length > 0) {
            let Observs = reqMicroWithObs.map(t => t.Observations.filter(i => i.Name == ReqObsName && i.Value != null));
            if (Observs && Observs.length > 0) {
                Observs.forEach(element => {
                    element.forEach(a => {
                        if (MajorType == null)
                            MajorType = a.Type;
                        else if (Number(MajorType) < Number(a.Type))
                            MajorType = a.Type;
                    });
                    if (MajorType != null) {
                        return MajorType;
                    }

                });
            }
        }
        return MajorType;
    }


    /**
     * chargement des données Conseil Azote ( Tooltip sur Micros et Legend Color)
     * Step 2-4
     * @param farm
     * @param sector
     * @param cultivationPlot
     * @param microPlot
     * @param plotMajorObsNType
     * @param plotMajorObsSo3Type
     */
    private LoadLegendConsAzote(farm: Farm, sector: Sector, cultivationPlot: CultivationPlot, microPlot: MicroPlot, plotMajorObsNType: string, plotMajorObsSo3Type: string): LegendProperties {

        let ObsAdviceUnit = "kg/ha";
        let legendAdviceN: LegendProperties = new LegendProperties;
        let dictAdviceAzote = new Array<LegendProperty>();
        let ObservN = this.getObservation(microPlot, this.ObsNameN, plotMajorObsNType, true);
        let ObservSo3 = this.getObservation(microPlot, this.ObsNameSo3, plotMajorObsSo3Type, true);
        let ObservDate = this.getObservation(microPlot, this.ObsNameDate, plotMajorObsNType, true);
        if (ObservDate == null) {
            ObservDate = this.getObservation(microPlot, this.ObsNameDate, plotMajorObsSo3Type, true);
        }
        dictAdviceAzote = this.InitBasicProperties(farm, sector, cultivationPlot, microPlot, false);
        dictAdviceAzote.push(
            this.InitLegendProperty("Date conseil",ObservDate != null? HandleService.getLOCALDate(ObservDate.Value):"",''),
            this.InitLegendProperty("Objectif de rendement",  microPlot.MicroPlotDetails.ObjectifRendement, "T/ha"),
            this.InitLegendProperty("N", ObservN != null ? ObservN.Value : "", ObsAdviceUnit),
            this.InitLegendProperty("SO3", ObservSo3 != null ? ObservSo3.Value : "", ObsAdviceUnit),
        );

        legendAdviceN.legendGroupKey = "Conseil azote",
            legendAdviceN.legendProperies = dictAdviceAzote;
        let legendItem = this.legend.find(a => a.key.toUpperCase() === "CONSEIL AZOTE");
        if (legendItem != null) {
            legendItem.items.forEach(item => {
                if (item.legendName === "N") {
                    let advice = ObservN;
                    this.BindingObsStyles(item, advice, true);
                }
                else if (item.legendName === "SO3") {
                    let advice = ObservSo3;
                    this.BindingObsStyles(item, advice, true);
                }
            })
        };
        return legendAdviceN;
    }


    /**
     * retourne l observation
     * @param microPlot
     * @param reqObsName Nom de propriété
     * @param reqObsType type filter ( 2 Update 1 Init) si pas de 2 alors retourne le 1
     * @param blForceData Indique que on ne veux QUE selon le type si celui-ci est recherché
     */
    private getObservation(microPlot: MicroPlot, reqObsName: string, reqObsType: string = null, blForceData: boolean = false) {
        let reqObservation = null;
        if (reqObsType) {
            reqObservation = microPlot.Observations.find(o => o.Name == reqObsName && o.Type == reqObsType);
            if (blForceData)
                return reqObservation;
            if (reqObservation && reqObservation.value != null)
                return reqObservation;
            else
                reqObservation = null;
        }
        if (reqObservation == null)
            reqObservation = this.FindObservation(microPlot.Observations, reqObservation);

        return reqObservation;

    }
    /**
     * Ordonne les legendes selon leur clés
     * @param _LegendeKey clés
     * @param blFumure filtre specifique fumure
     */
    private OrderLegend(_LegendeKey: string, blFumure: boolean) {
        /*Ordonne les données */
        let legendModFumure = this.legend.find(a => a.key == _LegendeKey);
        if (legendModFumure && legendModFumure.items != null && legendModFumure.items.length > 1) {
            if (!blFumure) {
                legendModFumure.items = legendModFumure.items.sort(function (a, b) {
                    return (isNullOrUndefined(a.legendName)) ? -1 : (isNullOrUndefined(b.legendName)) ? 1 : (a.legendName.toLowerCase() < b.legendName.toLowerCase()) ? -1 : (a.legendName.toLowerCase() > b.legendName.toLowerCase()) ? 1 : 0;
                });
            }
            else/** fumure filtre selon le nom et sa date */ {
                let _current = this;
                legendModFumure.items = legendModFumure.items.sort(function (a, b) {
                    let aName = isNullOrUndefined(a.legendName) ? "" : _current.getOrderFumureName(a.legendName);
                    let bName = isNullOrUndefined(b.legendName) ? "" : _current.getOrderFumureName(b.legendName);
                    return (aName < bName) ? -1 : (aName > bName) ? 1 : 0;
                });
            }
        }
    }

    /**
     *  Ordonne selon le group DONC propriete SortOn
     * @param _LegendeKey
     * @param _iblSubGrpProducts on ordonne les sous group produit-date
     */
    private OrderGroupLegend(_LegendeKey: string, _iblSubGrpProducts: boolean = false) {
        /*Ordonne les données */
        let legendProcess = this.legend.find(a => a.key == _LegendeKey);
        if (legendProcess && legendProcess.items != null && legendProcess.items.length > 0) {
            //ordonne groupe-type
            legendProcess.items = legendProcess.items.sort(function (a, b) {
                let aName = isNullOrUndefined(a.sortOn) ? "" : (a.sortOn) + '  ' + a.legendName;
                let bName = isNullOrUndefined(b.sortOn) ? "" : (b.sortOn) + '  ' + b.legendName;
                return (aName < bName) ? -1 : (aName > bName) ? 1 : 0;
            });
            if (_iblSubGrpProducts) {
                legendProcess.items.forEach((t: LegendItem) => {
                    if (t.items && t.items.length) {
                        let _current = this;
                        t.items = t.items.sort(function (a, b) {
                            let aName = isNullOrUndefined(a.legendName) ? "" : _current.getOrderFumureName(a.legendName);
                            let bName = isNullOrUndefined(b.legendName) ? "" : _current.getOrderFumureName(b.legendName);
                            return (aName < bName) ? -1 : (aName > bName) ? 1 : 0;
                        });
                    }
                }
                )
            }
        }
    }
    /**
        * retourne le nom de produit fumure permettant l ordonnancement
        * @param legendNameFumure
        */
    private getOrderFumureName(legendNameFumure: string): string {
        let oReturn = legendNameFumure;
        if (!isNullOrUndefined(legendNameFumure)) {
            oReturn = legendNameFumure.toLowerCase();
            if (legendNameFumure.lastIndexOf(' - ') > 0) {
                let _prodName = "";
                let NameSub = legendNameFumure.substr(0, legendNameFumure.lastIndexOf(' - '));
                if (NameSub != null)
                    _prodName = NameSub.toLowerCase();

                let lastItemDate: string = legendNameFumure.split(' - ').pop();
                if (lastItemDate != null) {
                    let dateformat = HandleService.stringToDate(lastItemDate);
                    if (dateformat) {
                        let isoDate = HandleService.getISODate(dateformat);
                        oReturn = _prodName + isoDate;
                    }
                }
            }
        }
        return oReturn;
    }

    /**
     * Constitution de la liste des legendes composant gauche
     * selon les données présentes
     * @param farms 
     * @param valueP205 
     */
    loadLegendStatic(farms: Farm[], valueP205: string): void {
        this.legend = [];
        let _blIncludePack: boolean = farms.findIndex(e => e.PresTypePack()) > -1;
        let _blIncludePotentiel: boolean = (_blIncludePack || farms.findIndex(e => e.PresTypePotentiel()) > -1);
        let _blIncludeFerti: boolean = (_blIncludePack || farms.findIndex(e => e.PresTypeFerti()) > -1);
        let _blConseil = _blIncludePack || _blIncludePotentiel || _blIncludeFerti;
        let _blIncludeMods: boolean = farms.findIndex(e => e.PresTypeModulation()) > -1;
        let _blIncludeAnnuals: boolean = farms.findIndex(e => e.IsAnnualOrder()) > -1;
        let _blIncludeModePreco: boolean = farms.findIndex(e => e.HasModePreco()) > -1;

        let _blIncludeDiagLai: boolean = farms.findIndex(e => e.IsDiagPilotSPLAI()) > -1;

        let _blincludePilotLAI : boolean = farms.findIndex(e => e.IsOrderPilotSPLAI()) > -1;
        let _blincludePilotPreco : boolean =  _blincludePilotLAI && farms.findIndex(e => e.HasPreco()) > -1;
        

       
        //LEGENDES Specifiques Ferti
        if (_blIncludeFerti) {

            let _legDiagFerti : LegendItem[]= [
                this.formatLegendItem("pH", "pH", ''),
                            this.formatLegendItem("CaO", "Teneur CaO", 'ppm'),
                            this.formatLegendItem("K2O", "Teneur K2O", 'ppm'),
                            this.formatLegendItem("MgO", "Teneur MgO", 'ppm'),
                            this.formatLegendItem("K2O/MgO", "Teneur K2O/MgO", '')
            ];

            if (farms.findIndex(t=>t.IncludeLegendName(LegendsConfig.legFertiCEC))> -1) {
                _legDiagFerti.push(this.formatLegendItem(LegendsConfig.legFertiCEC, "CEC", 'meq/100g'))
            }

            if (farms.findIndex(t=>t.IncludeLegendName(LegendsConfig.legFertiMATORG))> -1) {
                _legDiagFerti.push(this.formatLegendItem(LegendsConfig.legFertiMATORG, "Matière organique", '%'))
            }

            this.legend.push(
                {
                    key: "Diagnostic fertilité",
                    selected: false,
                    items: _legDiagFerti,  
                }
            );

           
        }
        //LEGENDES Specifiques Potentiel
        if (_blIncludePotentiel) {

            let leg_potentials : LegendItem[]= [
                this.formatLegendItem("Potentiel", "Potentiel", ''),
                this.formatLegendItem("RU", "Réserve utile totale", 'mm'),
                this.formatLegendItem("HCC", "Humidité capacité au champ", '%')
            ];
            if( farms.findIndex(t=>t.IncludeLegendName(LegendsConfig.legPotentielAPM))> -1) {
                leg_potentials.push(this.formatLegendItem("APM", "APM", ''))
            }

            
            this.legend.push(
                {
                    key: "Diagnostic potentiel",
                    selected: false,
                    items: leg_potentials,
                       
                },

            )

        }

        if(_blIncludeAnnuals)
        {
           if(farms.findIndex(e => e.PresAnnualRdt()) > -1)
           {
            this.legend.push(
                {
                    key: LegendsConfig.legdAnnaRend,
                    selected: false,
                    items:
                        [
                            this.formatLegendItem(LegendsConfig.legAnnaRend_Indic, LegendsConfig.legLibAnnaRend_Indic, '%'),
                            this.formatLegendItem(LegendsConfig.legAnnaRend_Rendement, LegendsConfig.legLibAnnaRend_Rendement, 'T/ha'),
                        ]
                })
           };

           if(farms.findIndex(e => e.PresAnnualCarboN()) > -1)
           {
            this.legend.push(
                {
                    key: LegendsConfig.legdAnnaCarboN,
                    selected: false,
                    items:
                        [
                            this.formatLegendItem(LegendsConfig.legAnnaCarboN_Biom, LegendsConfig.legLibAnnaCarboN_CarboN, 'T/ha'),
                        ]
                })
           };

           if(farms.findIndex(e => e.PresAnnualAzote()) > -1)
           {
            this.legend.push(
                {
                    key: LegendsConfig.legdAnnaAzote,
                    selected: false,
                    items:
                        [
                            this.formatLegendItem( LegendsConfig.legAnnaAzoteEH, LegendsConfig.legLibAnnaAzoteEH , 'kg/m²'),
                            this.formatLegendItem(LegendsConfig.legAnnaAzoteSH, LegendsConfig.legLibAnnaAzoteSH, 'kg/m²'),
                            this.formatLegendItem(LegendsConfig.legAnnaAzoteNAbs, LegendsConfig.legLibAnnaAzoteNAbs, 'kg/ha'),
                        ]
                })
           };
        }

        if(_blIncludeDiagLai == true || _blincludePilotLAI == true) {
            //constitution des legendes groupées sur(Pilotage Azote SP) diag et order conseil N
           let _pilotItems : LegendItem [] =[];
           if(_blIncludeDiagLai) {
            _pilotItems.push(this.formatLegendItem(LegendsConfig.legDiag_SP_Lai, LegendsConfig.legLibDiag_LAI, ''));
           }
           if(_blincludePilotLAI) {
            _pilotItems.push(this.formatLegendItem(LegendsConfig.legOrd_SP_Lai_Advice_N, LegendsConfig.legLibOrd_SP_Lai_Advice_N, ''));
           }
           this.legend.push(
            {
                key: LegendsConfig.legdPilotSpLAI,
                selected: false,
                items:_pilotItems
            })

        }
        
        //LEGENDES Specifiques Ferti
        if (_blIncludeFerti)
            this.legend.push(
                {
                    key: "Conseil fumure",
                    selected: false,
                    items:
                        [
                            this.formatLegendItem("Conseil fumure", "P2O5", 'kg/ha'),
                            this.formatLegendItem("Conseil fumure", "K2O", 'kg/ha'),
                            this.formatLegendItem("Conseil fumure", "MgO", 'kg/ha'),
                            this.formatLegendItem("Conseil fumure", "CaO", 'kg/ha')
                        ]
                })
        //LEGENDES Specifiques Potentiel
        if (_blIncludePotentiel)
            this.BindLegendConsAzote();

        if (_blIncludeModePreco) {
          this.legend.push({
            key: this._legdInterModePreco,
            selected: false,
            items: []
          });
        }

        //LEGENDES COMMUNES
        if (_blConseil)
            this.legend.push(
                {
                    key: this._legdPrecoFumure,
                    selected: false,
                    items:
                        []
                });
        if (_blIncludeMods)
            this.legend.push(
                {
                    key: this._legdModulations,
                    selected: false,
                    items:
                        []
                });

        if(_blincludePilotPreco) 
            this.legend.push(
                {
                    key: this._legdPilotPreco,
                    selected: false,
                    items:
                        []
                });
                

        if (_blConseil)
            this.legend.push(
                {
                    key: "Assolement",
                    selected: false,
                    items:
                        [
                            this.formatLegendItem("CultivationPlots", farms[0].IdCampagne.toString(), ''),
                            this.formatLegendItem("CultivationPlots", (farms[0].IdCampagne - 1).toString(), ''),
                            this.formatLegendItem("Sector", "Ilots", '')
                        ]
                }
            ,)



        if (_blIncludeFerti && valueP205) {
            if (valueP205 == "P2O5_OL") {
                this.legend.find(function (element) {
                    return element.key == "Diagnostic fertilité";
                }).items.splice(2, 0,
                    this.formatLegendItem("P2O5 Olsen", "Teneur P2O5 (Olsen)", 'ppm')
                );
            }
            else if (valueP205 == "PO5_JH") {

                this.legend.find(function (element) {
                    return element.key == "Diagnostic fertilité";
                }).items.splice(2, 0,
                    this.formatLegendItem("P2O5 Joret Hebert", "Teneur P2O5 (Joret Hebert)", 'ppm')
                );
            }
            else if (valueP205 == "P2O5_DY") {
                this.legend.find(function (element) {
                    return element.key == "Diagnostic fertilité";
                }).items.splice(2, 0,
                    this.formatLegendItem("P2O5 Dyer", "Teneur P2O5 (DYER)", 'ppm')
                );

            }
        }
    }


     /**
     * retourne le calcul selon l unité de la dose par la teneur
     * @param dose dose non vide
     * @param TeneurCultivationPlot valeur de la teneur
     * @param cultivationUnit unité de sortie
     */
    private GetPreconisationValue(dose: number, TeneurCultivationPlot: number, cultivationUnit: string): number {
        if (this.EmptyNumber(TeneurCultivationPlot) || dose == 0 || TeneurCultivationPlot == 0)
            return 0;

        if (!isNullOrEmptyString(cultivationUnit)) {//convertion en Kg si nécessaire
            let _ProdUnit = cultivationUnit.trim().toUpperCase();
            if (_ProdUnit == 'T' || _ProdUnit == 'M3') {
                dose = dose * 1000;
            }
            else if (_ProdUnit == 'Q') {
                dose = dose * 100;
            }
        }
        return Math.round(dose * TeneurCultivationPlot / 100) * 100 / 100;

    }

    private EmptyNumber(initValue: number): boolean {
        return initValue == null || initValue === undefined;
    }

    /**
     * initialise les legends ( tooltip) de base spécifique a l exploitation parcelle ilot
     * @param farm exploitation
     * @param sector ilot
     * @param cultivationPlot parcelle
     * @param microPlot micro (nullable)
     * @param blIncluDateAdCreate  indique si on sort cultivationPlot.AdviceCreationDate
     * @param blIncludeCulture  indique si on sort cultivationPlot.Culture
     * @param include_mc_id_advice indique si on sort l id Micro Parcelle comme Identifiant conseil
     * @returns 
     */
    private InitBasicProperties(farm: Farm, sector: Sector, cultivationPlot: CultivationPlot, microPlot: MicroPlot, blIncluDateAdCreate: boolean, blIncludeCulture:boolean=true, include_mc_id_advice:boolean = true): Array<LegendProperty> {
        let _properties = new Array<LegendProperty>();
        _properties = this.InitBasicPlotProperties(farm, sector, cultivationPlot, blIncluDateAdCreate, blIncludeCulture);
        if (microPlot != null)
            _properties = _properties.concat(this.InitBasicMicroPlotProperties(microPlot, include_mc_id_advice));
        return _properties;
    }
   
    /**
     * initialise les legends ( tooltip) specifique a la parcelle et exploitation
     * @param farm 
     * @param sector 
     * @param cultivationPlot 
     * @param blIncluDateAdCreate on sort cultivationPlot.AdviceCreationDate
     * @param blIncludeCulture  on sort la culture
     * @returns 
     */
    private InitBasicPlotProperties(farm: Farm, sector: Sector, cultivationPlot: CultivationPlot, blIncluDateAdCreate: boolean, blIncludeCulture:boolean=true): Array<LegendProperty> {
        let dictFieldDatas = new Array<LegendProperty>();
        dictFieldDatas.push(
            this.InitLegendProperty("Exploitation", farm.NameExploitation),
            this.InitLegendProperty("Parcelle",this.FormatPlotWithAreaText(cultivationPlot)),
            this.InitLegendProperty("Ilot", sector.PacNumber != null ? sector.PacNumber.toString() : "")
            
            );
        if (blIncluDateAdCreate) {
            dictFieldDatas.splice(3, 0,
                this.InitLegendProperty("Date", HandleService.getLOCALDate(cultivationPlot.AdviceCreationDate)))        }

                if(blIncludeCulture) {
                    dictFieldDatas.push(
                        this.InitLegendProperty("Culture", cultivationPlot.CultivationSelected ? cultivationPlot.CultivationSelected.Name : "")  )  
                }
        return dictFieldDatas;
    }

    /**
     * initialise les legends ( tooltip) specifique à la micro
     * @param microPlot 
     * @param include_id_advice indique que l on sort l identifiant conseil
     * @returns 
     */
    private InitBasicMicroPlotProperties(microPlot: MicroPlot, include_id_advice:boolean = true): Array<LegendProperty> {
        let dictPlotldDatas = new Array<LegendProperty>();
        dictPlotldDatas.push(
            this.InitLegendProperty("Identifiant microparcelle",  microPlot.MicroPlotDetails.IdBeApi),
        );
        if(include_id_advice == true) {
            dictPlotldDatas.push( 
                this.InitLegendProperty("Identifiant conseil",  microPlot.Id.toString())
            );
        }
        return dictPlotldDatas;
    }

    private InitObsLegendProperty(newPropName: string, blIncludeObs: boolean, observat: Observation[], obsNameReq: string, newPorpUnit: string = 'kg/ha', onEmptyValue: string = ""): LegendProperty {
        let reqObs = blIncludeObs ? this.FindObservation(observat, obsNameReq) : null;
        return this.InitLegendProperty(newPropName, reqObs != null ? reqObs.Value : onEmptyValue, newPorpUnit, true)
    }

    private InitLegendProperty(newPropName: string, newPropValue: any, newPorpUnit: string='', blShowInTooltip: boolean = true, _iSfxValue:string=null): LegendProperty {
        let oNewProp: LegendProperty = { propertyName: newPropName, propertyValue: newPropValue, unit: newPorpUnit, isShownInTooltip: blShowInTooltip, sfxValue:_iSfxValue }
        return oNewProp;
    }
    /**
     * Initialise UNIQUEMENT si la valeur Existe
     * @param newPropName
     * @param newPropValue
     * @param newPorpUnit
     * @param blShowInTooltip
     * @param _iSfxValue
     */
    private InitLegendExistProperty(newPropName: string, newPropValue: any, newPorpUnit: string='', blShowInTooltip: boolean = true, _iSfxValue:string=null): LegendProperty {
        if(!isNullOrUndefined(newPropValue) )
        {
            let oNewProp: LegendProperty = { propertyName: newPropName, propertyValue: newPropValue, unit: newPorpUnit, isShownInTooltip: blShowInTooltip, sfxValue:_iSfxValue }
            return oNewProp;
        }
        return null;

    }
    private ClearNullPropertie(_ilistDatas:Array<LegendProperty>):Array<LegendProperty>
    {
        return _ilistDatas.filter(t=>t!=null);
    }
    ////////////////////// Gestion des Legendes styles (colors) left component

    /**
     * Ajout de la legende diagnostic
     * @param oLegendItem 
     * @param farmLegends legendes de l'order
     * @param ReqLegendName Nom de la legende (simbologies.champ)
     * @param NewStyleName   Nouveau nom pour la légende
     */
    private BindingLegendsStyles(oLegendItem: LegendItem, farmLegends: Legend[], ReqLegendName: string, NewStyleName: string) {

        ReqLegendName = ReqLegendName.toUpperCase();
        let farmLegend: Legend = farmLegends.find(t => t.Name != null && t.Name.toUpperCase() == ReqLegendName && this.IncludeRanges(t));
        this.BindingLegendStyles(oLegendItem, farmLegend, NewStyleName);
    }

    /**
     * Met à jour la légende si un nouveau max est trouvé (ou min si blCheckMin === true)
     *
     * @param oLegendItem - Légende courante
     * @param legend - Légende de la farm
     * @param NewStyleName - Nouveau nom pour la légende
     * @param blSetLegendName - true pour utiliser le nom par défaut provenant de la farm
     * @param blCheckMin - true pour vérifier également les valeurs min en plus des valeurs max (true en intervention, préconisation, modulation)
    */
    private BindingLegendStyles(oLegendItem: LegendItem, legend: Legend, NewStyleName: string, blSetLegendName: boolean = false, blCheckMin: boolean = false) {
        if (blCheckMin) {
            const hasNewMinMax: boolean = this.IsLegendUpdateNecessary(oLegendItem.style, legend);
            if (!hasNewMinMax) {
                return;
            }
        } else {
            if (!this.OverWriteOverMaxStyle(oLegendItem.style, legend)) {
                return;
            }
        }

        // un nouveau min ou max est trouvé, on actualise le style de légende
        oLegendItem.style = new Legend;
        oLegendItem.style.Class = legend.Class;
        if (NewStyleName)
            oLegendItem.style.Name = NewStyleName;
        else if (blSetLegendName && legend.Name)
            oLegendItem.style.Name = legend.Name;
    }

    /**
     * Contrôle si la nouvelle légend peut ecraser la basic car son max est >
     * @param basicLegend
     * @param newLegend
     */
    private OverWriteOverMaxStyle(basicLegend: Legend, newLegend: Legend): boolean {
        if (this.IncludeRanges(newLegend)) {
            if (this.IncludeRanges(basicLegend)) {/*contrôle la valeur max la plus haute */
                return (basicLegend.Class[basicLegend.Class.length - 1].MaxValue < newLegend.Class[newLegend.Class.length - 1].MaxValue);
            }
            return true;
        }

        return false;
    }

    /**
     * Contrôle si la nouvelle légende possède un minimum plus petit ou un maximum plus grand que la légende actuelle.
     *
     * @param basicLegend La légende actuelle
     * @param newLegend La nouvelle légende dont il faut tester les valeurs min et max
     * @return true si une actualisation de légende est nécessaire
     */
    private IsLegendUpdateNecessary(basicLegend: Legend, newLegend: Legend): boolean {
        if (this.IncludeRanges(newLegend) && this.IncludeRanges(basicLegend)) {
            // on pointe sur le 2ème élément de la légende si plus de 1 élément
            let indexBasicLegend = basicLegend.Class.length > 1 ? 1 : 0;
            let indexNewLegend = newLegend.Class.length > 1 ? 1 : 0;

            const baseMin = basicLegend.Class[indexBasicLegend].MinValue;
            const baseMax = basicLegend.Class[basicLegend.Class.length - 1].MaxValue;
            const newMin = newLegend.Class[indexNewLegend].MinValue;
            const newMax = newLegend.Class[newLegend.Class.length - 1].MaxValue;

            return newMin < baseMin || newMax > baseMax;
        }

        return false;
    }


    /**
        * Contrôle la présence de rang de données
        * class de couleur
        */
    public IncludeRanges(basicLegend: Legend): boolean {
        return basicLegend && basicLegend.Class && basicLegend.Class.length > 0;
    }

    /**
     * constitution des Styles AU MAX de la legend [oLegendItem] selon les observations de la micro
     * @param oLegendItem legend
     * @param advice Observation
     * @param blSetLegNameOnStyle indique que l on attribue le Legendname au Style.Name
     */
    private BindingObsStyles(oLegendItem: LegendItem, advice: Observation, blSetLegNameOnStyle: boolean = true): boolean {
        if (advice && advice.Style != null && advice.Style.length > 0) {
            oLegendItem.style = this.GetLegendMaxValue(oLegendItem.style, advice.Style);
            if (blSetLegNameOnStyle && oLegendItem.style) {
                oLegendItem.style.Name = oLegendItem.legendName;
                return true;
            }
        }
        return false;
    }
    /**
     * constitution des Styles AU MAX venant d 'observation
     * @param oLegendItem legend à completer
     * @param obsName Nom de l'observation recherchée
     * @param microPlot micro contenant des observations
     * @param blSetLegNameOnStyle attribution du LegendItem.LegendName au style.name
     */
    private BindingReqObsStyles(oLegendItem: LegendItem, obsName: string, microPlot: MicroPlot, blSetLegNameOnStyle: boolean = true): boolean {
        let firstAdvice = microPlot.Observations.find(o => o.Name == obsName && o.Style != null && o.Style.length > 0);
        return this.BindingObsStyles(oLegendItem, firstAdvice, blSetLegNameOnStyle);
    }



    /**
  * Convertion d'une collection de style en legend
  * SI jsonStyle.MaxValue est > à la current.maxvalue
  */
    private GetLegendMaxValue(currentLegend: Legend, jsonStyle: JsonStyle[]) {

        if (this.IncludeRanges(currentLegend)) { // contrôle si la legend courrant est pas superieur de par sa valeur max
            if (currentLegend.Class[currentLegend.Class.length - 1].MaxValue >= jsonStyle[jsonStyle.length - 1].range) {
                return currentLegend;
            }
        }
        return this.GetLegendFromJsonStyle(jsonStyle);
    }

    /**
     * Convertion d'une collection de style en legend
     */
    private GetLegendFromJsonStyle(jsonStyle: JsonStyle[]): Legend {
        let legendToBeReturned = new Legend();
        let minValueOfLegend = 0;
        jsonStyle.forEach(element => {
            let legendColor = new LegendColor();
            legendColor.MinValue = minValueOfLegend;
            legendColor.MaxValue = element.range;
            minValueOfLegend = element.range;
            legendColor.Color = element.color;

            legendToBeReturned.Class.push(legendColor)
        });
        return legendToBeReturned;
    }
    private FindObservation(observations: Observation[], obsNameReq: string): Observation {
        return observations.find(e => e.Name == obsNameReq);
    }

   /**
    * Indique si il dispose pour la preco selon son ident
    * d'une dose à la micro
    * @param CultivationPlot
    * @param _iIdentPreco
    * @returns
    */
    private IncludeMcPrecoDoseByIdent(CultivationPlot: CultivationPlot, _iIdentPreco: number): boolean {
        if (CultivationPlot.MicroPlots && CultivationPlot.MicroPlots.length)
        {
            return CultivationPlot.MicroPlots.findIndex(mc => mc.Preconisations  && this.IncludeMcFertiDose(mc.Preconisations.find(t=>this.EqualByIdent(t,_iIdentPreco)), true)) > -1;
        }
        return false;
    }


   /**
    * Indique si il dispose pour l'inter selon son ident
    * d'une dose à la micro
    * @param CultivationPlot
    * @param _iIdentInter
    * @returns
    */
    private IncludeMcInterDoseByIdent(CultivationPlot: CultivationPlot, _identInter: number): boolean {
        if (CultivationPlot.MicroPlots && CultivationPlot.MicroPlots.length)
        {
            return CultivationPlot.MicroPlots.findIndex(mc => mc.Interventions  && this.IncludeMcFertiDose(mc.Interventions.find(t=>this.EqualByIdent(t, _identInter)), true)) > -1;
        }
        return false;
    }

    private EqualByIdent( _imcPreco : mcPreconisation, _iIdent :number ) :boolean
    {
        return _imcPreco.Ident && _imcPreco.Ident !=0 && _imcPreco.Ident ==_iIdent;
    }
    /**
    * Contrôle que la preco existe avec un ferti et une dose à celui ci
    * @param Preconisation
    * @param blCheckOver0Value Contrôle la présence d une dose Exclusivement superieur à 0 , si NON pas de contrôle de la valeur
    */
    private IncludeFertiDose(Preconisation: Intervention, blCheckOver0Value: boolean): boolean {
        return ( this.IsEmptyFertiDose(Preconisation) == false && (blCheckOver0Value == false || Number(Preconisation.Fertilizer.Dose) > 0));
    }

       /**
    * Contrôle que la micro preco|modulation existe avec un ferti et une dose à celui ci
    * @param mcPreconisation preconisation ou modulation à la micro
    * @param blCheckOver0Value Contrôle la présence d une dose Exclusivement superieur à 0 , si NON pas de contrôle de la valeur
    */
    private IncludeMcFertiDose(mcPreconisation: mcPreconisation, blCheckOver0Value: boolean): boolean {
        return ( this.IsEmptymcFertiDose(mcPreconisation) == false && (blCheckOver0Value == false || Number(mcPreconisation.Fertilizer.Dose) > 0));
    }

     /**
     * Indique si c est une preco|modulation sans dose de produit
     * @param mcPreconisation plot preco|modulation
     * @returns
     */
    private IsEmptyFertiDose(plPreconisation: Intervention) {
        return (!plPreconisation || !plPreconisation.Fertilizer || (plPreconisation.Fertilizer.Dose === null || plPreconisation.Fertilizer.Dose === undefined));
    }
    /**
     * Indique si c est une preco dans dose de produit
     * @param mcPreconisation micro preco|modulation
     * @returns
     */
    private IsEmptymcFertiDose(mcPreconisation: mcPreconisation) {
        return (!mcPreconisation || !mcPreconisation.Fertilizer || (mcPreconisation.Fertilizer.Dose === null || mcPreconisation.Fertilizer.Dose === undefined));
    }
 
    /**Retourne les Type Modulation pour PDF Impression */
    public getPDFModulationTypes(): any[] {
        let oReturn: any[] = [];
        
        if (this.legend && this.legend.length) {
            let legModulation = this.legend.find(t => t.key == this._legdModulations);
            if (legModulation && legModulation.items && legModulation.items.length) {
                legModulation.items.forEach(t => oReturn.push({ code: t.legendCode, value: t.legendName?t.legendName:" " }));
            }
            let legModePreco = this.legend.filter(t => (t.key == LegendsConfig.legInterModePreco )&& t.items && t.items.length );
            if(legModePreco && legModePreco.length) {
                let _blModepreco = legModePreco.find(t => (t.key == LegendsConfig.legInterModePreco))!=null;
                if (_blModepreco) {
                  oReturn.splice(0,0,{ code: LegendsConfig.legInterModePrecoCode, value: LegendsConfig.legInterModePrecoName})
                }
            }

            let legPilotPreco = this.legend.filter(t => (t.key == LegendsConfig.legPilotSpPreco )&& t.items && t.items.length );
            if(legPilotPreco && legPilotPreco.length) {
                let _blModepreco = legPilotPreco.find(t => (t.key == LegendsConfig.legPilotSpPreco))!=null;
                if (_blModepreco) {
                  oReturn.splice(0,0,{ code: LegendsConfig.legPilotPrecoCode, value: LegendsConfig.legPilotPrecoName})
                }
            }
        }
        return oReturn;
    }

    /**Retourne les impressions supplémentaires arrivant à la fin de la liste (synthèses) */
    public getPDFExtraPrintTypes(): PrintPdfExtraModel[] {
        let oReturn: PrintPdfExtraModel[] = [];

        if (this.legend && this.legend.length) {
            let legModulation = this.legend.find(t => (t.key == LegendsConfig.legPilotSpPreco || t.key == this._legdPrecoFumure) && t.items && t.items.length >0 );
            if (legModulation) {
                oReturn.push(PrintManager.FormatPdfTypeSynthesisFert())
            }
            let legModePreco = this.legend.find(t => (t.key == LegendsConfig.legdModulations )&& t.items && t.items.length >0);
            if(legModePreco ) {
                oReturn.push(PrintManager.FormatPdfTypeSynthesisOtherInputs())
            }
        }
        return oReturn;
    }

    /**Retourne la liste des PDf de Diagnostics (Annuel - Pilot LAI) Si dispose de données */
    public GetPDFDiagnosticTypes():any[] {
       
        let oReturn = this.getPDFAnnualsTypes();
        let oDiagLai = this.getPDFDiagLAITypes();
        if(oDiagLai) {
            oReturn.push(oDiagLai);
        }
        return oReturn;
    }

    /**Retourne si il dispose de legende concernant le pilotage SP Diagnostic conseil ou Preconisation */
    public IncludePilotageSP() {
        if (this.legend && this.legend.length) {
            return this.legend.filter(t => (t.key == LegendsConfig.legPilotSpPreco || t.key== LegendsConfig.legdPilotSpLAI )&& t.items && t.items.length ).length>0;
        }
        return false;
    }

    /**Retourne les PrestationType Annuals pour PDF Impression */
    private getPDFAnnualsTypes(): any[] {
        let oReturn: any[] = [];
        if (this.legend && this.legend.length) {
            let legAnnuals = this.legend.filter(t => (t.key == LegendsConfig.legdAnnaAzote || t.key== LegendsConfig.legdAnnaRend || LegendsConfig.legdAnnaCarboN)&& t.items && t.items.length );
            if (legAnnuals.length) {
                let _blRendement=legAnnuals.find(t => (t.key == LegendsConfig.legdAnnaRend))!=null;
                let _blAzote=legAnnuals.find(t => (t.key == LegendsConfig.legdAnnaAzote))!=null;
                let _blCarboN=legAnnuals.find(t => (t.key == LegendsConfig.legdAnnaCarboN))!=null;
                if(_blRendement)
                {
                    oReturn.push({ code: farmConfig.propPrest_AnnRend, value: "rendement" })
                }
                if(_blAzote)
                {
                    oReturn.push({ code: farmConfig.propPrest_AnnAzote, value: "azote SP" })
                }
                if(_blCarboN)
                {
                    oReturn.push({ code: farmConfig.propPrest_AnnCarboN, value: "CarboN" })
                }
            }
        }
        return oReturn;
    }

    /**
     * Retourne la prestation diagnostic pilot LAI pour PDF Impression
     * @returns 
     */
    private getPDFDiagLAITypes(): any {
        let oReturn = null;
        if (this.legend && this.legend.length) {
            let legDiagLAI = this.legend.filter(t => (t.key == LegendsConfig.legdPilotSpLAI) && t.items && t.items.length );
            if (legDiagLAI.length) {
                oReturn ={ code: farmConfig.propPrest_Pilot_Azote_SP_LAI, value: "Azote SP (LAI)" };
            }
        }
        return oReturn;
    }

    /**
     *
     * @param _iName nom propriete
     * @param _iLegendName nom checkbox dans menu des legendes
     * @param _iUnit
     * @param _iStyle
     * @param _iblHasSubMenu
     * @param _iLegendCode
     * @param _ISortProp
     */
    private formatLegendItem(_iName: string, _iLegendName: string, _iUnit: string, _iStyle: Legend = undefined, _iblHasSubMenu: boolean = false, _iLegendCode: string = null, _ISortProp: string = null): LegendItem {
        return {
            name: _iName,
            legendName: _iLegendName,
            unit: _iUnit,
            style: _iStyle,
            hasSubMenu: _iblHasSubMenu,
            legendCode: _iLegendCode,
            sortOn: _ISortProp,
            items: [],
            externeProp:null,
            Basicstyle:null,
        }
    }
    /**Suppression des legendes n etant pas Celle _iPropLegendKey */
    private ClearOnEmptyLegend(_iPropLegendKey:string)
    {
        let legendModulat = this.legend.find(a => a.key == _iPropLegendKey);
        if(legendModulat)
        {
            if(!legendModulat.items || legendModulat.items.length==0)
            this.legend= this.legend.filter(t=>t.key !==_iPropLegendKey);
        }
    }

    /**
     * Suppression des legende sans style
     * @param _iPropLegendKey
     */
    private ClearEmptyStyleLegend(_iPropLegendKey:string)
    {
        let _leg = this.legend.find(a => a.key == _iPropLegendKey);
        if(_leg && _leg.items && _leg.items.length>0)
        {
            _leg.items = _leg.items.filter(r=>r.style!=null && r.style.Class!=null && r.style.Class.length>0)
        }
    }

    /**
     *
     * @param farm Exploitation
     * @param sector Parcelles
     * @param cultivationPlot Parcelle
     * @returns array
     */
    private FormatCommonPrecoLegend(_iFarm : Farm, _iSector: Sector, _iCultivationPlot: CultivationPlot)
    {
        
      let legend = [];
      legend.push(
          this.InitLegendProperty("Exploitation", _iFarm.NameExploitation),
          this.InitLegendProperty("Parcelle",this.FormatPlotWithAreaText(_iCultivationPlot)),
          this.InitLegendProperty("Ilot", _iSector.PacNumber != null ? _iSector.PacNumber.toString() : ""),
          this.InitLegendProperty("Date", HandleService.getLOCALDate(_iCultivationPlot.AdviceCreationDate)),
          this.InitLegendProperty("Culture", _iCultivationPlot.CultivationSelected ? _iCultivationPlot.CultivationSelected.Name : ""),
      );
      return legend;
    }

   /**
    * Retourne les microFeatures du group spécifié
    * @param _iLegendGroupKey spécifique à ce group de legende
    * @param _iLegendItemKey si != null filtre sur la clés de la légende
    * @returns 
    */
    public GetMicroFeatureGroup(_iLegendGroupKey:string, _iLegendItemKey:string=null) {
       return this.microFeatures.filter(a => {
            let properties = a.getProperties().properties as LegendProperties[];
            let selectedProperty = properties.find(p => {
                return (p.legendGroupKey == _iLegendGroupKey && (_iLegendItemKey == null || p.legendItemKey ==_iLegendItemKey ));
            });
            if (selectedProperty != null)
                return a;
        });
    }

    /**Retourne selon le titre de legende si on est sur un group de preconisation ( fumur-modulation-pilotage preco - interMode preco) */
    public static IsPreconisationLegend(_iLegendTitle) {
        let precos :string[] = [LegendsConfig.legdPrecoFumur,LegendsConfig.legdModulations,LegendsConfig.legPilotSpPreco,LegendsConfig.legInterModePreco];
        return precos.includes(_iLegendTitle);
    }

    /**format un string contenant le nom de la parcelle et sa surface sur 2 deicmals max */
    private FormatPlotWithAreaText(_iPlot:CultivationPlot) {
        return `${_iPlot.NameCultivationPlot} (${HandleService.RoundDecimalsView(_iPlot.Surface,2)} ha)`
    }
}

/**Comprend les proprietes de configuration des legendes */
export class LegendsConfig {
    /**Titre groupe de la legende des modulations  "Autres préconisations" */
    public static legdModulations = "Autres modulations";
    /** Titre groupe de la legende des preconisations de fumure  "Modulation fumure" */
    public static legdPrecoFumur = "Modulation fumure";
    /** Titre groupe de la legende données annuels Rendement */
    public static legdAnnaRend = "Rendement (précédent)";

        /** Titre groupe de la legende données annuels CarboN */
        public static legdAnnaCarboN = "CarboN";

    /** Titre groupe de la legende des Preconisation en pilotage azote SP LAI */
    public static legPilotSpPreco ="Modulation Pilotage Azote SP";

     /** Titre groupe de la legende des interventions organiques de type “préconisation” */
     public static legInterModePreco = "Apports organiques";

     /** Titre du groupe de la legende Pilotage Azote SP LAI */
     public static legdPilotSpLAI = "Pilotage Azote SP";
     /** Titre groupe de la legende données annuels Azote */
     public static legdAnnaAzote = "Diagnostic Azote SP";

      /** Titre groupe de la legende données diagnostic fertilité */
     public static legDiagFerti = "Diagnostic fertilité";
/** Titre groupe de la legende données diagnostic potentiel */
     public static legDiagPot = "Diagnostic potentiel";


     public static pdfAnnaAzote = "Azote SP";
     public static pdfAnnaRend = "Rendement";
     public static pdfAnnaCarboN = "CarboN";
     public static pdfDiagAzoteLAI = "Azote SP (LAI)";


    /**Code propriete Rendement 'INDICE_APY' */
     public static legAnnaRend_Indic="INDICE_APY";
     /**Code propriete Rendement 'RENDEMENT' */
     public static legAnnaRend_Rendement="RENDEMENT";

       /**Code propriete Carbo N 'BIOM_CI' de la legende (symbologie) */ 
     public static legAnnaCarboN_Biom="BIOM_CI";

     /**Code propriete Diagnostic Azote SP LAI  : 'LAI'*/
     public static legDiag_SP_Lai="LAI";

     /**Code propriete order Azote SP LAI conseil N calculé : 'AdviceN' */
     public static legOrd_SP_Lai_Advice_N="ADVICEN";

      /**Code propriete annuel azote 'Biomasse EH' */
     public static legAnnaAzoteEH ="BIOM_EH";
      /**Code propriete annuel azote 'Biomasse SH' */
     public static legAnnaAzoteSH="BIOM_SH";
      /**Code propriete annuel azote 'Biomasse SH' */
     public static legAnnaAzoteNAbs="N_ABS";


       /**Code de la légende optionelle diagnostic potentiel APM */
    public static legPotentielAPM ="APM";

    /**Code de la légende optionelle  diagnostic Ferti CEC */
    public static legFertiCEC ="CEC";

     /**Code de la légende optionelle diagnostic Ferti MO */
     public static legFertiMATORG ="MAT_ORG";
     /**Libellé affiché pour legende et propriété MO */
     public static legLibFertiMATORG = "Matière organique";

    /**Libelle propriete Azote 'Indice APY' */
     public static legLibAnnaRend_Indic="Indice APY";
     public static legLibAnnaRend_Rendement="Rendement";
 
     public static legLibAnnaCarboN_CarboN="Developpement CIPAN";

     /**Libelle propriete azote Sp LAI 'LAI' affiché ds tooltip et legende gauche*/
     public static legLibDiag_LAI="LAI";

      /**Libelle propriete azote Sp LAI 'Conseil N' affiché ds tooltip et legende gauche*/
     public static legLibOrd_SP_Lai_Advice_N="Conseil N";

       /**Libelle propriete Azote 'Biomasse EH' */
     public static legLibAnnaAzoteEH ="Biomasse EH";
       /**Libelle propriete Azote 'Biomasse SH' */
     public static legLibAnnaAzoteSH="Biomasse SH";
     public static legLibAnnaAzoteNAbs="Azote absorbée";
    /**Libellé de la propriete volume de bouillié à la micro */
     public static legLibMashVolumeValue="Volume de bouillie";
     public static legLibModulationPdtDose="Dose de produit";

   
    /**texte pour impression preconisation intervention mode preco */
    public static legInterModePrecoName = "fumure organique";
    /**code pour impression preconisation intervention mode preco */
    public static legInterModePrecoCode = "INTER_MODE_PRECO";

    /**texte pour impression preconisation azote SP */
    public static legPilotPrecoName = "Pilotage Azote SP";
    /**Code pour impression preconisation azote SP */
    public static legPilotPrecoCode = "PILOT_SP_PRECO";

}

