export class Cultivation {

    Id: number;
    Name: string;
    Color: string;

    constructor(id: number, name: string,  color: string) {
        // subscribe to alert messages
        this.Id = id;
        this.Name = name;
        this.Color = color;
    }

}