/**
 * Parametres additionnels attendus par le compte Smag
 */
export class AdditionalValues {
   application_id: string;
   displaySignUp: string;
   displayLogin: string;
   displayInfo: string;
   // login legacy qui doit etre encrypté
   id_login_legacy: string = '';

   constructor(application_id: string, displaySignup: string, displayLogin: string, id_login_legacy?:string) {
      this.application_id = application_id;
      this.displaySignUp = displaySignup;
      this.displayLogin = displayLogin;
      this.displayInfo = "0";
      if (id_login_legacy && id_login_legacy != '') {
         this.id_login_legacy = id_login_legacy;
      }
   }
}

export class BuildUrlBody {
   AdditionalValues: AdditionalValues;

   constructor(application_id: string, displaySignup: string, displayLogin: string, id_login_legacy?:string) {
      this.AdditionalValues = new AdditionalValues(application_id, displaySignup, displayLogin, id_login_legacy);
   }
}
