<nb-auth-block container="body">
    <div class="titleimg"></div>
    <div id="block_login">
        <div id="smag_login" container="body" (click)="launchSmagAccountConnection()">
          <a href="#" id="smag">
            <img src="/assets/logo_smag_account.svg" alt="" style="max-width:30px;">
            <span>
              {{  getText('ButtonSmagLoginText', true) }}
            </span>
          </a>
        </div>
    </div>
</nb-auth-block>
