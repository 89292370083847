/**
 *  Gestion de la popup cartographique
 */

import { Component, OnChanges, ViewChild, ElementRef, Input, OnDestroy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { SharedMapService, HandleService } from './../../../_services/index';
import { LegendGroup, LegendProperties } from '../../../_models/index';


declare var ol: any;

@Component({
  selector: 'popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss', './popup.component.responsive.scss']
})

export class AppSharedMapPopupComponent implements OnChanges, OnDestroy, AfterViewInit {

  @ViewChild('popup') popup: ElementRef;
  public popupContent: string;

  @Input() map: any;

  @Input() selectedLegenditem: LegendGroup;

  private timer: any;

  private handlerMouseOut: any;
  private handlerMouseOn: any;

  private selectedLayer: any;
  private mapTargetEl: any;

  constructor(private mapService: SharedMapService, private cdr: ChangeDetectorRef) { }

  ngOnDestroy() {
    this.mapTargetEl.removeEventListener('mouseout', this.handlerMouseOut);
    this.cdr.detach();
  }

  ngOnChanges(changes: any) {
    if (this.selectedLegenditem && this.selectedLegenditem.items && changes.selectedLegenditem && changes.selectedLegenditem.currentValue) {

      if (this.selectedLegenditem.key === "Assolement") {
        if (this.selectedLegenditem.items[0].name === "Sector")
          this.selectedLayer = this.mapService.ilotLayer;
        else if (this.selectedLegenditem.items[0].name === "CultivationPlots")
          this.selectedLayer = this.mapService.plotLayer;
        else
          this.selectedLayer = this.mapService.microLayer;
      } else
        this.selectedLayer = this.mapService.microLayer;

    }
    if (changes.map) {

      // Lors du premier changement
      if (changes.map.currentValue && !changes.map.previousValue) {

        // Initialisatoin du syseme de popup
        this.initPopup(this.popup);
      }
    }
  }

  ngAfterViewInit(): void {
    this.initPopup(this.popup)
  }

  /**
   * Initialisatoin du sytème de popup
   * @param container
   * @param content
   */

  public showPopup(evt: any) {

    if (evt.dragging || (evt.frameState && evt.frameState.animate)) {
      clearInterval(this.timer);
      this.popupContent = '';
      this.mapService.overlay.setPosition(undefined);
      return;
    }


    if (!HandleService.isMobile() && (evt.type == 'contextmenu' || evt.originalEvent.target.localName === 'canvas') || HandleService.isMobile()) {
      let pixel: any;
      if(evt.type == 'contextmenu') {
        pixel = [evt.layerX, evt.layerY];
      } else {
        pixel = evt.pixel;
      }
      this.timer = setTimeout(() => {

        // Recherche de la feature de la couche micro parcelle survolé par la sourie
        let feature = this.map.forEachFeatureAtPixel(pixel, function (feature: any, layer: any) {
          return feature;
        }, this, (layerFilter: any) => {
          if (this.selectedLayer)
            return layerFilter.get('code') == this.selectedLayer.get('code');
          else
            return layerFilter.get('code') == this.mapService.microLayer.get('code');
        });


        if (feature) {
          if(!this.selectedLegenditem || !this.selectedLegenditem.key || !this.selectedLegenditem.items)
            return;
          // Récupération des propriétés flaguées "popup"
          let properties = feature.getProperties().properties as LegendProperties[];
          let selectedProperty = properties.find(p => {
            return (p.legendGroupKey === this.selectedLegenditem.key && p.legendItemKey === this.selectedLegenditem.items[0].legendName);
          });
          if (!selectedProperty)
            selectedProperty = properties.find(p => {
              return (p.legendGroupKey === this.selectedLegenditem.key);
            });
          if (!selectedProperty) {
            return;
          }
          const shownProperty = selectedProperty.legendProperies.filter(property => property.isShownInTooltip === true);


          // Construction du contenu HTML
          let html = '<ul>';
          shownProperty.forEach(property => {
            html += '<li><b>' + property.propertyName + '</b> : ';
            if (property.propertyValue == null || property.propertyValue === '') {
              html += 'Non défini(e)';
            } else {
              html += property.propertyValue;
              if(property.unit != null && property.propertyValue && !property.propertyValue.toString().includes(property.unit))
                html += ' ' + property.unit;
              if(property.sfxValue != null)
                html += ' ' + property.sfxValue;
            }
            html += ' ';
          });

          html += '</ul>';

          // Mise à jour du contenu HTML
          this.popupContent = html;

          this.cdr.detectChanges();
          // Mise à jour de la position

          if(evt.type == 'contextmenu')
            this.mapService.overlay.setPosition(this.map.getCoordinateFromPixel([evt.layerX, evt.layerY]));

          else
            this.mapService.overlay.setPosition(evt.coordinate);

          if(!HandleService.isMobile()) {
            this.constraintToMap(evt);
          }

          if(HandleService.isMobile()) {
            document.getElementById('closePopup').addEventListener('click', (e) => {
              e.preventDefault();
              this.popupContent = '';
              this.mapService.overlay.setPosition(undefined);
            });
          }

        } else {
          clearInterval(this.timer);
          // On cache la popup si aucune feature n'est survolé
          this.popupContent = '';
          this.mapService.overlay.setPosition(undefined);
        }
      });
    } else {
      clearInterval(this.timer);
      // On cache la popup si aucune feature n'est survolé
      this.popupContent = '';
      this.mapService.overlay.setPosition(undefined);
    }
  }

  initPopup(container: ElementRef) {
    // Ajout de la couche popup à la carto
    if (!this.popup) {
      return;
    }
    this.mapService.overlay = new ol.Overlay({
      element: this.popup.nativeElement
    });
    this.map.addOverlay(this.mapService.overlay);

    this.map.on('pointerdown', (evt: any) => {
      clearInterval(this.timer);
    });

    const detectEvent = HandleService.isMobile() ? 'singleclick': 'pointermove';

    // A chaque déplacement de la sourie sur la carto
    this.map.on(detectEvent, (evt: any) => {
      this.showPopup(evt);
    });

    this.handlerMouseOut = (e: any) => {
      this.popupContent = '';
      this.mapService.overlay.setPosition(undefined);
    }
    this.handlerMouseOn = (evt: any) => {
      this.showPopup(evt);
    }


    // On cache la popup lorsque la sourie quite le survol de la carto
    this.mapTargetEl = this.map.getTargetElement();
    if(!HandleService.isMobile()) this.mapTargetEl.addEventListener('mouseout', this.handlerMouseOut);
    this.map.getTargetElement().addEventListener('contextmenu', this.handlerMouseOn);

  }

  /**
   * Ajuste le positionnement de la popup de façon à ce qu'elle soit toujours visible
   * @param evt
   */
  constraintToMap(evt: any) {

    let popupEl = this.popup.nativeElement;
    let mapEL = this.mapTargetEl;
    let position = evt.pixel;
    let coordinate = evt.coordinate;

    // Positionnement au centre de la popup
    popupEl.style.left = -(popupEl.clientWidth / 2) + 'px';

    // Dépassement en haut ?
    const popupTop = position[1] - 15 - popupEl.clientHeight;
    if (popupTop < 0) {
      popupEl.classList.remove('ol-popup');
      popupEl.classList.add('ol-popup-flip-bottom');
    } else {
      popupEl.classList.add('ol-popup');
      popupEl.classList.remove('ol-popup-flip-bottom');
    }

    // Dépassement à gauche ?
    const popupLeft = position[0] - popupEl.clientWidth / 2;
    if (popupLeft < 0) {
      coordinate = this.map.getCoordinateFromPixel([popupEl.offsetLeft * -1, position[1]])
    } else {

      // Dépassement à droite ?
      const popupRight = position[0] + popupEl.clientWidth / 2;
      if (popupRight > mapEL.clientWidth) {
        coordinate = this.map.getCoordinateFromPixel([mapEL.clientWidth + popupEl.offsetLeft, position[1]])
      }
    }

    // Mise à jour de la position
    this.mapService.overlay.setPosition(coordinate);
  }
}
