import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService, LoaderState } from './loading.service';

@Component({
  selector: 'loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  private subscription: Subscription;
  public show = false;
  public msg: string = '';

  /**
   * Constructeur
   */
  constructor(private loadingService: LoadingService) { }

  ngOnInit() {
    this.subscription = this.loadingService.loaderState
      .subscribe((state: LoaderState) => {
        this.show = state.show;
        this.msg = state.msg;
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
