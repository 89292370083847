import { SmagUser } from "..";


export class User {
    UserId: string;
    Login: string;
    Firstname: string;
    Lastname: string;
    EMail: string;
    Address: string;
    City: string;
    Domain: string;
    Role: string;

    /**
     *
     */
    constructor() {
       this.Firstname = "";
       this.Lastname = "";
       this.Role = "";
    }
    /**
     * Contrôle selon son Rôle si c est un agent beapi
     */
    public IsAgentBeApi():boolean
    {
        return this.hasRole(eUserRole.AGENTBEAPI);
    }



    /**
     * Contrôle la concordance du rôle
     */
    public hasRole(userRole: number):boolean
    {
        if(this.Role!= null && this.Role!= undefined)
        {
            return (this.Role.toUpperCase() == eUserRole[userRole])
        }
    }



    /**
     * retourne le role à afficher au client
     */
    UserViewRole():string
    {
        if(this.IsAgentBeApi())
        {
            return 'Agent be Api';
        }
        return this.Role;
    }

}

export class SmagTokens {
    smag_token:string;
    smag_refresh_token:string;
}


export class Login extends SmagTokens {
    username: string;
    userid: string;
    token: string;
    legacytoken: string;
    companyid:string;
    refresh_token:string;
    stay_connected:boolean;
}

/**
 * Liste des différents roles des utilisateurs BEAPI
 */
export enum eUserRole {
    DEFAULT = 1,
    EXPLOITANT = 2,
    AGRICULTEUR = 3,
    INITIATEUR = 4,
    TECHNICIEN = 5,
    AGENTBEAPI = 6,
}
