<kendo-window title="" *ngIf="opened" (close)="close()" [minWidth]="250" [width]="520">
  <kendo-window-titlebar>
    <div class="k-window-title">Export des fichiers de modulation</div>
    <button class="k-button k-bare k-button-icon" kendoWindowMinimizeAction></button>
    <button class="k-button k-bare k-button-icon" kendoWindowMaximizeAction></button>
    <button class="k-button k-bare k-button-icon" kendoWindowRestoreAction></button>
    <button class="k-button k-bare k-button-icon" kendoWindowCloseAction></button>
  </kendo-window-titlebar>
  <span class="boldLbl">Modulation :</span>

  <div *ngIf="CheckModeMulti" class="dvCheckModeMulti">
    <label class="k-form-field right" *ngIf="HiddenChkPrecosFerti == false">
      <input type="checkbox" id="ChkPrecosFerti" class="k-checkbox" [(ngModel)]="ChkPrecosFerti"
        (change)="cbxFiltrePrecos()" />
      <label class="k-checkbox-label k-checkbox-label-pad" for="ChkPrecosFerti">Fumure</label>
    </label>
    <label class="k-form-field right" *ngIf="HiddenChkInterModePreco == false">
      <input type="checkbox" id="ChkInterModePreco" class="k-checkbox" [(ngModel)]="ChkInterModePreco"
        (change)="cbxFiltrePrecos()" />
      <label class="k-checkbox-label k-checkbox-label-pad" for="ChkInterModePreco">Apports organiques</label>
    </label>
    <label class="k-form-field right" *ngIf="HiddenChkPilotSpPreco == false">
      <input type="checkbox" id="ChkPilotSpPreco" class="k-checkbox" [(ngModel)]="ChkPilotSpPreco"
        (change)="cbxFiltrePrecos()" />
      <label class="k-checkbox-label k-checkbox-label-pad" for="ChkPilotSpPreco">Pilotage Azote SP</label>
    </label>
    <label class="k-form-field right" *ngIf="HiddenChkOtherPrecos == false">
      <input type="checkbox" id="ChkOtherPrecos" class="k-checkbox" [(ngModel)]="ChkOtherPrecos"
        (change)="cbxFiltrePrecos()" />
      <label class="k-checkbox-label k-checkbox-label-pad" for="ChkOtherPrecos">Autres</label>
    </label>
  </div>

  <kendo-grid [data]="gridPrecos" [height]="250" [selectable]="selectableSettings" [class]="'gridPrecos'">
    <kendo-grid-column field="IsChecked" title="" width="30">

      <ng-template kendoGridCellTemplate let-dataItem let-columnIndex="columnIndex" let-rowIndex="rowIndex">
        <input type="checkbox" (click)="changeClick(rowIndex)" />

      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="Name" title="Modulations">
    </kendo-grid-column>

    <kendo-grid-column field="ConvertUnits" title="Unités"  width="160">
      <ng-template kendoGridHeaderTemplate   >
        <span title={{ColumnUnitTltp}}>{{ColumnUnit}}</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex" let-column="column" >

          <div width="110px">
            <kendo-dropdownlist *ngIf="dataItem['ConvertUnits'] && dataItem['ConvertUnits'].Values && dataItem['ConvertUnits'].Values.length>0"
            name="'ConvertUnits'"
            [data]="dataItem['ConvertUnits'].Values"
            [textField]="'text'"
            [valueField]="'key'"
            [valuePrimitive]="true"
            [(ngModel)]="dataItem['ConvertUnits'].Selected_key"
            [style.width] = "'110px'"

        >
    </kendo-dropdownlist>

          </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-messages noRecords="Aucune modulation disponible">
    </kendo-grid-messages>
  </kendo-grid>


  <span class="boldLbl">Format :</span>
  <div class="format">
    <div class="left-div">
      <div style="margin:5px;">
        <input type="radio" name="format" id="culture" class="k-radio" value="console" [(ngModel)]="selectedFormat"
          (change)="handleConsoleRadioCheckChanged($event)">
        <label class="k-radio-label" for="culture">Choisir la console : </label>
      </div>
      <div style="margin:5px;">
        <input type="radio" name="format" id="parcelle" class="k-radio" value="shape" [(ngModel)]="selectedFormat"
          (change)="handleConsoleRadioCheckChanged($event)">
        <label class="k-radio-label" for="parcelle">Shapefile</label>
      </div>
    </div>
    <div class="right-div">
      <kendo-combobox class="right-combo-Console" [disabled]="!isConsole" [data]="listConsole"
        [allowCustom]="allowCustom" [textField]="'shownName'" [valueField]="'id'" [(ngModel)]="selectedConsole">
      </kendo-combobox>
    </div>
  </div>
  <span class="boldLbl">Options :</span>
  <div class="choice">
    <div class="left-div">
      <div style="margin:5px;">
        <input type="checkbox" class="k-checkbox" id="CbxMergePrecos" [checked]="blCbxMergePrecos"
          (click)="cbxMergePrecosClick()">
        <label class="k-checkbox-label" (click)="cbxMergePrecosClick()"
          title="{{CbxMergePlotPrecoTltp}}">{{CbxMergePlotPrecoTxt}}</label>
      </div>
      <div style="margin:5px; padding-left:15px">
        <input type="checkbox" class="k-checkbox" id="CbxMergeDiffCultivations" [disabled] ="blCbxMergePrecos == false" [checked]="blCbxMergeDiffCultivation"
          (click)="CbxMergeDiffCultivationsClick()">
        <label class="k-checkbox-label" (click)="CbxMergeDiffCultivationsClick()"
          title="{{CbxMergeDiffCultTltp}}">{{CbxMergeDiffCultTxt}}</label>
      </div>

    </div>
  </div>
  <div class="dvSlider">

    <label class="lblSlider" title="{{SlideTltp}}" style="padding-right: 1px;">{{SlideTitle}}</label>
    <kendo-slider class="export-slider" [incrementTitle]="'Augmenter (1 mètre)'" [decrementTitle]="'Diminuer (1 mètre)'"
      [dragHandleTitle]="SlideBufferValue" [min]="0" [max]="10" [smallStep]="1"
      [(ngModel)]="SlideBufferValue"></kendo-slider>
  </div>
  <div class="text-right">
    <button type="button" class="k-button" (click)="close()">Annuler</button>
    <button type="button" class="k-button k-primary" (click)="submit()">Exporter</button>
  </div>
</kendo-window>
