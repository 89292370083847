import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ping',
  templateUrl: './ping.component.html',
  styleUrls: ['./ping.component.scss']
})
export class PingComponent implements OnInit {


  start: number = 0;
  end: number = 0;
  difference: number = 0;

  constructor() {
    this.start = new Date().getTime();
  }

  ngOnInit() {
    this.end = new Date().getTime();
    //Temps écoulé en secondes
    this.difference = (this.end - this.start) / 1000 ;
  }



}
