/**
 * Remplacement des attibuts par du texte
 */

import { Injectable } from '@angular/core';

@Injectable()
export class AttributToTextService {

    public text: any = {
        // Groupes
        "culture": "Culture",
        "interculture": "Interculture",
        "iohiver": "Entrée/sortie hiver",
        "sol": "Sol",
        "precedent": "Précédent",
        "fumure": "Fumures",
        "irrigation": "Irrigation",
        "pk": "Gestion PK",
        "prairie": "Cas prairie",
        "apports": "Apports",

        // Colonnes
        "sector": "Ilot",
        "parcelle": "Parcelle",
        "id_beapi": "Micro parcelle",
        "micro_parcelle": "Nom",
        "statut": "Statut",
        "type_sol": "Profil pédologique",
        "taux_cailloux_sol": "Tx. cailloux sol",
        "taux_cailloux_sous_sol": "Tx. cailloux ssol",
        "culture_precedent": "Culture préc.",
        "rendement": "Rendement",
        "residus_precedent": "Résidus préc",
        "irrigation_precedent": "Irrigation",
        "espece_precedente": "Variété Précédente",
        "teneur_no3": "Teneur NO3",
        "volume_previ": "Volume irrig. prévi.",
        "blocage": "Blocage PK",
        "annee_impasse_p": "Années impasse P",
        "annee_impasse_k": "Années impasse K",
        "nb_apports_organiques": "Nb apports org.",
        "nb_apports_minenaux": "Nb apports min.",
        "annee_retournement": "Année retournement",
        "mois_retournement": "Mois retournement",
        "date_destruction": "Date de destruction",
        "mode_destruction": "Mode destruction",
        "dev_vegetatif_cipan": "Dev. végétatif CIPAN",
        "bles_ameliorants": "Caractérisation de l'espèce",
        "date_defanage": "Date de défanage",
        "objectif_11_5_proteine": "Obj. 11.5 protéine",
        "comifer": "COMIFER ", 
        "pourcentage_legumineuses": "% légumineuses",
        "stategie_conseil": "Stratégie conseil",
        "biomasse_eh": "Biomasse EH ",
        "biomasse_sh": "Biomasse SH ",
        "reliquat_sh": "Reliquat SH ",
        "cumul_pluvio": "Cumul pluvio.",
        "periode_pluvio": "Période pluvio.",
        "objectif_rendement": "Obj. rendement",
        "objectif_rendement_theorique": "Obj. rendement théorique",
        "espece": "Espèce",
        "residus": "Résidus",
        "is_previous_catch_crop": "Dérobée",
        "is_catch_crop": "Dérobée",
        "strategie": "Strategie",
        "adP2O5": "P2O5",
        "adK2O": "K2O",
        "adCaO": "CaO",
        "adMgO": "MgO",
        "adB": "B",
        "adCu": "Cu",
        "adFe": "Fe",
        "adMn": "Mn",
        "adZn": "Zn",
        "adN": "N",
        "adSO3": "SO3",
        "advice": "Synthèse du conseil",
        "solde": "Ecart au conseil",
        "preconisation": "Préconisations",
        "conseil": "Conseil",


        // Legende
        "P2O5": "P2O5",
        "K2O": "K2O",
        "MGO": "MgO",
        "CAO": "CaO",
        "K2O_MGO": "K2O/MgO",
        "PH": "pH",
        "POTENTIEL": "Potentiel",
        "PRECEDENT": "Précédent",
        "CULTUTRE": "Culture",
        "ILOTS": "Ilots",
        "BIOMASSE": "Biomasse",
        "INN": "INN",
        "RENDEMENT": "Rendement",
        "INDICE_APY": "Indice APY ",
        "TYP_SURFAC": "Type de surface",
        "N_TOT_RE": "Dose d’azote épandu ",
        "P2O5_RE": "Dose de phosphore épandu",
        "K2O_RE": "Dose de potasse épandue",
        "NH4_RE": "Dose de NH4 épandu",
        "INTERCULTURE": "Interculture",

        // Groupe legende/popup
        "fertilite": "Cartes fertilité",
        "potentiel": "Carte potentiel",
        "assolement": "Assolement",
        "biomasse": "Biomasse",
        //"rendement": "Rendement ",
        "surface": "Surface",
        "epandage": "Epandage",

        // Popup
        "PH_EAU": "pH",
        "P2O5_OL": "P2O5 Olsen",
        "P2O5_JH": "P2O5 Joret Hébert",
        "P2O5_DY": "P2O5 Dyer",
        "CEC": "CEC",
        "MAT_ORG": "Matière organique",
        "APM": "APM",
        "PROFI_PEDO": "Profil pédologique",
        "RU": "Réserve utile"
    };

    constructor() { }

    public replace(attribut: string) {
        return (this.text[attribut] !== undefined) ? this.text[attribut] : attribut;
    }
}