import {LegendColor} from './legendcolor';
import { Legend } from '..';
export class LegendGroup {
    key: string;
    items: LegendItem[];
    selected:boolean;

    constructor(key: string, items: any) {
        this.key = key;
        this.items = items;
    }
}

export class LegendItem {
    name: string;
    style: Legend;
    legendName: string;
    legendCode: string=null;
    //Ordonne sur cette propriete
    sortOn:string=null;
    unit: string;
    hasSubMenu:boolean;
    items:LegendItem[];
    //propriete externe pour gestion autre propriete
    externeProp:LegendExtProperty=null;
     //Style de donnée de base
    Basicstyle: Legend=null;
}

export class LegendProperties {
    legendGroupKey: string;
    legendItemKey: string;
    legendProperies: LegendProperty[];
}

export class LegendProperty {
    propertyName: string;
    propertyValue: string;
    unit:string;
    isShownInTooltip: boolean;
    sfxValue:string;
}
/**reprend donnees pour reconstituer une legende externe */
export class LegendExtProperty {
    propertyName: string;
    //valeur maximal
    propertyValue: number;
      //valeur minimale
    propertyMinValue: number;
    unit:string;
    /**
     * 
     * @param _iPropName 
     * @param _iPropMaxValue Maximum de la propriete ( pour calcul de la legende rang max value)
     * @param _iPropUnit 
     * @param _iPropMinValue minimal de la propriete ( pour calcul de la legende rang min value)
     */
    constructor( _iPropName:string,_iPropMaxValue:number,_iPropUnit:string, _iPropMinValue:number=null) {
        this.propertyName = _iPropName;
        this.propertyValue = _iPropMaxValue;
        this.unit = _iPropUnit;
        this.propertyMinValue = _iPropMinValue;
    }
}