import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Login } from '../_models/index';
import {Observable, of} from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpRequestService } from '../_services/index';
import {map} from 'rxjs/operators';


@Injectable()
export class AuthenticationService {
    private baseUrl = environment.BeApiOperational.url;
    private jwtHelper: JwtHelperService = new JwtHelperService();
    private currentUser: Login;

    constructor(private httpRequestService: HttpRequestService) { }


    public login(username: string, password: string) {
        return this.httpRequestService.PostRequest(this.baseUrl + 'api/authenticate', { username: username, password: password }, 'login')
            .subscribe((response: Response) => {
                 // login successful if there's a jwt token in the response
                return this.saveLoginUserInfo(response, username, password);
            });
    }

    /// return true if token expired;
    public isTokenExpired(): boolean {
      const currentuser = JSON.parse(localStorage.getItem('currentUser')) as Login;
      const token = currentuser.token;
      if (this.jwtHelper.isTokenExpired(token)) {
        this.logout();
      }
      return this.jwtHelper.isTokenExpired(token);
    }

    private saveLoginUserInfo(user: any, username: string = null, password: string = null) {
        if (user && user.Token) {
            const loginModel = new Login();
            loginModel.username = username;
            loginModel.userid = user.UserId;
            loginModel.token = user.Token;
            loginModel.refresh_token = user.Refresh_Token;
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(loginModel));
            this.currentUser = loginModel;
        }
        return user;
    }

    public getLegacyToken() {
        const currentuser = JSON.parse(localStorage.getItem('currentUser')) as Login;
        const token = currentuser.token;
        const legacyToken = this.jwtHelper.decodeToken(token).LegacyToken;
        if (legacyToken) {
            localStorage.setItem('currentLegacyToken', legacyToken);
            return legacyToken;
        } else {
            throw new Error('Current user does not have jwt');
        }
    }

    public preserve(refresh_token: string): Observable<boolean> {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser.stay_connected) {
            return this.httpRequestService.PostEmptyBodyRequest(this.baseUrl + 'api/refresh?refresh_token=' + refresh_token,  'preserve')
            .pipe(
              map((response: Response) => {
                  const tokenInformation: any = response;
                  currentUser.refresh_token = tokenInformation.Refresh_Token;
                  currentUser.token = tokenInformation.Token;
                  localStorage.setItem('currentUser', JSON.stringify(currentUser));
                  return true;
              })
            );
        }
        return of(false);
    }

    getToken(username: string, password: string, clientid: string) {
        const currentuser = JSON.parse(localStorage.getItem('currentUser')) as Login;
        if (currentuser) {
          return currentuser.token;
        }

        return this.httpRequestService.PostRequest(this.baseUrl + 'api/gettoken', { username: username, password: password, clientid: clientid }, 'getToken')
            .pipe(
              map((response: Response) => {
                  // login successful if there's a jwt token in the response
                  const user: any = response;
                  if (user && user.Token) {
                      // store user details and jwt token in local storage to keep user logged in between page refreshes
                      localStorage.setItem(clientid, JSON.stringify(user));
                  }
                  return user;
              })
            );
    }

    public verifyToken(currentToken: string) {
      const token = localStorage.getItem('currentToken');
      if (token) {
            return this.httpRequestService.GetEmptyBodyRequest(this.baseUrl + 'api/validejwt', 'verifyToken')
                .pipe(map((response: Response) => {
                    return this.saveLoginUserInfo(response);
                }));
        }
    }

    public logout() {
        // remove user from local storage to log user out
        // localStorage.removeItem('currentUser');
        // localStorage.removeItem('currentToken');
        // localStorage.removeItem('currentLegacyToken');
       // localStorage.clear();
    }


}
