<nb-auth-block>
  <div class="title"></div>
  <div class="content">
    <div class="titleimg"></div>
    <div class="auth-content">
      <p *ngIf="many_smag_users" class="many_smag_users_title">{{ authSmagTextService.get("ManyUsers") }}</p>
      <ul class="user-list" *ngIf="many_smag_users">
        <li *ngFor="let profile of smag_users_grouped">
          <h4 class="category">{{ authSmagTextService.getProfileText(profile.label) }}</h4>
          <ul class="user-list">
            <li *ngFor="let smag_user of profile.users" (click)="loginUser(smag_user)" class="button li_button">
              {{ smag_user.getName() }} (<i>{{ smag_user.login }}</i>)
            </li>
          </ul>
        </li>
      </ul>
      <div class="title" *ngIf="no_smag_user==true">
        <p [innerHtml]="text_no_users"></p>
         <a *ngIf="!user_already_logged" class="forgot-password" routerLink="../login">{{ authSmagTextService.get("Retour") }}</a>
      </div>
      <div class="title" *ngIf="one_smag_user && !user_already_logged">
        {{ authSmagTextService.get("OneUser") }}
      </div>
      <div class="title" *ngIf="one_smag_user && user_already_logged">
        {{ authSmagTextService.get("OneUserAlreadyLogged") }}
      </div>
      <div class="title" *ngIf="undefined_error && undefined_error.length > 0">
        {{ undefined_error }}
      </div>
    </div>
  </div>
</nb-auth-block>

