<nb-auth-block>
    <div class="titleimg"></div>
    <h2 class="title">Mot de passe oublié?</h2>
    <small class="form-text sub-title">Pas de soucis...</small>
  <!--  <form (ngSubmit)="requestPass(requestPassForm)" #requestPassForm="ngForm">

        <div *ngIf="showMessages.error && errors && errors.length > 0 && !submitted" class="alert alert-danger" role="alert">
            <div>
                    L'adresse email saisie est incorrecte.
            </div>
            < !--div *ngFor="let error of errors">{{ error }}</div-- >
        </div>
        <div *ngIf="showMessages.success && messages && messages.length > 0 && !submitted" class="alert alert-success" role="alert">
            <div>
                <strong>Hooray!</strong>
            </div>
            <div *ngFor="let message of messages">{{ message }}</div>
        </div>

        <div class="form-group">
            <label for="input-email" class="sr-only">Entrer votre adresse mail</label>
            <input name="email" [(ngModel)]="user.email" id="input-email" #email="ngModel" class="form-control" placeholder="Email"
                pattern=".+@.+\..+" [class.form-control-danger]="email.invalid && email.touched" [required]="getConfigValue('forms.validation.email.required')"
                autofocus>
            <small class="form-text error" *ngIf="(email.invalid && email.touched) || (submitted && !requestPassForm.form.valid)">
                Veuillez saisir votre adresse mail.
            </small>
        </div>
        <button  [class.btn-pulse]="submitted" class="okbutton"></button>
    </form>-->
    <div class="title">
        <label>Veuillez contacter le référent be Api<br/> de votre coopérative.</label>
    </div>
   

    <div class="links col-sm-12">
        <small class="linktext">
            <a routerLink="../login">
                <strong>RETOUR À LA PAGE ACCUEIL</strong>
            </a>
        </small>
    </div>
</nb-auth-block>