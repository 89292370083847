import { Fertilizer } from "./fertilizer";
import { Legend } from "./legend";
import{ InterventionType } from "./interventiontype";
import{ InterventionGroupe } from "./interventionGroupe";
import { isNullOrEmptyString, isNullOrUndefined} from '../../_helpers';


/**
 * Class des Preconisations|Modulation à la parcelle
 */
export class Intervention {

    Ident:number;
    IdIntervention : number;
    BeginDate:Date;
    EndDate:Date;
    Envisaged:boolean;
    IsCipan:boolean;
    Fertilizer:Fertilizer;
    CodeProduit: string;
    Legend:Legend;
    IdGroupe:string;
    Percent:number;
    IsPercent:boolean;
    Comm:string;
    RefDose:number;
    ModDatas:ModulComplements;
    InterventionType:InterventionType;
    InterventionTypeGroup :InterventionGroupe;
    // SPECIFIQUE Interventions Organiques
    public ModePreco: boolean | null;

    DeleteDate: Date|null;

    /*
    * isIntervention: Indique s'il s'agit d'une intervention
    */
    public constructor(_iPreco: Intervention, isIntervention:boolean = false)
    {
        this.Ident=_iPreco.Ident;
        this.IdIntervention =_iPreco.IdIntervention;
        this.BeginDate=_iPreco.BeginDate;
        this.EndDate=_iPreco.EndDate;
        this.Envisaged=_iPreco.Envisaged;
        this.IsCipan=_iPreco.IsCipan;
        this.Fertilizer=_iPreco.Fertilizer;
        this.CodeProduit=_iPreco.CodeProduit;
        this.IdGroupe= _iPreco.IdGroupe;
        this.Legend=_iPreco.Legend;
        this.Percent=_iPreco.Percent;
        this.IsPercent=_iPreco.IsPercent;
        this.Comm=_iPreco.Comm;
        this.RefDose=_iPreco.RefDose;
        this.ModDatas=_iPreco.ModDatas;
        this.InterventionType=_iPreco.InterventionType;
        this.InterventionTypeGroup =_iPreco.InterventionTypeGroup;
        if(isIntervention == true) {
          this.ModePreco = _iPreco.ModePreco;
          this.DeleteDate = isNullOrUndefined(_iPreco.DeleteDate) ? null : _iPreco.DeleteDate;
        }

    }

     IncludeIdent():boolean
    {
        return this.Ident > 0;
    }
    /**
     * Indique que l'intervention est en mode preco
     * (pour une intervention ORGANIQUE sur la culture en cours modulée)
     * @returns bool
     */
    IsModePreco() : boolean
    {
      return this.ModePreco == true;
    }

    /**
     * Dans le cas d'une intervention SI on est sur une supprimée
     * @returns bool
     */
    public IsInterDeleted() {
      return isNullOrUndefined(this.DeleteDate) == false;
    }

    /**
     * Dans le cas d'une intervention SI on est sur une supprimée
     * @returns bool
     */
    public static IsInterDeleted(plotInter:Intervention) {
      return isNullOrUndefined(plotInter.DeleteDate) == false;
    }

    /**
     * Gestion des interventions
     * @returns string
     */
    public GetCodeProduct():string
    {
      return isNullOrEmptyString(this.CodeProduit) ? this.Fertilizer.code : this.CodeProduit;
    }

    /**
     * Indique si on est sur une modulation bouillie
     * @returns boolean
     */
    public static IsModulationBouillie(preconisation:Intervention):boolean {
      return preconisation !=null && preconisation.ModDatas !=null && preconisation.ModDatas.Type !=null && preconisation.ModDatas.Type.toUpperCase() == "BOUILLIE";
    }

   

}

export class ModulComplements
{
    Type:string;
    Value:number;
}
