<div class="js-menu">
  <div class="smag-button-container"></div>
  <div class="jarvis-menu-image">
    <div [ngStyle]="{'background-image' : 'url(' + ImageUrl + ')'}">
      <span class="jarvis-menu-image-customlabel" *ngIf="CoopName">by {{CoopName}}</span>
    </div>
  </div>
  <div class="jarvis-menu-container">
    <div [ngClass]="{showfilter: showPopupFilter}" class="menu-filters">
      <div class="mobile-filter">
        <i (click)="showPopupFilter = !showPopupFilter" class="icon-filter"></i>
        <span>Filtres</span>
      </div>
      <div class="list-menu">
        <div *ngFor="let menu of FilterMenus()" class="jarvis-ui-menu">
          <div class="multiselectable" *ngIf="menu.Checkable == 1">
            <div class="menu-title">
              {{menu.Title}}
            </div>
            <div (mouseleave)="mouseOutItems($event)" class="menu-items">
              <div *ngIf="Selected(menu.Code) > 1" class="count_select">{{Selected(menu.Code)}} éléments sélectionnés</div>
              <div *ngIf="Selected(menu.Code) == 1" class="menu-item checked_true firstselected">{{SelectedText(menu.Code)}}</div>
              <div *ngFor="let group of menu.GroupItems"  (click)="menuGroupClick($event)">
                  <div *ngIf="group.Title != 'Default' && menu.GroupItems.length > 1" class="group-title" id="{{menu.Code + '_' + group.Id+'_MenuGroup'}}">
                      {{group.Title}}
                    </div>
                <div *ngFor="let itemmenu of group.Items" id="{{menu.Code + '_' + itemmenu.Id}}" (click)="itemClick($event)" class="menu-item checked_{{itemmenu.Checked}}">
                  <div id="{{menu.Code + '_' + itemmenu.Id}}" class="checkbox checked_{{itemmenu.Checked}}"></div> {{itemmenu.Value}}
                </div>
              </div>
            </div>
            <div id="selectall_{{menu.Code}}" class="selectall" *ngIf="allItems(menu).length > 10" (click)="selectAllClick($event)">{{TitleSelectAll(menu.Code)}}</div>
          </div>
          <div class="monoselectable" *ngIf="menu.Checkable == 2">
            <div class="menu-title">
              {{menu.Title}}
            </div>
            <div class="menu-items">
                <div *ngFor="let group of menu.GroupItems">
              <div *ngFor="let itemmenu of group.Items" id="{{menu.Code + '_' + itemmenu.Id}}" (click)="itemClick($event)" class="menu-item checked_{{itemmenu.Checked}}">
                {{itemmenu.Value}}
              </div>
              </div>
            </div>
          </div>
        </div>
        <div class="validate-list">
          <input type="button" value="Valider" (click)="showPopupFilter = !showPopupFilter">
        </div>
      </div>
    </div>
    <div class="menu-actions">
      <div *ngFor="let menu of ActionMenus()" class="jarvis-ui-menu">
        <div class="actionMenu" *ngIf="menu.Checkable == 3">
          <div class="menu-title">
            {{menu.Title}}
          </div>
          <div class="menu-items-action">
              <div *ngFor="let group of menu.GroupItems">
            <div *ngFor="let itemmenu of group.Items" id="{{menu.Code + '_' + itemmenu.Id}}" (click)="itemActionClick(menu.Code,itemmenu.Id)" class="menu-action checked_{{itemmenu.Checked}}" title="{{itemmenu.Title}}">
              <i id="{{menu.Code + '_' + itemmenu.Id}}" class="{{itemmenu.Image}}"></i>
              <span>{{itemmenu.Title}}</span>
            </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-jarvis-popup></app-jarvis-popup>
