import { Component, OnInit } from '@angular/core';
import { error } from '../jarvis-popup/_models/error';
import { JarvisPopupService } from './jarvis-popup.service';


@Component({
  selector: 'app-jarvis-popup',
  templateUrl: './jarvis-popup.component.html',
  styleUrls: ['./jarvis-popup.component.scss']
})
export class JarvisPopupComponent implements OnInit {

  public Error:error;

  constructor(private popupService: JarvisPopupService) {

   this.Error = this.popupService.Error;
   }

   public closeClick()
   {
     this.popupService.Error.Show = false;
   }


  ngOnInit() {
  }

}
