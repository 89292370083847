import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { map, Observable, throwError, catchError } from 'rxjs';
import { environment } from '../../environments/environment';
 
@Injectable({
  providedIn: 'root'
})
export class HttpRequestService {


  constructor(private httpRequestService: HttpClient) {
  }

  public request(url: string, body, options) {
    return this.PostRequest(url, body, 'functionName');
  }

  /**
   * Poste une requete requete vers l'URL
   * @Param [] bodyData List de proprietes nommées qui seront envoyé dans une structure du body
   * @Param string functionName nom de la fonction appelant ce post
   * @Param withOCP boolean Inclure la clé OCP dans l'entete de requete
   */
  public PostRequest(url: string, bodyData: any, functionName: string = '', withOCP: boolean = false): Observable<any> {
    const httpHeaders = {
      headers: this.formatSmagAuthHeader(withOCP)
    };
    return this.httpRequestService.post<string>(url, JSON.stringify(bodyData), httpHeaders)
      .pipe(
        map((result: any) => {
            return result;
          }),
        catchError((err => {
            console.log('Error Call ' + functionName, err);
            return throwError(err);
        }))
      );
  }

  /**
   * Get Request Exported File Data
   * @param url 
   * @param _iEFileDownloader 
   * @returns 
   */
  GetExportedFile(url:string, _iEFileDownloader : any):Observable<any> {
   return this.httpRequestService.get(url, {responseType:_iEFileDownloader.DownloadFileType, headers:this.formatSmagAuthHeader(false, true)})
  }

  /**
   * Poste une requete requete vers l'URL
   * @Param [] bodyData List de proprietes nommées qui seront envoyées dans une structure du body
   * @Param string functionName nom de la fonction appelant ce post
   */
  public GetRequest(url: string, bodyData: any[], functionName: string= ''): Observable<any> {
    const body = {};
    Object.keys(bodyData).forEach(function(key) {
      body[key] = bodyData[key];
    });

    const httpHeaders = {
      headers: this.formatSmagAuthHeader()
    };

    return this.httpRequestService.get<any>(url, httpHeaders)
      .pipe(
        map((result: any) => {
            return result;
          },
          (error: any) => {
            console.log('Error Call GetRequest ' + functionName, error);
            return error;
          }
        ),
      );
  }

  public PostEmptyBodyRequest(url: string, functionName: string): Observable<any> {
    return this.PostRequest(url, [], functionName);
  }

  public GetEmptyBodyRequest(url: string, functionName: string): Observable<any> {
    return this.GetRequest(url, [], functionName);
  }

  /**
   * Construction de l'entete de la requete
   * @Param withOCP boolean Inclure la clé OCP dans l'entete de requete
   * @Param _iIsDownloadFile boolean true sur un chargement de fichier zip (arrayBuffer)
   */
  private formatSmagAuthHeader(withOCP: boolean = false, _iIsDownloadFile:boolean=false): HttpHeaders {

    let oHeaders = new HttpHeaders({
      'Content-Type': 'application/json;  charset=utf-8',
      'Accept': 'application/json, text/plain, */*',
    });

    // 'responseType': 'arraybuffer, blob, json, stream, application/octet-stream',
    if(_iIsDownloadFile == true){
      oHeaders = new HttpHeaders({
        'Content-Type': 'application/zip, application/octet-stream, application/x-www-form-urlencoded',
        'responseType': 'arraybuffer',
      });
    }
    if (withOCP === true) {
      oHeaders = oHeaders.append('Ocp-Apim-Subscription-Key', environment.SmagAccount.OcpApimSubscriptionKey);
    }
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser && currentUser.token) {
      oHeaders = oHeaders.append('SmagAuthorization', 'Bearer ' + currentUser.token);
    }
    if (currentUser && currentUser.smag_token) {
      oHeaders = oHeaders.append('SmagToken', 'Bearer ' + currentUser.smag_token);
    }
    return oHeaders;
  }

  /**
   * get Legacy - Pour migration Angular 13
   */
  get(url: string, body, options) {
    return this.GetRequest(url, body, options);
  }
  /**
   * post Legacy - Pour migration Angular 13
   */
  public post(url: string, body, functionName) {
    return this.PostRequest(url, body, functionName);
  }

}
