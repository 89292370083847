import { Geometry } from './geometry'
import { Observation } from './observation';
import { MicroPlotDetails } from './microPlotDetails';
import { mcPreconisation } from './mcPreconisation';


export class MicroPlot {
    Id : number;
    IdBeapi: string;
    Surface: number;
    MicroPlotDetails: MicroPlotDetails;
    MicroPlotType:string;
    Name: string;
    Geometry: Geometry;
    Observations: Array<Observation>;
    Preconisations: Array<mcPreconisation>;
    Interventions: Array<mcPreconisation>;
    /**Pilotage azote SP LAI */
    AdviceN : number;
    //  IdPreviousMicroPlot : number;
    //  IdAntePreviousMicroPlot : number;
    //  IdFertility: number;

    constructor(_iMicroPlot:MicroPlot)
    {
        this.Id =_iMicroPlot.Id;
        this.IdBeapi= _iMicroPlot.IdBeapi;
        this.Surface= _iMicroPlot.Surface;
        this.MicroPlotDetails= _iMicroPlot.MicroPlotDetails;
        this.MicroPlotType= _iMicroPlot.MicroPlotType;
        this.Name= _iMicroPlot.Name;
        this.Geometry= _iMicroPlot.Geometry;
        this.Observations= _iMicroPlot.Observations;
        this.Preconisations= _iMicroPlot.Preconisations;
        this.Interventions= _iMicroPlot.Interventions;
        this.AdviceN = _iMicroPlot.AdviceN;
        //    this.IdPreviousMicroPlot = _iMicroPlot.IdPreviousMicroPlot;
        //    this.IdAntePreviousMicroPlot = _iMicroPlot.IdAntePreviousMicroPlot;
        //    this.IdFertility= _iMicroPlot.IdFertility;
    }
  
    /**
     * retourne si dispose de diag Ferti OU PACK
     */
    public IsFerti():boolean
    {
        return (!this.IncludeType() || this.IsPACK() || this.EqualType("FERTI") );
    }
    /**
     * retourne si dispose de diag Potentiel OU PACK
     */
    public IsPotentiel():boolean
    {
        return (!this.IncludeType() || this.IsPACK() || this.EqualType("POTENTIEL") );
    }


    private IsPACK():boolean
    {
        return (  this.EqualType("PACK") )
    }
    private EqualType(_iReqType:string):boolean
    {
        return (this.MicroPlotType.toUpperCase()==_iReqType);
    }
    private IncludeType():boolean
    {
        return (this.MicroPlotType !=null);
    }
}