import { isNullOrUndefined } from '../../_helpers';
import { PrintManager, DownloadPdfEnum } from './print_manager';

export class DownloadPdfRequest {
  farmsId: Array<string>;
  idCampaign: number;
  DownloadType: DownloadPdfEnum;
  filterPrecosType: string;
  /// filtre order sur  l' Order prestation Type
  filterPrestType: string;
  SfxTitle: string;

  constructor(farmsId: Array<string>, idCampaign: number, exportType: string, _iFilterIntervType: string, _iSfxTitle: string, _ifilterPrestType: string) {

    this.farmsId = farmsId;
    this.idCampaign = idCampaign;
    this.DownloadType = this.convertToDownloadType(exportType);
    this.filterPrecosType = _iFilterIntervType;
    this.SfxTitle = _iSfxTitle;
    this.filterPrestType = !isNullOrUndefined(_ifilterPrestType) ? _ifilterPrestType : null;
  }

  /**
  * convertion des  codes type d'impression en enu type print
  * @param _iExportType code type export
  */
  private convertToDownloadType(_exportType: string): DownloadPdfEnum|null {

    switch (_exportType) {
      case 'fertilite':
        return DownloadPdfEnum.DiagnosticFertilité;
      case 'potentiel':
        return DownloadPdfEnum.DiagnocticPotentiel;
      case 'fumure':
        return DownloadPdfEnum.ConseilFumure;
      case "Conseil azote":
        return DownloadPdfEnum.ConseilAzote;
      case 'preconisation':
        return DownloadPdfEnum.Préconisations;
      case PrintManager.code_Print_Modulation:
        return DownloadPdfEnum.Modulations;
      case PrintManager.code_Print_Diag_Annuals:
        return DownloadPdfEnum.Annuals;
      case PrintManager.code_Print_Orga_Ferti:
        return DownloadPdfEnum.ModePreco;

      case PrintManager.code_Print_Diag_Sp_LAI:
        return DownloadPdfEnum.DiagSpLai;

      case PrintManager.code_Print_Advice_Sp_LAI:
        return DownloadPdfEnum.AdvicePilotNSpLai;

      case PrintManager.code_Print_Preco_Sp_LAI:
        return DownloadPdfEnum.PrecoPilotNSpLai;

      case PrintManager.code_Print_Synt_Fertis:
        return DownloadPdfEnum.SynthFertilizerNeeds;
      case PrintManager.code_Print_Synt_Other_Inputs:
        return DownloadPdfEnum.SynthOtherInputNeeds;
        default:
          return null;
    }
  }
}



