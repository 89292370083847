export class Productrequest {
    Code: string;
    Date: Date;
    // la clés de l unité vers laquelle convertir l unité du produit
    ChangeUnitCode :string=null;
  
    constructor(_Code:string,_Date:Date, _changeUnitCode:string=null) {
        this.Code = _Code;
        this.Date= _Date;
        this.ChangeUnitCode = _changeUnitCode;
    }
}
