import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JarvisUiComponent } from './jarvis-ui.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JarvisPopupModule } from '../jarvis-popup/jarvis-popup.module';
import { JarvisPopupService } from '../jarvis-popup//jarvis-popup.service';
import { SmagAccountService } from '../../framework/auth/services/index';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    JarvisPopupModule
  ],
  declarations: [JarvisUiComponent],
  exports: [
    JarvisUiComponent // <-- this!
  ],
  providers: [
    JarvisPopupService,
    SmagAccountService
  ]
})
export class JarvisUiModule { }

