import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JarvisPopupComponent } from './jarvis-popup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JarvisPopupService } from './jarvis-popup.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule      
  ],
  declarations: [JarvisPopupComponent],
  exports: [
    JarvisPopupComponent // <-- this!
  ],
  providers: [
    JarvisPopupService
  ]
})
export class JarvisPopupModule { }

