<div id="popup" class="ol-popup popup-map" #popup>

     <button id="closePopup" class="k-button k-bare" dir="rtl" kendowindowcloseaction="">
      <span class="k-icon k-i-close" role="presentation">
      </span>
      </button>
  <div id="popup-content" [innerHTML]="popupContent"></div>
  <!-- <button id="closePopup" class="k-button k-primary" type="button">Fermer</button> -->

</div>
