import { itemValue } from "./itemValue";
import { isNullOrUndefined } from '../../../_helpers';

export class groupItem{
    Id:string;
    Title:string;
    Items:Array<itemValue>;

   /**
     * Ordonne (ASC) les Items du menu selon leur valeur( texte)
     *
     */
    public OrderItems()
    {
        if(this.Items!= null && this.Items.length>1)
        {
            this.Items=  this.Items.sort(function(a,b){
                return (isNullOrUndefined(a.Value) )?-1:( isNullOrUndefined(b.Value))?1: (a.Value.toLowerCase()<b.Value.toLowerCase())?-1:(a.Value.toLowerCase()>b.Value.toLowerCase())?1:0;
        });
        };

    }
}
