import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Params, CanLoad, Route } from '@angular/router';
import { AuthenticationService } from '../_services/index';
import { map } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private auth: AuthenticationService) {

    }

    /// There are three situationas to check:
    /// 1. If the user is not login, he point directly to home, then return false;
    /// 2. If the user is logined using jwt (Jarvis Icon), then try to valid the token;
    /// 3. if the user is already login, the currentUser != null, in this situation, we need to check if the token is expired or not;
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const token = route.queryParams['token'];
        // situation 1
        if (!token && !localStorage.getItem('currentUser')) {
            // not logged in so redirect to login page with the return url
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
        // situation 2
        if (token) {
            localStorage.setItem('currentToken', token);
            localStorage.removeItem('currentUser');
            return this.auth.verifyToken(token).pipe(
              map(response => {
                if (localStorage.getItem('currentUser')) {
                    const urlWithoutAccessToken = state.url.replace(new RegExp('.token=' + token), '');
                    this.router.navigateByUrl(urlWithoutAccessToken);
                    return true;
                } else {
                      // not logged in so redirect to login page with the return url
                      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                      return false;
                  }
              })
            );

        }

        // situation 3
        if (localStorage.getItem('currentUser')) {
            if (this.auth.isTokenExpired()) {
                const currentUser = JSON.parse(localStorage.getItem('currentUser'));
                this.auth.preserve(currentUser.refresh_token).subscribe((result: boolean) => {
                    if (result) {
                        this.router.navigateByUrl(state.url) ;
                        return true;
                    } else {
                        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                        return false;
                    }
                },
                error => {
                    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                        return false;
                }
                );
            } else {
              return true;
            }
        }
    }
}

@Injectable()
export class AuthCanLoadGuard implements CanLoad {

    canLoad(route: Route): boolean {
        if (localStorage.getItem('currentUser')) {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login']);
        return false;
    }
    constructor(private router: Router, private auth: AuthenticationService) {

    }

}
