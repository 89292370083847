import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DashboardComponent } from './dashboard.component';
import { CommonModule } from '@angular/common';
import { ComboBoxModule, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { FarmServices, ExportPdfService } from '../../_services/index';
import { FormsModule } from '@angular/forms';

import { ComponentsModule } from './../components/components.module'
import { JarvisUiModule } from './../../modules/jarvis-ui/jarvis-ui.module';
import { JarvisUiService } from '../../modules/jarvis-ui/jarvis-ui.service';

@NgModule({
  imports: [
    CommonModule,
    ComboBoxModule,
    FormsModule,
    DropDownsModule,
    ComponentsModule,
    JarvisUiModule
  ],
  declarations: [
    DashboardComponent
  ],
  exports:[
    ComponentsModule
  ],
  providers: [
    FarmServices,
    ExportPdfService,
    JarvisUiService
  ]
})
export class DashboardModule { }

