import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { User } from '../../app/_models/share/user'
//declare gives Angular app access to gtag function
declare let gtag: Function;
@Injectable()
export class GoogleAnalyticsService {
  private ga_id = "G-NVKBV42YHJ";
  private _blAllowService: boolean = null;
  private _blUserAllowed: boolean = null;
  /**User acces on service Analytics */
  private get IsAccessOnService(): boolean {
    if (this._blAllowService == null)
      this.InitAccessService()
    return this._blAllowService == true;
  }

  constructor() { }

  /**
 * init on service Google Analytics by conditions
 */
  public InitService(_iUser: User): boolean {
    this.Clear();
    this._blUserAllowed = _iUser != null && !_iUser.IsAgentBeApi();
    let oblAccess = this.InitAccessService() == true;
    if (oblAccess) {
      this.eventConnection();
    }
    return oblAccess;
  }

    /**
  * return if Allow Log On Google Analytics
  * if Prod.Env && is not user SMAG
  */
 private InitAccessService(): boolean {
  if (this._blAllowService == null) {
    this._blAllowService = (environment.production == true) && this._blUserAllowed == true;
  }
  return this._blAllowService;
}

private Clear() {
  this._blAllowService = null;
  this._blAllowService
}

  private eventConnection() {
    gtag('js', new Date());
    gtag('config', this.ga_id);
  }

  public eventScreenViewEmitter(_iScreenName: string) {
    if (this.IsAccessOnService)
      gtag('event', 'screen_view', {
        'app_name': 'BeApi Restitution',
        'screen_name': _iScreenName
      });
  }

  /**
   *
   * @param _iProductSelected number product selected
   * @param _iConsoleFormat format console
   * @param _iblMerged
   * @param _iBufferSize Buffer size
   */
  public LogExportTerminal(_iProductSelected: number, _iConsoleFormat: string, _iblMerged: boolean, _iBufferSize: number) {
    if (this.IsAccessOnService) {
      gtag('event', 'Export', {
        'format': _iConsoleFormat,
        'records': _iProductSelected,
        'merge': _iblMerged,
        'elargir': _iBufferSize
      });
    }
  }

  public LogPrintPDF(_iPdfType: string) {
    if (this.IsAccessOnService) {
      gtag('event', 'Impression', {
        'type': _iPdfType,
      });
    }
  }
}
