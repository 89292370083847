import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { NbEmailPassAuthProvider } from './framework/auth/providers';
import { LoadingService } from './framework/auth/components/loading/loading.service';
import { NbAuthModule } from './framework/auth/auth.module';
import { NbThemeModule, NbLayoutModule } from '@nebular/theme';
import {
  AlertService, AuthenticationService, FarmServices, LegendService,
  SharedMapService, NullToEmptyString, SelectableService, AttributToTextService, GoogleAnalyticsService, AuthSmagTextService
} from './_services';
import { SmagAccountService } from './framework/auth/services';
import { AuthGuard } from './_guards';
import { HttpClientModule } from '@angular/common/http';
import { AlertComponent } from './_directives';
import { PingComponent } from './ping/ping.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { JarvisUiService } from './modules/jarvis-ui/jarvis-ui.service';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { NbEvaIconsModule } from '@nebular/eva-icons';

declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: (Provider | EnvironmentProviders)[];
  }
}
@NgModule({
  declarations: [
    AppComponent,
    AlertComponent,
    PingComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    DialogsModule,
    ButtonsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    NgbModule,
    NbAuthModule.forRoot({
      providers: {
        email: {
          service: NbEmailPassAuthProvider
        },
      },
    }),
    NbThemeModule.forRoot({ name: 'default' }), // this will enable the default theme, you can change this to `cosmic` to enable the dark theme
    NbLayoutModule,
    NbEvaIconsModule,
  ],
  providers: [
    AuthGuard,
    AlertService,
    AuthenticationService,
    FarmServices,
    SharedMapService,
    SelectableService,
    NullToEmptyString,
    AttributToTextService,
    LegendService,
    LoadingService,
    GoogleAnalyticsService,
    SmagAccountService,
    JarvisUiService,
    AuthSmagTextService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
