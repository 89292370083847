/**
 * Convertie une valeur nulle en chaine vide
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'NullToEmptyString', pure: false })
export class NullToEmptyString implements PipeTransform {
    transform(value: any, args: any[] = null): any {
        return (value === 'null' || !value) ? ' ': value;
    }
}