
export function isNullOrEmptyString(str) {
    return (!str || str.length === 0 );
}

export function isNullOrUndefined<T>(obj: T | null | undefined): obj is null | undefined {
    return typeof obj === "undefined" || obj === null;
}

export function objectToArray(_object) {
  let arr = [];
  Object.keys(_object).map(function(key){
    arr.push({[key]:_object[key]})
    return arr;
  });
}
