export const environment = {
  production: true,
  client_id: '8c481d582cc5472f99dc78980c2cc308',
  JarvisIconUrl:'http://validation-smag-jarvis-icon.smag.local/',

  DownLoad:
  {
    url: 'https://print-operational-api.agreo.fr/',
    pdfUrl:"https://beapi-print-data-api.agreo.fr/Print.asmx",
    download: 'api/farm/DownloadPdf',
  },

  Precizion: {
    url: 'https://precizionexporter-operational-api.agreo.fr/',
    get_all_console : '/api/console/getall',
    get_console_download:'api/modulation/datacard/get',
    get_shape_download :'api/modulation/shape/get',
    file_download :'api/modulation/file/get',
  },
  BeApiOperational: {
    url: 'https://beapi-adviceviewer-operational-api.agreo.fr/',
    getfarms: 'api/farm/CheckFarms',
    getcampagne: 'api/campaign/getcampagnefrombeapi',
    getcultures: 'api/cultivation/getcultivationsfrombeapi',
    getfilter: 'api/farm/GetFarmByCultivationsFiltered',
    getparcelles:'api/parcelle/getparcellesfrombeapi',
    getuserinfo:'api/user/getuserinfo'
  },
  SmagAccount: {
    getidentities: 'api/smag/identities',
    checksmagaccount: 'api/smag/checksmagaccount',
    ApplicationId: 'ff0b4ee8-2602-4a71-92d4-8942e2c0b6f0',
    SmagbuttonJs : 'https://prod-smag-party-button-app-v1.azurewebsites.net/Scripts/SmagButton.js?',
    SmagbuttonCss : 'https://prod-smag-party-button-app-v1.azurewebsites.net/Content/SmagButton.css?',
    OcpApimSubscriptionKey: '477091744ced48d495a498adc4550547',
    UrlBuildAuth:  'https://prod-smag-application-api-management.azure-api.net/authentication-operational/v1/api/authentication/build_url',
    smagApiAuthenticationUrl: 'https://prod-smag-application-api-management.azure-api.net/authentication-operational/v1/api/'
  },
};
