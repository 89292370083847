export class SmagUser {
   id: string;
   username: string;
   login: string;
   first_name?: string;
   last_name: string;
   profile: string;
   jarvis_token: string;
   has_smag_account: boolean;
   smag_token: string;
   smag_refresh_token: string;

   constructor(login:string, first_name:string, last_name:string, profile: string, jarvis_token: string, has_smag_account: boolean, smag_token: string, smag_refresh_token: string) {
      this.login = login ?? '';
      this.first_name = first_name ?? '';
      this.last_name = last_name ?? '';
      this.jarvis_token = jarvis_token ?? '';
      this.profile = profile ?? '';
      this.has_smag_account = has_smag_account ?? true;
      this.smag_token = smag_token;
      this.smag_refresh_token = smag_refresh_token;
   }

   public getName() {
      return this.first_name +' '+ this.last_name;
   }

}

export class SmagFarms{
   id: string;
   siret: string;

   constructor(id:string, siret: string) {
      this.id = id;
      this.siret = siret;
   }
}
