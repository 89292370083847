import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DownloadPdfRequest, Farm} from '../_models/index';
import {SharedMapService} from './map.service';
import {environment} from '../../environments/environment';
import {HttpRequestService} from '../_services/index';
import { PrintManager } from '../_models/print/print_manager';

declare var Smag: any;
declare var doPostRequest: any;
declare var getResolutionForScale: any;
declare var hexToRgb: any;
declare var rgbToRgba: any;
declare var computeRGBAForPrint: any;
declare var ol: any;

@Injectable()
export class ExportPdfService {
    private baseUrl = environment.DownLoad.url;

    public propPDFInterModePrecoCode: string = null;
    public propPDFInterModePrecoName: string = PrintManager.code_Print_Orga_Ferti;

    constructor(private httpRequestService: HttpRequestService, private mapService: SharedMapService) {
    }

    /**
     * Exportation de pDF
     * @param _iDownloadType
     * @param _iOrders
     * @param _iFilterModInterType filtre sur les Modulations PrecoInterventionType
     * @param _iSfxModTitle Title banner impression
     *  @param _ifilterPrestType filter l order sur la perstationType en plus
     */
    public ExportPdf(_iDownloadType: string, _iOrders: Farm[], _iFilterModInterType: string, _iSfxModTitle: string, _ifilterPrestType: string): Observable<any> {
        if ( _iOrders === null || _iOrders === undefined || !_iOrders.length) {
           return null; // throw new DOMException("Exploitation n'existes pas.")
        }
        return this.httpRequestService.PostRequest(this.baseUrl + environment.DownLoad.download, this.buildRequest(_iDownloadType, _iOrders, _iFilterModInterType, _iSfxModTitle, _ifilterPrestType), 'ExportPdf');
    }

    public Downlaod(res: any): void {
      // doPostRequest existe dans module carto permet de simuler l'envoi d'un formulaire
        doPostRequest('POST', environment.DownLoad.pdfUrl + '/DownloadPdf', { filePath: res, saveAs: true }, '_blank');
    }


    private buildRequest(downloadType: string, _iOrders: Farm[], _iFilterPrecoType: string, _iSfxModTitle: string, _ifilterPrestType: string) {
      const ilotIds: Array<string> = [];
      _iOrders.forEach(a => {if (ilotIds.indexOf(a.IdExploitation) == -1)ilotIds.push(a.IdExploitation); }  );
      return new DownloadPdfRequest(ilotIds, _iOrders[0].IdCampagne, downloadType, _iFilterPrecoType, _iSfxModTitle, _ifilterPrestType);
    }

    replaceAll(str: string, find: string, replace: string) {
        return str.replace(new RegExp('\\' + find, 'g'), replace);
    }

}
