import { Input, Injectable } from "@angular/core";


@Injectable()
export class LegendColor {
    MinValue: number;
    MaxValue: number;
    Red: number;
    Blue: number;
    Green: number;
    alpha: number;

    private _color: string;
    @Input()
    set Color(color: string) {
        this._color = color;
    }
    get Color(): string {
        if (this._color)
            return this._color;
        return "rgb(" + Math.round(this.Red)
            + "," + Math.round(this.Green)
            + "," + Math.round(this.Blue) + ")";
    }

}
