import { LegendColor  } from "./legendcolor";

export class Legend{
    Name:string;
    Class:Array<LegendColor>;

    /**
     *
     */
    constructor() {
      this.Class = [];

    }
}