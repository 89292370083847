import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class LoadingService {

    private loaderSubject = new Subject<LoaderState>();
    loaderState = this.loaderSubject.asObservable();

    constructor() { }

    show(msg: string = "Patientez durant le chargement de l'application") {
        this.loaderSubject.next(<LoaderState>{ show: true, msg: msg });
    }
    hide() {
        this.loaderSubject.next(<LoaderState>{ show: false });
    }
}

export interface LoaderState {
    show: boolean;
    msg: string;
}
