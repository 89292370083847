import { itemValue } from "./itemValue";
import { Output, EventEmitter } from '@angular/core';
import { groupItem } from "./groupItem";
import {isNullOrUndefined} from '../../../_helpers';

export class menuItem{
    Code:string;
    Title:string;
    Checkable:menuType;
    GroupItems:Array<groupItem> = new Array<groupItem>();
    MultipleGroupSelected: boolean = true;
    Visible:boolean = true;

   /**
     * On ordonne l'objet(ASC) les menus présents dans chaque groupes présents
     * @param blIncludeGroups Indique si OUI on ordonne également les groupes selon le titre
     */
    public Order(blIncludeGroups:boolean)
    {
        if( this.GroupItems!= null && this.GroupItems.length>0)
        {
            if(blIncludeGroups && this.GroupItems.length>1)
            {
                this.GroupItems=  this.GroupItems.sort(function(a,b){
                    return (isNullOrUndefined(a.Title))?-1:(isNullOrUndefined(b.Title))?1: (a.Title.toLowerCase()<b.Title.toLowerCase())?-1:(a.Title.toLowerCase()>b.Title.toLowerCase())?1:0;
                  })
            }
             this.GroupItems.forEach(r=>r.OrderItems());

        }
    }
}
export enum menuType {
    MultiSelectable = 1,
    Mono = 2,
    Action = 3,
}

