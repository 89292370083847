<div *ngIf="Error.Show" class="jarvis-popup">
<div class="jarvis-popup-container">
        <div class="jarvis-popup-header">
                {{Error.Title}} 
        </div>
 <p>{{Error.Message}}</p>
    <div class="jarvis-popup-footer">
        <button (click)="closeClick()">Fermer</button>
    </div>
</div>
</div>
