import { Component, Inject } from '@angular/core';
import { NB_AUTH_OPTIONS_TOKEN } from '../../auth.options';
import { NbAuthResult, NbAuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { getDeepFromObject } from '../../helpers';
import { Login, User } from './../../../../_models/index';


@Component({
  selector: 'ConnectorComponent',
  template: '{{warn}}  username : {{user.username}} , password : {{user.password}}'
})
export class ConnectorComponent {

  warn : String = '';
  provider: string = '';
  messages: string[] = [];
  user: any = {};
  redirectDelay: number = 0;
  errors: string[] = [];

  constructor(
    protected service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS_TOKEN) protected config = {},
    protected router: Router){
      this.provider = this.getConfigValue('forms.login.provider');
      this.redirectDelay = this.getConfigValue('forms.login.redirectDelay');
      this.Login();
    }

  Login(): void{
    try {
      this.warn = this.router.url;
      var tree = this.router.parseUrl(this.router.url);
      this.user =new User();
      this.user.username = tree.queryParams['username'];
      this.user.password = tree.queryParams['password'];
      if(!this.user.username || ! this.user.password){
        this.user.username = this.user.username?this.user.username:'?';
        this.user.password = this.user.password?this.user.password:'?';
        throw new Error (" 401 paramètre(s) GET manquant(s) ");
      }
      else {
        this.service.authenticate(this.provider, this.user).subscribe((result: NbAuthResult) => {
          if (result.isSuccess()) {
            this.messages = result.getMessages();
            let user = result.getResponse().body;
            if (user && user.Token) {
                var loginModel = new Login();
                loginModel.username = this.user.username;
                loginModel.userid = user.UserId;
                loginModel.token = user.Token;
                loginModel.refresh_token = user.Refresh_Token;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(loginModel));
            }
          } else {
            this.warn=result.getErrors().join();
            //throw new Error ( result.getErrors().join());
          }
          const redirect = result.getRedirect();
          if (redirect) {
            setTimeout(() => {
              return this.router.navigateByUrl(redirect);
            }, this.redirectDelay);
      }
        });

      }
    } catch (error) {
        this.warn=error;
    }

  }


  getConfigValue(key: string): any {
    return getDeepFromObject(this.config, key, null);
  }
 }
